import FieldsetContainer from 'components/blocks/Delta/Inputs/Containers/FieldsetContainer';
import React, { useState } from 'react'
import { useForm, useFieldArray, Controller } from "react-hook-form";
import InputPattern from '../Inputs/InputPattern';
import InputHora from '../Inputs/InputHora';
import InputChkBox from '../Inputs/InputChkBox';
import InputDate from 'components/blocks/Delta/Inputs/InputDate';

interface IInputProps {
    control: any;
    register: any;
    name: string;
    errors: any;
    disabled?: boolean;
    setValue: any;
    watch: any;
}


//Este componente es el que se encarga de renderizar el array de horarios de Declaración empresarial sobre descripción y exposición a riesgos durante el embarazo o la lactancia natural
//no es muy reutilable en principio pero sirve de base para otros arrayfields
export const ArrayFieldProcesosIT: React.FC<IInputProps> = ({ control, name, register, errors, disabled, setValue, watch }) => {


    const { fields, append, remove } = useFieldArray({
        control,
        name: name
    });



    return (
        <>

            {fields.map((item, index) => (
                <div className="container form--simple-styled" id={`${name}.${index}`}>
                    <div className="row">
                        <div className="col-xl-12">
                            <FieldsetContainer legend=" Procesos de IT en los seis meses anteriores" disabled={disabled}>




                                

                                <div className="col-xl-12 col-md-6">
                                    <InputDate
                                        id={`${name}.${index}.Baja`}
                                        name={`${name}.${index}.Baja`}
                                        maxDate={new Date(watch(`${name}.${index}.Alta`))}
                                        label="Fecha Baja"
                                        
                                        placeholder=""
                                        errors={errors}
                                        register={register}
                                        setValue={setValue}

                                    />
                                </div>
                                <div className="col-xl-12 col-md-6">
                                    <InputDate
                                        id={`${name}.${index}.Alta`}
                                        name={`${name}.${index}.Alta`}
                                        minDate={new Date(watch(`${name}.${index}.Baja`))}
                                        label="Fecha Alta"
                                        
                                        placeholder=""
                                        errors={errors}
                                        register={register}
                                        setValue={setValue}

                                    />
                                </div>

                                <InputChkBox
                                    id={`${name}.${index}.RelacionEmbarazo`}
                                    name={`${name}.${index}.RelacionEmbarazo`}
                                    label="Marque si fue relacionado con el embarazo"
                                    placeholder=""
                                    justify='center'
                                    xl={12}
                                    md={12}
                                    col={12}
                                    errors={errors}
                                    register={register}


                                />
                                <button id={`btnDelete${index}`} type="button" onClick={() => remove(index)} className=' btn-box btn-secondary    '>
                                    Borrar Proceso de IT
                                </button>
                            </FieldsetContainer>



                        </div>
                    </div>
                </div>
            ))}
            <button
                className="mt-2 btn-box btn-accent"
                type="button"
                disabled={disabled}
                onClick={() => append({})}
            >Añadir Procesos de IT en los seis meses anteriores</button>
        </>
    )
}

export default ArrayFieldProcesosIT;
