import React from 'react';
import { FieldValues, RefCallBack, UseFormRegister, useForm, useFormContext } from 'react-hook-form';
import { validate } from 'uuid';

interface IInputProps {
    name: string,
    id: string,
    label: string,
    placeholder: string,
    errors: any,
    register: any,
    readonly?: boolean,
    defaultValue?: string
    disabled?: boolean
    maxLength?: number,
    watch: any
    handleOnChange?: (e: React.ChangeEvent<HTMLInputElement>) => void;
    value?: any,
    isvalidateNumber?: boolean,
    isvalidateNumberPostalCode?: boolean,
    req?: string
    xl?: number
    md?: number
    col?: number
}

const InputNIE: React.FC<IInputProps> = ({ name, id, label, placeholder, errors, register, readonly, defaultValue, disabled, maxLength, req, xl, md, col, watch }) => {

    const validateNIE = (value: string) => {
        const tipo = watch(name + "Tipo");
        if (tipo === "DNI") {
            const regex = /^\d{8}[A-Z]$/;
            if (!regex.test(value)) {
                return "El DNI debe tener 8 números y una letra";
            }


            const dniNumbers = value.substring(0, 8);
            const dniLetter = value.substring(8, 9);
            const letter = calculateDNIletter(dniNumbers);
            if (letter !== dniLetter) {
                return "La letra no coincide";
            }
        }
        else if (tipo === "NIE") {
            const regex = /^[A-Z]{1}[0-9]{7}[A-Z]{1}$/;
            if (!regex.test(value)) {
                return "El NIE debe tener 1 letra, 7 números y 1 letra";
            }


            const initialLetter = value.substring(0, 1);
            if (!["X", "Y", "Z"].includes(initialLetter)) {
                return "La letra inicial del NIE debe ser X, Y o Z";
            }


            const finalLetter = value.substring(8, 9);
            const calculatedLetter = calculateNIEletter(value.substring(1, 8));
            if (finalLetter !== calculatedLetter) {
                return "La letra final del NIE no coincide";
            }
        }
        else if (tipo === "Pasaporte") {
            const validCombinations = [
                "AA", "AB", "AC", "AD", "AE", "AF", "AG", "AH", "AI", "AJ", "AK", "AL", "AM", "AN", "AO", "AP", "AQ", "AR", "AS", "AT", "AU", "AV", "AW", "AX", "AY", "AZ",
                "BA", "BB", "BC", "BD", "BE", "BF", "BG", "BH", "BI", "BJ", "BK", "BL", "BM", "BN", "BO", "BP", "BQ", "BR", "BS", "BT", "BU", "BV", "BW", "BX", "BY", "BZ",
                "CA", "CB", "CC", "CD", "CE", "CF", "CG", "CH", "CI", "CJ", "CK", "CL", "CM", "CN", "CO", "CP", "CQ", "CR", "CS", "CT", "CU", "CV", "CW", "CX", "CY", "CZ",
                "DA", "DB", "DC", "DD", "DE", "DF", "DG", "DH", "DI", "DJ", "DK", "DL", "DM", "DN", "DO", "DP", "DQ", "DR", "DS", "DT", "DU", "DV", "DW", "DX", "DY", "DZ"
            ];
            const regex = /^[A-Z]{2}\d{7}$/;
            if (!regex.test(value)) {
                return "El pasaporte debe tener 2 letras mayúsculas y 7 dígitos";
            }
            const combination = value.substring(0, 2);
            if (!validCombinations.includes(combination)) {
                return "La combinación de letras mayúsculas no es válida";
            }
        }


        return true;
    };



    // Función para calcular la letra final del NIE
    function calculateNIEletter(numbers: string): string {
        const nieLetters = "JPGMNRS";
        const remainder = parseInt(numbers) % 9;
        return nieLetters[remainder];
    }

    //Calcular la letra del DNI
    function calculateDNIletter(numbers: string): string {
        const dniLetters = "TRWAGMYFPDXBNJZSQVHLCKE";
        const remainder = parseInt(numbers) % 23;
        return dniLetters[remainder];
    }

    return (
        <div className={`col-xl-${xl} col-md-${md} col-${col}`}>
            <div className={`input-block ${errors && errors[name]?.message ? 'error' : ''}`}>
                <label htmlFor={id} className="input-block__label">{label}</label>
                <div className="row">
                    <div className="col-xl-12">
                        <input
                            type="text"
                            className='col-xl-6 col-12 col-md-12 mr-1 ml-1 mb-1'
                            name={name}
                            id={id}
                            placeholder={placeholder}
                            {...register(name, { required: req, validate: validateNIE })}
                            readOnly={readonly || false}
                            disabled={disabled || false}
                            defaultValue={defaultValue || ''}
                            maxLength={maxLength}
                        />
                        <select id="cars" {...register(name + "Tipo")} className=' col-xl-3 col-md-12 col-12 m-1'>
                            <option value="DNI">DNI</option>
                            <option value="NIE">NIE</option>

                            <option value="Pasaporte">Pasaporte</option>
                        </select>
                    </div>
                </div>
                {errors && errors ?
                    <p className="error m-0 ">
                        {errors && errors[name]?.message}
                    </p>
                    : ''}
                {errors?.[name.split('.')[0]] && errors?.[name.split('.')[0]][name.split('.')[1]]?.[name.split('.')[2]]?.message ? //procesamiento de error cuando se usa dentro de un ArrayField
                    <>
                        <p className="error m-0 ">
                            {errors?.[name.split('.')[0]][name.split('.')[1]]?.[name.split('.')[2]]?.message}
                        </p>
                    </>

                    : ''}
            </div>
        </div>
    )
}

export default InputNIE;

// USE EXAMPLE
{/* <InputText 
  id="personalDataName"
  name="personalDataName"
  label="Nombre"
  placeholder="Nombre"
  errors={errors}
  register={register}
  req = 'MENSAJE DE ERROR CUANDO NO HAYA VALOR'
/> */}