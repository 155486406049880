import React from 'react'
import { useLocation } from "react-router-dom";
import SolicitudPagoDirectoIncapacidadTemporal from 'components/pages/ManagementNewForms/SolicitudPagoDirectoIncapacidadTemporal';
const PageRenderNewForms = () => {
    const location = useLocation();
    const { id } = location.state || {};
    switch (id) {
        case 5:

            return (
                <div>

                    <SolicitudPagoDirectoIncapacidadTemporal/>

                </div>
            )

        default:
            break;
    }

}

export default PageRenderNewForms

