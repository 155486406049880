import { StatusDto } from "@solimat/solimat-web-endpoint/lib/record/dto";

const useCheckTypes = () => {
  const checkRecordType = (value: number | undefined) => {
    // RecordWithTD = 0, anterior Enfermedad profesional
    // RecordWithoutTD, anterior Accidente de trabajo
    // RecordCATA, anterior Enfermedad común
    switch (value) {
      case 1:
        return "Historial con IT";
      case 2:
        return "Historial sin IT";
      case 3:
        return "Historial CATA";
      case 4:
        return "Historial Oficio";
      default:
        return "";
    }
  };

  const checkEpisodeType = (episodeType: number | undefined) => { //asistencial
    // Hospitalization,
    // OperationRoom,
    // Rehabilitation,
    // Visit
    switch (episodeType) {
      case 200:
        return "Consultas";
      case 201:
        return "Centro Externo";
      case 202:
        return "Hospitalización";
      case 203:
        return "Imagen";
      case 204:
        return "Quirófano";
      case 205:
        return "Rehabilitación";
      case 206:
        return "Urgencias";
      case 207:
        return "Documentación asistencial";
      default:
        return "";
    }
  };



  const checkDocumentType = (value: number | undefined) => {
    /*
    Asistencial = 0,
    Económico = 1,
    Administrativo = 2,
    General = 3
    */
    switch (value) {
      case 0:
        return "Asistencial";
      case 1:
        return "Económico";
      case 2:
        return "Administrativo";
      case 3:
        return "General";
      default:
        return "";
    }
  };

  const checkStatus = (status: StatusDto) => {
    switch (status) {
      case StatusDto.Scheduled:
        return "Programada";
      case StatusDto.Done:
        return "Realizada";
      case StatusDto.Modified:
        return "Modificada";
      case StatusDto.Canceled:
        return "Cancelada";
      default:
        return "";
    }
  };

  const checkContType = (value: number) => {
    switch (value) {
      case 0:
        return "Contingencia Común";
      case 1:
        return "Contingencia Profesional";
      default:
        return "";
    }
  };

  const checkManagementType = (value: number) => {

    switch (value) {
      case 100:
        return "Base Cotización CATA";
      case 101:
        return "Base Reguladora";
      case 102:
        return "Comisión Prestaciones Especiales";
      case 103:
        return "Compensación SLD";
      case 104:
        return "Gastos Asistenciales";
      case 105:
        return "Gasto Farmacia";
      case 106:
        return "Gasto Jurídico";
      case 107:
        return "Gasto Transporte";
      case 108:
        return "Recobro";
      case 109:
        return "Regularización";
      case 110:
        return "Reposición Ortoprotésica";
      case 111:
        return "Resumen IMS";
      case 112:
        return "Pago Directo";
      case 0:
        return "Parte de Confirmación";
      case 1:
        return "Parte alta baja";
      case 2:
        return "Parte sin baja";
      case 3:
        return "Entorno del Trabajador";
    }
  };

  const checkManagementDocumentEntityType = (EnumMember: number) => {
    switch (EnumMember) {
      case 1:
        return "ENVIO_CORRESPONDENCIA";
      case 2:
        return "DOCUMENTOS_DIGITALES";
      case 3:
        return "DOCUMENTACION_PAPEL";
      default:
        return "";
    }
  };

  const checkCoverageType = (coverage: number) => {
    switch (coverage) {
      case 0:
        return "Sin Determinar";
      case 1:
        return "Contingencia Común";
      case 2:
        return "Contingencia Profesional";
      case 3:
        return "Cese de actividad trabajadores autónomos";
      default:
        return "";
    }
  };
  const checkFormStatusRD = (status: number) => {
    switch (status) {

      case 1:
        return "Aceptado";
      case 2:
        return "Denegado";
      case 3:
        return "Aceptado con Incidencias";
      case 4:
        return "Revisar";
      case 5:
        return "Trámite Audiencia";
      case 6:
        return "En Trámite";
      default:
        return "Estado Desconocido";
    }
  }
  const checkFormStatusDocumentsRD = (status: number) => {
    switch (status) {
      case 0:
        return "Pendiente Adjuntar Documentos";
      case 1:
        return "Pendiente Validar Gestor";
      case 2:
        return "Aceptado";
      case 3:
        return "Aceptado sin Requerir Documento";
      case 4:
        return "Rechazado";
      case 5:
        return "Obsoleto";
      default:
        return "Estado Desconocido";
    }
  }
  const checkContingencyType = (contingencyType: number) => {
    switch (contingencyType) {
      case 0:
        return "Sin determinar";
      case 1:
        return "Enfermedad comun";
      case 2:
        return "Accidente no laboral";
      case 4:
        return "Accidente laboral CB";
      case 5:
        return "Enfermedad profesional CB";
      case 6:
        return "Periodo observacion CB";
      case 7:
        return "Accidente laboral SB";
      case 8:
        return "Riesgo durante embarazo";
      case 9:
        return "Riesgo durante lactancia";
      case 14:
        return "Cuidado hijos";
      case 18:
        return "Cese actividad trabajadores autonomos";
      case 21:
        return "Enfermedad profesional SB";
      case 23:
        return "Periodo observacion SB";
      case 24:
        return "Situacion asimilada accidente trabajo";
      case 25:
        return "Prestacion extraordinaria cese actividad";
      case 26:
        return "Prestacion ordinaria extraordinaria cese actividad";
      case 27:
        return "Prestacion extraordinaria de temporada cese actividad";
      case 28:
        return "Prestación extraordinaria cese resolución autoridad laboral";
      case 29:
        return "Prestación extraordinaria cese resolución autoridad laboral 2";
      case 30:
        return "Prestación ordinaria extraordinaria cese actividad 2";
      case 31:
        return "Prestación ordinaria extraordinaria cese actividad prórroga";
      case 32:
        return "Prestación extraordinaria de temporada cese actividad 2";
      case 33:
        return "Sistema liquidacion directa 2020 ASAT";
      case 34:
        return "Prestacion extraordinaria cese resolucion autoridad laboral 1 2";
      case 35:
        return "Prestacion extraordinaria cese resolucion autoridad laboral 2 2";
      case 36:
        return "Prestacion ordinaria extraordinaria cese actividad 3";
      case 37:
        return "Prestacion extraordinaria de temporada cese actividad 3";
      case 38:
        return "Prestacion extraordinaria cese resolucion autoridad laboral 1 3";
      case 39:
        return "Prestacion extraordinaria cese resolucion autoridad laboral 2 3";
      case 40:
        return "Prestacion ordinaria extraordinaria cese actividad 4";
      case 41:
        return "Prestacion extraordinaria de temporada cese actividad 4";
      case 42:
        return "Prestacion extraordinaria cese resolucion autoridad laboral 1 4";
      case 43:
        return "Prestacion extraordinaria cese resolucion autoridad laboral 2 4";
      case 44:
        return "Prestacion ordinaria extraordinaria cese actividad 5";
      case 45:
        return "Prestacion extraordinaria de temporada cese actividad 5";
      case 46:
        return "Sistema liquidacion directa 2021 ASAT";
      default:
        return "";
    }
  };

  return {
    checkRecordType,
    checkEpisodeType,
    checkDocumentType,
    checkStatus,
    checkContType,
    checkManagementType,
    checkManagementDocumentEntityType,
    checkCoverageType,
    checkContingencyType,
    checkFormStatusRD,
    checkFormStatusDocumentsRD
  };
};

export default useCheckTypes;
