import { useState } from "react";
import { record } from "@solimat/solimat-web-endpoint";

const useUpdateIncompatibilityDocuments = () => {
  const BASE_URL = process.env.REACT_APP_PRESTACIONES_PACIENTE_PATH || "";
  const FUNCTIONS_KEY = process.env.REACT_APP_PRESTACIONES_PACIENTE_KEY || "";
  const apiRecord = new record.RecordService(BASE_URL, FUNCTIONS_KEY);
  const [loadingUpdateIncompatibilityDocuments, setLoadingUpdateIncompatibilityDocuments] =
    useState(false);
  const [successUpdateIncompatibilityDocuments, setSuccessUpdateIncompatibilityDocuments] =
    useState(false);
  const [errorUpdateIncompatibilityDocuments, setErrorUpdateIncompatibilityDocuments] =
    useState(false);


  const UpdateIncompatibilityDocuments = (
    userId: string,
    prestacionId: string,
    formId:string,
    data:any,
    _cb?: (data: any) => void
  ) => {
    setLoadingUpdateIncompatibilityDocuments(true);
    setSuccessUpdateIncompatibilityDocuments(false);
    apiRecord
      .updateIncompatibilityDocuments(userId, prestacionId,formId,data)
      .then((res: any) => {
        setLoadingUpdateIncompatibilityDocuments(false);
        setSuccessUpdateIncompatibilityDocuments(true);
       
        if (typeof _cb === "function") {
          _cb(res.data);
        }
      })
      .catch((err: any) => {
        console.log(err);
        setLoadingUpdateIncompatibilityDocuments(false);
        setErrorUpdateIncompatibilityDocuments(true);
      });
  };
  return {
    
    UpdateIncompatibilityDocuments,
    loadingUpdateIncompatibilityDocuments,
    successUpdateIncompatibilityDocuments,
    errorUpdateIncompatibilityDocuments,
  };
};
export default useUpdateIncompatibilityDocuments;