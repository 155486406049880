import React, { useEffect, useState, createContext, useContext } from 'react'
import { useLocation, Link } from 'react-router-dom';
import PageMenu from 'components/blocks/PageMenu/PageMenu';
import IconArrowGray from "../../../img/icons/svg/botones/flecha-big-gris.svg";
import FieldsetContainer from 'components/blocks/Delta/Inputs/Containers/FieldsetContainer';
import { FormProvider, useForm } from 'react-hook-form';
import Loader from 'components/blocks/Loader/Loader';
import EmpresaButtonGroup from 'components/blocks/ManagementNewForms/ButtonGroups/EmpresaButtonGroup';
import InputPattern from 'components/blocks/ManagementNewForms/Inputs/InputPattern';
import TrabajadorButtonGroup from 'components/blocks/ManagementNewForms/ButtonGroups/TrabajadorButtonGroup';
import InputChkBox from 'components/blocks/ManagementNewForms/Inputs/InputChkBox';
import InputDate from 'components/blocks/Delta/Inputs/InputDate';
import ArrayFieldContingencias from 'components/blocks/ManagementNewForms/ButtonGroups/ArrayFieldContingencias';
import RadioButtonGroup from 'components/blocks/ManagementNewForms/ButtonGroups/RadioButtonGroup';

export const CertificadoEmpresa: React.FC = () => {

    const location = useLocation();
    const title = "Certificado de empresa"
    const breadcrumbs = ["Certificado de empresa"];
    const formMethods = useForm();
    const { register, handleSubmit, setError, formState: { errors }, setValue, watch, control } = formMethods;
    const [disableConContrato, setdisableConContrato] = useState(false);
    const [disableSinContrato, setdisableSinContrato] = useState(false);
    const [disableAgrario, setdisableAgrario] = useState(false);
    
    const onSubmit = async (data: any) => {

        console.log(data);
    };

    const MethoddisableConContrato = (value: boolean) => {
        setdisableConContrato(value);
    };
    const MethoddisableSinContrato = (value: boolean) => {
        setdisableSinContrato(value);
    };
    const MethoddisableAgrario = (value: boolean) => {
        setdisableAgrario(value);
    };


    return (
        <>
            <PageMenu title={title} breadcrumbs={breadcrumbs}>
                <div className="btns-header-container">
                    <Link to={`/inicio`}>
                        <img src={IconArrowGray} alt="icon arrow right" />
                    </Link>
                </div>
            </PageMenu>
            <FormProvider {...formMethods}>
                <div className="container form--simple-styled" id="formDeltawithoutleave">


                    <div className="row">
                        <div className="col-xl-12">
                            <FieldsetContainer legend="" disabled={false}>
                                <InputPattern
                                    id="DonCertifica"
                                    name="DonCertifica"
                                    label="D./Dña."
                                    placeholder=""

                                    xl={6}
                                    md={6}
                                    errors={errors}
                                    register={register}
                                    req='Quien certifica el documento'

                                />
                                <InputPattern
                                    id="EnCalidadDe"
                                    name="EnCalidadDe"
                                    label="En calidad de: "
                                    placeholder=""

                                    xl={6}
                                    md={6}
                                    errors={errors}
                                    register={register}
                                    req='Falta en calidad de'

                                />
                                <label className="input-block__label">CERTIFICA, a efectos de la solicitud de la prestación por IT / Riesgo que son ciertos todos los datos que a continuación se consignan: </label>
                            </FieldsetContainer>
                        </div>
                    </div>




                    <EmpresaButtonGroup
                        errors={errors}
                        register={register}
                        setValue={setValue}
                        watch={watch}
                        label='Datos de la Empresa'
                    />

                    <TrabajadorButtonGroup errors={errors} register={register} label={'Datos del Trabajador'} setValue={setValue} seguridadSocial={true} watch={watch}>

                        <InputPattern
                            id="TrabajadorGrupoCotizacion"
                            name="TrabajadorGrupoCotizacion"
                            label="Grupo Cotización"
                            placeholder=""
                            xl={6}
                            md={6}
                            errors={errors}
                            register={register}


                        />
                        <InputChkBox
                            id="TrabajadorContratoParcial"
                            name="TrabajadorContratoParcial"
                            label="Marcar si es contrato a tiempo parcial"
                            placeholder=""
                            col={12}
                            xl={6}
                            md={6}
                            justify='center'
                            errors={errors}
                            register={register}


                        />
                        <InputPattern
                            id="TrabajadorTipoContrato"
                            name="TrabajadorTipoContrato"
                            label="Tipo de Contrato"
                            placeholder=""
                            xl={6}
                            md={6}
                            errors={errors}
                            register={register}


                        />
                        <div className="col-xl-6 col-md-6">
                            <InputDate
                                id="TrabajadorFechaAlta"
                                name="TrabajadorFechaAlta"
                                maxDate={new Date(watch('TrabajadorFechaBaja'))}
                                label="Fecha alta empresa"
                                req='Falta fecha de alta'
                                placeholder=""
                                errors={errors}
                                register={register}
                                setValue={setValue}

                            />
                        </div>
                        <div className="col-xl-6 col-md-6">
                            <InputDate
                                id="TrabajadorFechaBaja"
                                name="TrabajadorFechaBaja"
                                minDate={new Date(watch('TrabajadorFechaAlta'))}
                                label="Fecha Baja empresa"
                                req='Falta fecha de baja'
                                placeholder=""
                                errors={errors}
                                register={register}
                                setValue={setValue}

                            />
                        </div>
                        <div className="col-xl-6 col-md-6">
                            <InputDate
                                id="TrabajadorFechaBajaMedica"
                                name="TrabajadorFechaBajaMedica"
                                label="Fecha Baja Médica"

                                placeholder=""
                                errors={errors}
                                register={register}
                                setValue={setValue}

                            />
                        </div>
                        <div className="col-xl-6 col-md-6">
                            <InputDate
                                id="TrabajadorFechaAbonoIT"
                                name="TrabajadorFechaAbonoIT"
                                label="Fecha hasta la que se ha abonado la IT"

                                placeholder=""
                                errors={errors}
                                register={register}
                                setValue={setValue}

                            />
                        </div>
                        <label className="input-block__label col-12">Causa de baja médica: </label>

                        <InputChkBox
                            id="TrabajadorCausaBajaEnfermedadComun"
                            name="TrabajadorCausaBajaEnfermedadComun"
                            label="Enfermedad Común"
                            placeholder=""
                            col={6}
                            xl={3}
                            md={3}
                            justify=''
                            errors={errors}
                            register={register}


                        />
                        <InputChkBox
                            id="TrabajadorCausaBajaAccidenteNoLaboral"
                            name="TrabajadorCausaBajaAccidenteNoLaboral"
                            label="Accidente no Laboral"
                            placeholder=""
                            col={6}
                            xl={3}
                            md={3}
                            justify=''
                            errors={errors}
                            register={register}


                        />
                        <InputChkBox
                            id="TrabajadorCausaBajaEnfermedadProfesional"
                            name="TrabajadorCausaBajaEnfermedadProfesional"
                            label="Enfermedad Profesional"
                            placeholder=""
                            col={6}
                            xl={3}
                            md={3}
                            justify=''
                            errors={errors}
                            register={register}


                        />
                        <InputChkBox
                            id="TrabajadorCausaBajaAccidenteLaboral"
                            name="TrabajadorCausaBajaAccidenteLaboral"
                            label="Accidente Laboral"
                            placeholder=""
                            col={6}
                            xl={3}
                            md={3}
                            justify=''
                            errors={errors}
                            register={register}


                        />
                        <InputPattern
                            id="TrabajadorCausaExtincion"
                            name="TrabajadorCausaExtincion"
                            label="Causa extinción/suspensión del pago directo"
                            placeholder=""
                            xl={12}
                            md={12}
                            errors={errors}
                            register={register}


                        />
                        <label className="mb-2 input-block__label col-12">Días de vacaciones anuales retribuidas y no disfrutadas: </label>
                        <div className="col-xl-4 col-md-4">
                            <InputDate
                                id="TrabajadorVacacionesDesde"
                                name="TrabajadorVacacionesDesde"
                                label="Desde: "
                                maxDate={new Date(watch('TrabajadorVacacionesHasta'))}
                                placeholder=""
                                errors={errors}
                                register={register}
                                setValue={setValue}

                            />
                        </div>
                        <div className="col-xl-4 col-md-4">
                            <InputDate
                                id="TrabajadorVacacionesHasta"
                                name="TrabajadorVacacionesHasta"
                                label="Hasta: "
                                minDate={new Date(watch('TrabajadorVacacionesDesde'))}
                                placeholder=""
                                errors={errors}
                                register={register}
                                setValue={setValue}

                            />
                        </div>
                        <InputPattern
                            id="TrabajadorVacacionesBase"
                            name="TrabajadorVacacionesBase"
                            label="Base:"
                            placeholder=""
                            xl={4}
                            md={4}
                            errors={errors}
                            register={register}


                        />
                    </TrabajadorButtonGroup>
                    <div className="row">
                        <div className=" col-xl-12">

                            <FieldsetContainer legend="Bases de cotización a certificar según el supuesto de pago directo" disabled={false}>
                                <InputChkBox
                                    id="BaseCotizacionSinExtincionContrato"
                                    name="BaseCotizacionSinExtincionContrato"
                                    label="Si no se ha extinguido la relación laboral o la baja médica es por accidente de trabajo/ enfermedad profesional o solicita la prestación de Riesgo"
                                    placeholder=""
                                    col={12}
                                    xl={12}
                                    md={12}
                                    justify=''
                                    errors={errors}
                                    register={register}
                                    functionOnChange={MethoddisableSinContrato}

                                />

                                <InputChkBox
                                    id="BaseCotizacionConExtincionContrato"
                                    name="BaseCotizacionConExtincionContrato"
                                    label="Si el pago directo es por extinción de la relación laboral y la baja médica derivada de enfermedad común o accidente no laboral"
                                    placeholder=""
                                    col={12}
                                    xl={12}
                                    md={12}
                                    justify=''
                                    errors={errors}
                                    register={register}
                                    functionOnChange={MethoddisableConContrato}

                                />
                                <InputChkBox
                                    id="BaseCotizacionRegimenAgrario"
                                    name="BaseCotizacionRegimenAgrario"
                                    label="Régimen Especial Agrario"
                                    placeholder=""
                                    col={12}
                                    xl={12}
                                    md={12}
                                    justify=''
                                    errors={errors}
                                    register={register}
                                    functionOnChange={MethoddisableAgrario}

                                />
                                <div className="row " style={{ display: disableSinContrato ? 'block' : 'none' }}>
                                    <div className=" col-xl-12">

                                        <FieldsetContainer legend="Certicado de cotización del mes anterior a la baja médica" disabled={false}>
                                            <InputPattern
                                                id="CotizacionMesAnteriorAnos"
                                                name="CotizacionMesAnteriorAnos"
                                                label="Año"
                                                placeholder=""
                                                xl={4}
                                                md={6}
                                                errors={errors}
                                                register={register}
                                                pattern={/^[0-9]*$/}
                                                patternMessage='Solo se aceptan números'

                                            />
                                            <InputPattern
                                                id="CotizacionMesAnteriorMeses"
                                                name="CotizacionMesAnteriorMeses"
                                                label="Mes"
                                                placeholder=""
                                                xl={4}
                                                md={6}
                                                errors={errors}
                                                register={register}
                                                pattern={/^[0-9]*$/}
                                                patternMessage='Solo se aceptan números'

                                            />
                                            <InputPattern
                                                id="CotizacionMesAnteriorDias"
                                                name="CotizacionMesAnteriorDias"
                                                label="Días"
                                                placeholder=""
                                                xl={4}
                                                md={6}
                                                errors={errors}
                                                register={register}
                                                pattern={/^[0-9]*$/}
                                                patternMessage='Solo se aceptan números'

                                            />
                                            <InputPattern
                                                id="CotizacionMesAnteriorContingenciasComunes"
                                                name="CotizacionMesAnteriorContingenciasComunes"
                                                label="Contingencias Comunes"
                                                placeholder=""
                                                xl={4}
                                                md={6}
                                                errors={errors}
                                                register={register}
                                                pattern={/^[0-9.,'"]*$/}
                                                patternMessage='Solo se aceptan números'

                                            />
                                            <InputPattern
                                                id="CotizacionMesAnteriorContingenciasProfesionales"
                                                name="CotizacionMesAnteriorContingenciasProfesionales"
                                                label="Contingencias Profesionales"
                                                placeholder=""
                                                xl={4}
                                                md={6}
                                                errors={errors}
                                                register={register}
                                                pattern={/^[0-9.,'"]*$/}
                                                patternMessage='Solo se aceptan números'

                                            />
                                            <InputPattern
                                                id="CotizacionMesAnteriorHorasExtras"
                                                name="CotizacionMesAnteriorHorasExtras"
                                                label="Horas Extras y otros conceptos (año anterior)"
                                                placeholder=""
                                                xl={4}
                                                md={6}
                                                errors={errors}
                                                register={register}
                                                pattern={/^[0-9.,'"]*$/}
                                                patternMessage='Solo se aceptan números'

                                            />

                                        </FieldsetContainer>

                                    </div>
                                </div>
                                <div className='mb-2 m-0 p-0' style={{ display: disableSinContrato ? 'block' : 'none' }}>
                                    <ArrayFieldContingencias
                                        legend='Trabajador/a con jornada a tiempo parcial: certificado de las bases de cotización de los tres meses anteriores a la baja médica'
                                        control={control} register={register} name={'ArrayContingenciasTiempoParcial'} errors={errors} cantidad={1}
                                        contingenciasProfesionales={true}
                                        horasExtra={true} />
                                </div>

                                <div className='mb-2 m-0 p-0' style={{ display: disableConContrato ? 'block' : 'none' }}>
                                    <ArrayFieldContingencias
                                        legend='Certicado de cotizaciones de los 
180 últimos días cotizados'
                                        control={control} register={register} name={'ArrayContingencias180Dias'} errors={errors} cantidad={1}
                                        contingenciasProfesionales={false}
                                        horasExtra={false} />
                                </div>
                                <div className="row pb-3" style={{ display: disableAgrario ? 'block' : 'none' }}>
                                    <div className=" col-xl-12">

                                        <FieldsetContainer legend="Régimen especial agrario" disabled={false}>
                                            <div className="col-xl-6 col-md-6">
                                                <RadioButtonGroup errors={errors} name={'AgrarioFijoEventual'}
                                                    register={register}

                                                    label={'Tipo de regimen agrario'}
                                                    chkname1={'Fijo'}
                                                    chkname2={'Eventual'}
                                                    chklabel1={'Fijo'}
                                                    chklabel2={'Eventual'}
                                                    nombreGrupo={'TipoRegimenAgrario'}
                                                />
                                            </div>
                                            <InputChkBox
                                                id="AgrarioTrabajandoDiaBaja"
                                                name="AgrarioTrabajandoDiaBaja"
                                                label="Marque si estaba trabajando el día de la baja"
                                                placeholder=""
                                                col={6}
                                                xl={6}
                                                md={6}
                                                justify='center'
                                                errors={errors}
                                                register={register}


                                            />
                                            <InputPattern
                                                id="AgrarioCotizacionMesAnterior"
                                                name="AgrarioCotizacionMesAnterior"
                                                label="Cotización jornadas reales mes anterior baja"
                                                placeholder=""
                                                xl={6}
                                                md={6}
                                                errors={errors}
                                                register={register}
                                                pattern={/^[0-9.,'"]*$/}
                                                patternMessage='Solo se aceptan números'

                                            />
                                            <InputPattern
                                                id="AgrarioSalario"
                                                name="AgrarioSalario"
                                                label="SalarioReal"
                                                placeholder=""
                                                xl={6}
                                                md={6}
                                                errors={errors}
                                                register={register}
                                                pattern={/^[0-9.,'"]*$/}
                                                patternMessage='Solo se aceptan números'

                                            />
                                        </FieldsetContainer>

                                    </div>
                                </div>
                                <div className="row pb-3">
                                    <div className=" col-xl-12">

                                        <FieldsetContainer legend="A los efectos de que se inicie el pago directo de esta prestación desde el día" disabled={false}>

                                            <div className="col-xl-12 col-md-12">
                                                <InputDate
                                                    id="FechaInicioPago"
                                                    name="FechaInicioPago"
                                                    label=" "
                                                    req='Falta la fecha de Inicio del Pago'
                                                    placeholder=""
                                                    errors={errors}
                                                    register={register}
                                                    setValue={setValue}

                                                />
                                            </div>
                                        </FieldsetContainer>
                                    </div>
                                </div>
                            </FieldsetContainer>

                        </div>
                    </div>

                    {/* BOTON DE ENVIAR */}
                    <div className='paragraph small text-secondary  mb-4'>Esta solicitud va a ser tramitada por medios informáticos. Los datos personales que figuran en ella serán incorporados a un fichero de base de datos para el cálculo y control de la prestación económica 
que se le reconozca, y permanecerán bajo custodia de esta Entidad. En cualquier momento puede ejercitar su derecho de acceso, rectificación, cancelación y oposición sobre los datos incorporados al 
fichero ( art. 5 de la ley 15/1999 de 13 diciembre de Protección de datos de carácter personal, BOE del día 14).
                    </div>

                    <button className='btn-box btn-accent mt-3 mb-3' type="submit" onClick={handleSubmit(onSubmit)}>
                        Enviar
                    </button>

                    <div className='paragraph small text-secondary  mb-4'>En cumplimiento de lo establecido en la normativa sobre de Protección de Datos de Carácter Personal, Solimat Mutua Colaboradora con la Seguridad Social nº 72 le informa que sus datos de carácter 
personal serán objeto de tratamiento, con la finalidad del mantenimiento y cumplimiento de la relación. La base jurídica que legitima el tratamiento de sus datos es el 
cumplimiento de una obligación legal. Sus datos serán conservados de forma indefinida en nuestra entidad para atender a las posibles responsabilidades derivadas del tratamiento. Usted 
podrá revocar el consentimiento prestado, así como ejercitar sus derechos de acceso, rectificación, supresión, oposición, limitación del tratamiento, o portabilidad, en su caso, remitiendo un 
escrito a la siguiente dirección: Solimat, Mutua Colaboradora con la Seguridad Social nº 72, C/ Berna, 1, 45003, Toledo, adjuntando su NIF. Si considera que sus derechos no han sido 
debidamente atendidos, puede contactar con nuestro delegado de protección de datos lopd@solimat.com o interponer una reclamación frente a la Agencia Española de Protección de Datos
                    </div>
                </div>


            </FormProvider>

        </>
    )
}

export default CertificadoEmpresa;
