import React, { useState, useRef } from "react";
import IconDownload from '../../../img/icons/symbols/expediente.png';
import {
  GridComponent,
  ColumnsDirective,
  ColumnDirective,
  Inject,
  Page,
  Sort
} from "@syncfusion/ej2-react-grids";
import { TooltipComponent } from "@syncfusion/ej2-react-popups";

const FilesNoticesGrid = ({ documents }) => {
  const tooltipRef = useRef(null);

  const beforeRender = (args) => {
    const targetColumn = args.target.closest("td").getAttribute('aria-colindex');
    if (targetColumn === '1') {
      tooltipRef.current.content = "descargar";
      tooltipRef.current.tipPointerPosition = 'Middle';
      tooltipRef.current.position = 'TopLeft';
    } else {
      tooltipRef.current.content = args.target.closest("td").innerText;
      tooltipRef.current.tipPointerPosition = 'Middle';
      tooltipRef.current.position = 'TopLeft';
    }
  };

  const [pageOptions] = useState({
    pageSize: 10,
    pageSizes: ["5", "10", "15", "20", "Todos"],
  });

  const [sortingOptions] = useState({
    columns: [{ field: "fecha", direction: "Descending" }],
  });

  const gridTemplate = (props) => {
   
      return (
        <div className="link-document">
          <a
            href={props.sasUri}
            rel={props.nombre}
            // target="_blank"
            className="link-bold"
          >
            <img style={{ width: '20px', height: '20px' }} src={IconDownload} alt="descargar" />
          </a>
         
        </div>
      );
  
  };

  const template = gridTemplate;

  return (
    <div>
      <TooltipComponent
        ref={tooltipRef}
        target=".e-rowcell"
        beforeRender={beforeRender}
      >
        <GridComponent
          dataSource={Array.isArray(documents) ? documents : []}
          allowPaging={documents?.length >= 5}
          pageSettings={pageOptions}
          sortSettings={sortingOptions}
          enableHover={false}
          allowSelection={false}
        >
          <ColumnsDirective>
            <ColumnDirective
              field="sasUri"
              headerText=""
              template={template}
              width="30"
            />
            <ColumnDirective 
              field="nombreOriginal" 
              headerText="Nombre" 
              width="170" 
              textAlign="center"
            />
          </ColumnsDirective>
          <Inject services={[Page, Sort]} />
        </GridComponent>
      </TooltipComponent>
    </div>
  );
};

export default FilesNoticesGrid;
