import React, { useEffect, useState, createContext, useContext } from 'react'
import { useLocation, Link } from 'react-router-dom';
import PageMenu from 'components/blocks/PageMenu/PageMenu';
import IconArrowGray from "../../../img/icons/svg/botones/flecha-big-gris.svg";
import FieldsetContainer from 'components/blocks/Delta/Inputs/Containers/FieldsetContainer';
import { FormProvider, useFieldArray, useForm } from 'react-hook-form';
import Loader from 'components/blocks/Loader/Loader';
import InputPattern from 'components/blocks/ManagementNewForms/Inputs/InputPattern';
import Accordion from "components/blocks/Accordion/Accordion";
import AccordionForm from 'components/blocks/ManagementNewForms/Accordion/AccordionForm';
import InputChkBox from 'components/blocks/ManagementNewForms/Inputs/InputChkBox';
import TextAreaDefault from 'components/blocks/ManagementNewForms/TextArea/TextAreaDefault';
import ArrayFieldHorario from 'components/blocks/ManagementNewForms/ButtonGroups/ArrayFieldHorario';
import ArrayFieldPuestosTrabajoRel from 'components/blocks/ManagementNewForms/ButtonGroups/ArrayFieldPuestosTrabajoRel';
import InputNIE from 'components/blocks/ManagementNewForms/Inputs/InputNIE';

export const DeclaracionExposicionREL: React.FC = () => {

    const location = useLocation();
    const title = "Declaración empresarial sobre descripción y exposición a riesgos durante el embarazo o la lactancia natural "
    const breadcrumbs = ["Declaración empresarial sobre descripción y exposición a riesgos durante el embarazo o la lactancia natural "];
    const formMethods = useForm();
    const { register, handleSubmit, setError, formState: { errors }, setValue, watch, control } = formMethods;

    const [disableArrayField, setDisableArrayField] = useState(false);




    const onSubmit = async (data: any) => {

        console.log(data);
    };

    const MethoddisableArrayField = (value: boolean) => {
        setDisableArrayField(value);
    };


    return (
        <>
            <PageMenu title={title} breadcrumbs={breadcrumbs}>
                <div className="btns-header-container">
                    <Link to={`/inicio`}>
                        <img src={IconArrowGray} alt="icon arrow right" />
                    </Link>
                </div>
            </PageMenu>
            <FormProvider {...formMethods}>
                <div className="container form--simple-styled" id="formDeltawithoutleave">
                    <div className="row">
                        <div className="col-xl-12">
                            <FieldsetContainer legend="Datos de la Solicitante" disabled={false}>
                                <InputPattern
                                    id="NombreSolicitante"
                                    name="NombreSolicitante"
                                    label="Nombre del Solicitante"
                                    placeholder=""

                                    xl={4}
                                    md={6}
                                    errors={errors}
                                    register={register}
                                    req='Falta Nombre'

                                />
                                <InputPattern
                                    id="Apellido1Solicitante"
                                    name="Apellido1Solicitante"
                                    label="Primer Apellido"
                                    placeholder=""

                                    xl={4}
                                    md={6}
                                    errors={errors}
                                    register={register}
                                    req='Falta primer apellido'

                                />
                                <InputPattern
                                    id="Apellido2Solicitante"
                                    name="Apellido2Solicitante"
                                    label="Segundo Apellido"
                                    placeholder=""

                                    xl={4}
                                    md={6}
                                    errors={errors}
                                    register={register}
                                    req='Falta segundo apellido'

                                />
                                <InputNIE
                                    id="DNISolicitante"
                                    name="DNISolicitante"
                                    label="DNI del Solicitante"
                                    placeholder=""
                                    watch={watch}
                                    xl={6}
                                    md={6}
                                    errors={errors}
                                    register={register}
                                    req='DNI del Solicitante'

                                />
                            </FieldsetContainer>
                        </div>
                    </div>

                    <div className="row">
                        <div className="col-xl-12">
                            <FieldsetContainer legend="Datos de la Empresa" disabled={false}>
                                <InputPattern
                                    id="RazonSocialEmpresa"
                                    name="RazonSocialEmpresa"
                                    label="Nombre o Razón Social de la Empresa"
                                    placeholder=""

                                    xl={6}
                                    md={6}
                                    errors={errors}
                                    register={register}
                                    req='Falta Razón Social'

                                />
                                <InputPattern
                                    id="CCC"
                                    name="CCC"
                                    label="Nº código cuenta cotización (CCC)"
                                    placeholder=""

                                    xl={6}
                                    md={6}
                                    errors={errors}
                                    register={register}
                                    req='Falta CCC'

                                />
                            </FieldsetContainer>
                        </div>
                    </div>

                    <div className="row">
                        <div className="col-xl-12">
                            <FieldsetContainer legend="Puesto de trabajo de la Solicitante" disabled={false}>
                                <InputPattern
                                    id="PuestoTrabajoSolicitante"
                                    name="PuestoTrabajoSolicitante"
                                    label="Puesto de Trabajo"
                                    placeholder=""

                                    xl={3}
                                    md={6}
                                    errors={errors}
                                    register={register}


                                />
                                <InputPattern
                                    id="ProfesionSolicitante"
                                    name="ProfesionSolicitante"
                                    label="Profesion"
                                    placeholder=""

                                    xl={3}
                                    md={6}
                                    errors={errors}
                                    register={register}


                                />
                                <InputPattern
                                    id="CategoriaSolicitante"
                                    name="CategoriaSolicitante"
                                    label="Categoria"
                                    placeholder=""

                                    xl={3}
                                    md={6}
                                    errors={errors}
                                    register={register}


                                />
                                <InputPattern
                                    id="CNOSolicitante"
                                    name="CNOSolicitante"
                                    label="CNO"
                                    placeholder=""

                                    xl={3}
                                    md={6}
                                    errors={errors}
                                    register={register}


                                />
                            </FieldsetContainer>
                        </div>
                    </div>

                    <div className="row">
                        <div className=" col-xl-12">

                            <FieldsetContainer legend="Horarios de los Riesgos Laborales" disabled={false}>
                                <ArrayFieldHorario
                                    control={control}
                                    register={register}
                                    name="RiesgosLaboralesHorariosArray"
                                    errors={errors}

                                />
                            </FieldsetContainer>
                        </div>
                    </div>

                    <div className="row">
                        <div className="col-12 mt-4">
                            <AccordionForm title="Riesgos por agentes Físicos" expandedHide={false} expandedInit={false}>
                                <div className="row">
                                    <div className=" col-xl-12">

                                        <FieldsetContainer legend="Radiaciones" disabled={false}>
                                            <InputChkBox
                                                id="RadiacionIonizante"
                                                name="RadiacionIonizante"
                                                label="Radiaciones Ionizantes"
                                                placeholder=""

                                                xl={4}
                                                md={4}
                                                errors={errors}
                                                register={register}


                                            />
                                            <InputPattern
                                                id="DosisRadiacion"
                                                name="DosisRadiacion"
                                                label="Dosis Efectiva (msv/año)"
                                                placeholder=""

                                                xl={4}
                                                md={4}
                                                errors={errors}
                                                register={register}


                                            />
                                            <InputChkBox
                                                id="RadiacionNoIonizante"
                                                name="RadiacionNoIonizante"
                                                label="Radiaciones no Ionizantes"
                                                placeholder=""

                                                xl={4}
                                                md={4}
                                                errors={errors}
                                                register={register}


                                            />
                                        </FieldsetContainer>
                                    </div>
                                </div>

                                <div className="row">
                                    <div className=" col-xl-12">

                                        <FieldsetContainer legend="Vibraciones" disabled={false}>

                                            <InputPattern
                                                id="ExposicionVibraciones"
                                                name="ExposicionVibraciones"
                                                label="Exposición diaria de cuerpo entero (m/s2)"
                                                placeholder=""

                                                xl={12}
                                                md={12}
                                                errors={errors}
                                                register={register}


                                            />
                                        </FieldsetContainer>
                                    </div>
                                </div>

                                <div className="row">
                                    <div className=" col-xl-12">

                                        <FieldsetContainer legend="Temperaturas extremas (frio o calor)" disabled={false}>

                                            <InputPattern
                                                id="TrabajoSedentarioTemperaturas"
                                                name="TrabajoSedentarioTemperaturas"
                                                label="Trabajos Sedentarios (ºC)"
                                                placeholder=""

                                                xl={6}
                                                md={6}
                                                errors={errors}
                                                register={register}


                                            />
                                            <InputPattern
                                                id="TiempoTrabajoSedentarioTemperaturas"
                                                name="TiempoTrabajoSedentarioTemperaturas"
                                                label="Tiempo de exposición en trabajo Sedentario (horas/día)"
                                                placeholder=""

                                                xl={6}
                                                md={6}
                                                errors={errors}
                                                register={register}


                                            />
                                            <InputPattern
                                                id="TrabajoNoSedentarioTemperaturas"
                                                name="TrabajoNoSedentarioTemperaturas"
                                                label="Trabajos no Sedentarios (ºC)"
                                                placeholder=""

                                                xl={6}
                                                md={6}
                                                errors={errors}
                                                register={register}


                                            />
                                            <InputPattern
                                                id="TiempoTrabajoNoSedentarioTemperaturas"
                                                name="TiempoTrabajoNoSedentarioTemperaturas"
                                                label="Tiempo de exposición en trabajo no sedentario (horas/día)"
                                                placeholder=""

                                                xl={6}
                                                md={6}
                                                errors={errors}
                                                register={register}


                                            />
                                        </FieldsetContainer>
                                    </div>
                                </div>

                                <div className="row">
                                    <div className=" col-xl-12">

                                        <FieldsetContainer legend="Ruido" disabled={false}>

                                            <InputPattern
                                                id="IntensidadRuido"
                                                name="IntensidadRuido"
                                                label="Intensidad (db(A))"
                                                placeholder=""

                                                xl={4}
                                                md={6}
                                                errors={errors}
                                                register={register}


                                            />
                                            <InputPattern
                                                id="TiempoExposicionRuido"
                                                name="TiempoExposicionRuido"
                                                label="Tiempo de Exposicion (horas/dia)"
                                                placeholder=""

                                                xl={4}
                                                md={6}
                                                errors={errors}
                                                register={register}


                                            />
                                            <InputPattern
                                                id="MaximaExposicionRuido"
                                                name="MaximaExposicionRuido"
                                                label="Nivel pico máximo de exposición (db(C))"
                                                placeholder=""

                                                xl={4}
                                                md={6}
                                                errors={errors}
                                                register={register}


                                            />
                                        </FieldsetContainer>
                                    </div>
                                </div>
                                <div className="row">
                                    <div className=" col-xl-12">

                                        <FieldsetContainer legend="Atmosferas de Sobrepresión e Ingravidez" disabled={false}>

                                            <InputChkBox
                                                id="AtmosferaSobrepresionIngravidez"
                                                name="AtmosferaSobrepresionIngravidez"
                                                label="Atmósferas de sobrepresión elevada e ingravidez"
                                                placeholder=""

                                                xl={12}
                                                md={12}
                                                errors={errors}
                                                register={register}


                                            />
                                        </FieldsetContainer>
                                    </div>
                                </div>

                            </AccordionForm>
                            <AccordionForm title="Riesgos por agentes Químicos" expandedHide={false} expandedInit={false}>
                                <div className="row">
                                    <div className=" col-xl-12">

                                        <FieldsetContainer legend="Agentes Químicos" disabled={false}>

                                            <div className="col-xl-12 col-md-12">
                                                <TextAreaDefault
                                                    id="DescripcionSubstanciasTóxicasQuimicos" name="DescripcionSubstanciasTóxicasQuimicos" label="Enumerar substancia/s tóxica/s" errors={errors} register={register}
                                                    resizable={false}
                                                    rows={3}

                                                />
                                            </div>
                                            <InputChkBox
                                                id="TipoExposicionQuimicaInhalatoria"
                                                name="TipoExposicionQuimicaInhalatoria"
                                                label="Inhalatoria"
                                                placeholder=""
                                                col={4}
                                                xl={4}
                                                md={4}
                                                errors={errors}
                                                register={register}


                                            />
                                            <InputChkBox
                                                id="TipoExposicionQuimicaDermica"
                                                name="TipoExposicionQuimicaDermica"
                                                label="Dérmica"
                                                placeholder=""
                                                col={4}
                                                xl={4}
                                                md={4}
                                                errors={errors}
                                                register={register}


                                            />
                                            <InputChkBox
                                                id="TipoExposicionQuimicaDigestiva"
                                                name="TipoExposicionQuimicaDigestiva"
                                                label="Digestiva"
                                                placeholder=""
                                                col={4}
                                                xl={4}
                                                md={4}
                                                errors={errors}
                                                register={register}


                                            />
                                        </FieldsetContainer>
                                    </div>
                                </div>
                            </AccordionForm>
                            <AccordionForm title="Riesgos por agentes Biologicos" expandedHide={false} expandedInit={false}>
                                <div className="row">
                                    <div className=" col-xl-12">

                                        <FieldsetContainer legend="Riesgo por agente biológico" disabled={false}>

                                            <div className="col-xl-12 col-md-12">
                                                <TextAreaDefault
                                                    id="DescripcionAgentesBiologicos" name="DescripcionAgentesBiologicos" label="Enumerar agentes/biológicos" errors={errors} register={register}
                                                    resizable={false}
                                                    rows={3}

                                                />
                                            </div>
                                            <InputChkBox
                                                id="TipoBiologicaGrupo1"
                                                name="TipoBiologicaGrupo1"
                                                label="Grupo 1"
                                                placeholder=""
                                                col={6}
                                                xl={3}
                                                md={6}
                                                errors={errors}
                                                register={register}


                                            />
                                            <InputChkBox
                                                id="TipoBiologicaGrupo2"
                                                name="TipoBiologicaGrupo2"
                                                label="Grupo 2"
                                                placeholder=""
                                                col={6}
                                                xl={3}
                                                md={6}
                                                errors={errors}
                                                register={register}


                                            />
                                            <InputChkBox
                                                id="TipoBiologicaGrupo3"
                                                name="TipoBiologicaGrupo3"
                                                label="Grupo 3"
                                                placeholder=""

                                                xl={3}
                                                md={6}
                                                col={6}
                                                errors={errors}
                                                register={register}


                                            />
                                            <InputChkBox
                                                id="TipoBiologicaGrupo4"
                                                name="TipoBiologicaGrupo4"
                                                label="Grupo 4"
                                                placeholder=""

                                                xl={3}
                                                md={6}
                                                col={6}
                                                errors={errors}
                                                register={register}


                                            />
                                        </FieldsetContainer>
                                    </div>
                                </div>
                            </AccordionForm>
                            <AccordionForm title="Riesgos por condiciones de trabajo" expandedHide={false} expandedInit={false}>
                                <div className="row">
                                    <div className=" col-xl-12">

                                        <FieldsetContainer legend="Trabajador de minas subterraneas" disabled={false}>

                                            <InputChkBox
                                                id="TrabajadorMinaSubterranea"
                                                name="TrabajadorMinaSubterranea"
                                                label="Trabajadores en minas subterráneas"
                                                placeholder=""
                                                justify='center'
                                                xl={12}
                                                md={12}
                                                errors={errors}
                                                register={register}


                                            />
                                        </FieldsetContainer>
                                    </div>
                                </div>
                                <div className="row">
                                    <div className=" col-xl-12">

                                        <FieldsetContainer legend="Cargas Físicas y Riesgo Postural" disabled={false}>

                                            <InputChkBox
                                                id="ManejoPesosArrastreEmpuje"
                                                name="ManejoPesosArrastreEmpuje"
                                                label="Manejo repetitivo de pesos, arrastre y empuje"
                                                placeholder=""

                                                xl={4}
                                                md={4}
                                                errors={errors}
                                                register={register}


                                            />
                                            <InputPattern
                                                id="ManejoPesosArrastreEmpujeCarga"
                                                name="ManejoPesosArrastreEmpujeCarga"
                                                label="Cargas (kg)"
                                                placeholder=""

                                                xl={4}
                                                md={4}
                                                errors={errors}
                                                register={register}


                                            />
                                            <InputPattern
                                                id="ManejoPesosArrastreEmpujeTiempo"
                                                name="ManejoPesosArrastreEmpujeTiempo"
                                                label="Veces/día"
                                                placeholder=""

                                                xl={4}
                                                md={4}
                                                errors={errors}
                                                register={register}


                                            />
                                            <div className="mb-3 border-bottom border-dark"></div>
                                            <InputChkBox
                                                id="SubirBajarEscaleras"
                                                name="SubirBajarEscaleras"
                                                label="Subir/bajar escaleras repetitivo"
                                                placeholder=""

                                                xl={4}
                                                md={4}
                                                errors={errors}
                                                register={register}


                                            />
                                            <InputPattern
                                                id="SubirBajarEscalerasTiempo"
                                                name="SubirBajarEscalerasTiempo"
                                                label="Veces/turno"
                                                placeholder=""

                                                xl={4}
                                                md={4}
                                                errors={errors}
                                                register={register}


                                            />
                                            <InputChkBox
                                                id="SubirBajarEscalerasDeMano"
                                                name="SubirBajarEscalerasDeMano"
                                                label="Subir Escaleras de mano"
                                                placeholder=""

                                                xl={4}
                                                md={4}
                                                errors={errors}
                                                register={register}


                                            />
                                            <div className="mb-3 border-bottom border-dark"></div>
                                            <InputChkBox
                                                id="FlexionarTroncoCuclillas"
                                                name="FlexionarTroncoCuclillas"
                                                label="Flexionar tronco y trabajo en cuclillas"
                                                placeholder=""

                                                xl={6}
                                                md={6}
                                                errors={errors}
                                                register={register}


                                            />
                                            <InputPattern
                                                id="FlexionarTroncoCuclillasTiempo"
                                                name="FlexionarTroncoCuclillasTiempo"
                                                label="Porcentaje (%) de la jornada"
                                                placeholder=""

                                                xl={6}
                                                md={6}
                                                errors={errors}
                                                register={register}


                                            />
                                            <div className="mb-3 border-bottom border-dark"></div>
                                            <InputChkBox
                                                id="BidepestacionMantenida"
                                                name="BidepestacionMantenida"
                                                label="Bidepestacion Mantenida"
                                                placeholder=""

                                                xl={6}
                                                md={6}
                                                errors={errors}
                                                register={register}


                                            />
                                            <InputPattern
                                                id="BidepestacionMantenidaTiempo"
                                                name="BidepestacionMantenidaTiempo"
                                                label="Horas/Jornada"
                                                placeholder=""

                                                xl={6}
                                                md={6}
                                                errors={errors}
                                                register={register}


                                            />
                                            <div className="mb-3 border-bottom border-dark"></div>
                                            <InputChkBox
                                                id="BidepestacionIntermitente"
                                                name="BidepestacionIntermitente"
                                                label="Bidepestacion Intermitente"
                                                placeholder=""

                                                xl={6}
                                                md={6}
                                                errors={errors}
                                                register={register}


                                            />
                                            <InputPattern
                                                id="BidepestacionIntermitenteTiempo"
                                                name="BidepestacionIntermitenteTiempo"
                                                label="Minutos/Hora"
                                                placeholder=""

                                                xl={6}
                                                md={6}
                                                errors={errors}
                                                register={register}


                                            />
                                        </FieldsetContainer>
                                    </div>
                                </div>
                            </AccordionForm>
                            <AccordionForm title="Riesgos por Nocturnidad/Turnicidad" expandedHide={false} expandedInit={false}>
                                <div className="row">
                                    <div className=" col-xl-12">

                                        <FieldsetContainer legend="Nocturnidad/Turnicidad" disabled={false}>
                                            <InputChkBox
                                                id="RiesgoNocturnidad"
                                                name="RiesgoNocturnidad"
                                                label="Nocturnidad"
                                                placeholder=""

                                                xl={6}
                                                md={6}
                                                errors={errors}
                                                register={register}


                                            />
                                            <InputChkBox
                                                id="RiesgoTurnicidad"
                                                name="RiesgoTurnicidad"
                                                label="Turnicidad. Cumplimentar horario de turnos:"
                                                placeholder=""
                                                functionOnChange={MethoddisableArrayField}
                                                xl={6}
                                                md={6}
                                                errors={errors}
                                                register={register}


                                            />
                                            <ArrayFieldHorario
                                                control={control}
                                                register={register}
                                                name="RiesgoTurnicidadArray"
                                                errors={errors}
                                                disabled={!disableArrayField}
                                            />
                                        </FieldsetContainer>
                                    </div>
                                </div>


                            </AccordionForm>

                            <div className="row">
                                <div className=" col-xl-12">

                                    <FieldsetContainer legend="Relación de puestos de trabajo en la empresa y nº de trabajadores en cada uno" disabled={false}>
                                    <ArrayFieldPuestosTrabajoRel
                                            control={control}
                                            register={register}
                                            name="RelacionPuestosTrabajoArray"
                                            errors={errors}

                                        />
                                    </FieldsetContainer>
                                </div>
                            </div>
                            <div className="row">
                                <div className=" col-xl-12">

                                    <FieldsetContainer legend="Otros riesgos no especificados en apartados anteriores." disabled={false}>
                                    <TextAreaDefault
                                                    id="RiesgosNoEspecificados" name="RiesgosNoEspecificados" label="" errors={errors} register={register}
                                                    resizable={false}
                                                    rows={3}

                                                />
                                    </FieldsetContainer>
                                </div>
                            </div>
                        </div>
                    </div>

                    <button className='btn-box btn-accent mt-3 mb-3' type="submit" onClick={handleSubmit(onSubmit)}>
                        Enviar
                    </button>

                    <div className='paragraph small text-secondary  mb-4'>En cumplimiento de lo establecido en la normativa sobre de Protección de Datos de Carácter Personal, Solimat Mutua Colaboradora con la Seguridad Social nº 72 le informa que sus datos de
                        carácter personal serán objeto de tratamiento, con la finalidad del mantenimiento y cumplimiento de la relación. La base jurídica que legitima el tratamiento de sus datos es el
                        cumplimiento de una obligación legal. Sus datos serán conservados de forma indefinida en nuestra entidad para atender a las posibles responsabilidades derivadas del tratamiento. Usted
                        podrá revocar el consentimiento prestado, así como ejercitar sus derechos de acceso, rectificación, supresión, oposición, limitación del tratamiento, o portabilidad, en su caso,
                        remitiendo un escrito a la siguiente dirección: Solimat, Mutua Colaboradora con la Seguridad Social nº 72, C/ Berna, 1, 45003, Toledo, adjuntando su NIF. Si considera que sus
                        derechos no han sido debidamente atendidos, puede contactar con nuestro delegado de protección de datos lopd@solimat.com o interponer una reclamación frente a la Agencia
                        Española de Protección de Datos.
                    </div>
                </div>


            </FormProvider>

        </>
    )
}

export default DeclaracionExposicionREL;
