import React, { useState, useEffect } from "react";
import PageMenu from "../../../components/blocks/PageMenu/PageMenu";
import GeneralManagement from "../GeneralManagement/GeneralManagement";
import { useManagementFormState } from "../GeneralManagement/ManagementFormStateContext";
import ManagementAuthorizations from "../ManagementAuthorizations/ManagementAuthorizations";
import useDecode from "../../../hooks/useDecode";
import GeneralDocuments from "../generalDocuments/GeneralDocuments";
const Management: React.FC = (props: any) => {
  const tokenResponse = useDecode("token");
  const breadcrumbs = ["Mis gestiones"];
  const roles = tokenResponse?.extension_roles;
  const rolesArray = JSON.parse(roles && roles);
  const isAutonomo = rolesArray.includes("autonomo");

  const myState = useManagementFormState();
  const handleOnClick = (title: string, url: string) => {
    myState.dispatch({ type: "SET_URL_FORM", payload: url });
    myState.dispatch({ type: "SET_TITLE_FORM", payload: title });
    let nameForm = title;
    const nameFormModified = nameForm
      .normalize("NFD")
      .replace(/([aeio])\u0301|(u)[\u0301\u0308]/gi, "$1$2")
      .normalize()
      .replace(/ /g, "-");

    let redirectPath = `/mis-gestiones/${nameFormModified}/form`;
    props.history.push({ pathname: redirectPath });
  };
useEffect(() => {
  

  return () => {
    
  }
}, [])

  return (
    <>
      <PageMenu title="Mis Gestiones" breadcrumbs={breadcrumbs} ></PageMenu>
      <GeneralManagement props={props} handleOnClick={handleOnClick} />
      {isAutonomo && (
        <>
          <PageMenu title="Autorizaciones"></PageMenu>
          <ManagementAuthorizations
            props={props}
            handleOnClick={handleOnClick}
          />
        </>
      )}
      <PageMenu title="Documentos"></PageMenu>
      <GeneralDocuments></GeneralDocuments>
    </>
  );
};

export default Management;
