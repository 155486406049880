import React, { useRef, useState, useEffect } from 'react';
import IdleTimer from 'react-idle-timer';
import Modal from 'components/blocks/Modal/Modal'
import useModal from 'hooks/useModal';
import useInterval from 'hooks/useInterval';
import useMsalB2C from 'hooks/useMsalB2C';

export interface IdleTimerContainerProps {
  isImpersonation?: boolean;
}
const IdleTimerContainer: React.FC<IdleTimerContainerProps> = ({ isImpersonation }) => {
  const idleTimerRef: any = useRef(null);
  const {
    collapsed,
    handleOpenModal,
    handleCloseModal } = useModal();
  const [count, setCount] = useState(59);
  const [delay] = useState(1000);
  const [isRunning, setIsRunning] = useState(false);
  const [reset] = useState(59);
  const { msalInstance } = useMsalB2C()
  const [renderDelayedContent, setRenderDelayedContent] = useState(false);

  const getExpirationTime = () => {
    const account = msalInstance.getActiveAccount();
    const currentTime = Math.floor(new Date().getTime() / 1000);
    const expiresAt = account?.idTokenClaims?.exp || currentTime + 1;
    const expSpanSec = expiresAt - currentTime;
    return expSpanSec;
  }
  const isTokenExpired = () => {
    const expSpanSec = getExpirationTime();
    return expSpanSec < 0

  };
  useInterval(() => {
    setCount(count - 1);
  },
    isRunning ? delay : null,
    reset
  );

  useEffect(() => {
    isTokenExpired()
    if (count === 0) {
      handleRedirect();
    }
  }, [count])
  useEffect(() => {
    const timer = setTimeout(() => {
      setRenderDelayedContent(true);
    }, 2000);

    return () => clearTimeout(timer);
  }, []);
  useEffect(() => {
    checkTokenExpirationTime();
  });
  function checkTokenExpirationTime() {
    const expSpanSec = getExpirationTime();
    if (expSpanSec > 0) {
      const milisecondsDelay = (expSpanSec + 1) * 1000;
      setTimeout(() => { checkTokenExpirationTime(); }, milisecondsDelay);
    } else {
      handleOpenModal();
    }
  }

  function handleStopAndReset() {
    setIsRunning(false);
    setCount(59);
  }
  function handleStartCounter() {
    setIsRunning(true);
  }

  const handleOnIdle = () => {
    if (!isImpersonation) {
      handleOpenModal();
      handleStartCounter();
    }
  }

  // const handleRedirect = () => {
  //   if (!isImpersonation) {
  //     msalInstance.logoutRedirect()
  //   }
  // }
  const handleRedirect = () => {
    if (!isImpersonation) {
      msalInstance.logoutRedirect({
        postLogoutRedirectUri: "/inicio", 
      });
    }
  };
  useEffect(() => {
    if (count === 0) {
      handleRedirect(); 
    }
  }, [count]);
  

  const handleOnPressContinueSession = () => {
    handleStopAndReset();
    handleCloseModal();
  }
  return (
    <>
      {!isTokenExpired() && !isImpersonation &&
        <div>
          <IdleTimer
            ref={idleTimerRef}
            timeout={600000}
            onIdle={handleOnIdle}
          ></IdleTimer>
          <Modal
            collapsed={collapsed}
            position={99999}
          >
            <div className="c-center">
              <p className="message-idle-session">
                <span className="message-idle-session__em d-block mb-4">¿Continúa conectado?</span>
                Su sesión va a expirar en
                <span className="d-block"><span className="message-idle-session__em">{count}</span>  segundos</span>
              </p>
              <div className="mt-4">
                <button className="btn-box btn-accent" onClick={handleOnPressContinueSession}>Continuar sesión</button>
              </div>
            </div>
          </Modal>
        </div>
      }
      {renderDelayedContent && isTokenExpired() &&
        <div>
          <IdleTimer
            ref={idleTimerRef}
            timeout={6000}
            onIdle={handleOnIdle}
          ></IdleTimer>
          <Modal
            collapsed={collapsed}
            position={99999}>
            <div className="c-center">
              <p className="message-idle-session">
                <span className="message-idle-session__em d-block mb-4">Su Sesión ha Expirado</span>
                {isImpersonation &&
                  <span className="message-idle-session__em d-block mb-4">Cierre o refresque la ficha del paciente para reactivar el visor del Portal Paciente</span>
                }
              </p>
              {!isImpersonation &&
                <div className="mt-4">
                  <button className="btn-box btn-accent" onClick={handleRedirect}>Reiniciar sesión</button>
                </div>
              }
            </div>
          </Modal>
        </div>
      }
    </>
  )
}

export default IdleTimerContainer;