import React, { useState } from 'react';
import { FieldValues, RefCallBack, UseFormRegister, useForm, useFormContext } from 'react-hook-form';

interface IInputProps {
    name: string,
    id: string,
    label: string,
    placeholder: string,
    errors: any,
    register: UseFormRegister<FieldValues>,
    setError: any,
    readonly?: boolean,
    defaultValue?: string
    disabled?: boolean
    maxLength?: number,
    value?: any,
    isvalidateNumber?: boolean,
    isvalidateNumberPostalCode?: boolean,
    req?: string
    xl?: number
    md?: number
}

const InputUpload: React.FC<IInputProps> = ({ name, id, label, placeholder, errors, register, readonly, defaultValue, disabled, maxLength, req, xl, md, setError }) => {

    const [ErrorMensaje, setErrorMensaje] = useState<string>('');
    const validateFile = (value: any) => {
        
        if(!req){
            
            return true;
        }


        // console.log(value[0].size)
        if (!value || value.length === 0) {
            setErrorMensaje(req);
            return false;
        }
        if (value[0].size > 8 * 1024 * 1024) { 
            
            setErrorMensaje("El archivo supera los 8MB");
            return false;
        }
        return true;
    };


    return (
        <div className={`col-xl-${xl} col-md-${md} mb-3`}>

            <label className="form-label">{label}</label>
            <input type="file" className=" form-control" id={id} {...register(name, { validate: validateFile })} />


            {errors[name] && errors ?
                <p className="error m-0 ">
                    {ErrorMensaje}
                </p>
                : ''}

            

            {errors?.[name.split('.')[0]] && errors?.[name.split('.')[0]][name.split('.')[1]]?.[name.split('.')[2]]?.message ? //procesamiento de error cuando se usa dentro de un ArrayField
                <>
                    <p className="error m-0 ">
                        {errors?.[name.split('.')[0]][name.split('.')[1]]?.[name.split('.')[2]]?.message}
                    </p>
                </>
                : ''}
        </div>
    )
}

export default InputUpload;

// USE EXAMPLE
{/* <InputUpload 
  id="personalDataName"
  name="personalDataName"
  label="Nombre"
  placeholder="Nombre"
  errors={errors}
  register={register}
  req="Este campo es requerido"
/> */}