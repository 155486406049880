import React, {useState,useEffect} from 'react';
import PageMenu from 'components/blocks/PageMenu/PageMenu';
import Modal from 'components/blocks/Modal/Modal';
import FormValidateOTP from "components/blocks/Forms/FormValidateOTP";
import useGetRequestDocumentOtp from "services/record/useGetRequestDocumentOtp";
import useModal from 'hooks/useModal';

import IconArrowGray from "../../../img/icons/svg/botones/flecha-big-gris.svg";
import useDecode from 'hooks/useDecode';
import useGetAllHealthcareDocument from '../../../services/record/useGetAllHealthcareDocument';
import useGetAllNonAssistanceDocuments from 'services/record/useGetAllNonAssistanceDocuments';


const DownloadDocument = (props) => {
  const [document,setDocument] = useState(null);
  const [breadcrumbs,setBreadcrumbs] = useState([]);
  // :recordId/:documentType/:documentoId
  const tokenResponse = useDecode("token");
  const userId = tokenResponse && tokenResponse.extension_id
  const recordId = props.match.params.recordId;
  const documentType = props.match.params.documentType;
  const documentId = props.match.params.documentId;
  const {
    collapsed,
    handleOpenModal,
    handleCloseModal,
    disabledCloseButton,
    handleDisableCloseButton
  } = useModal();
  const {
    requestDocumentOtp,
    getRequestDocumentOtp,
    loadingGetRequestDocumentOtp,
    errorGetRequestDocumentOtp
  } = useGetRequestDocumentOtp();
  const {    
    getAllHealthcareDocument,
    } = useGetAllHealthcareDocument();
    const {
      allNonAssistanceDocuments,
    } = useGetAllNonAssistanceDocuments();

  useEffect(()=> {
    if(documentType=== "asistencial") {
      getAllHealthcareDocument(userId,recordId, (handleFilterDocument));
      setBreadcrumbs(["Notificaciones", "Documento asistencial"])
    } else if (documentType=== "gestion"){
      allNonAssistanceDocuments(userId,recordId,handleFilterDocument);
      setBreadcrumbs(["Notificaciones", "Documento de gestión"])
    }

  },[])

  const handleFilterDocument = (data) => {
    const filteredDocument = data.filter((document) => document.id === documentId)
    setDocument(filteredDocument[0])
  }

  const handleRequestDocument = (documentID)=> {
    // setDocumentID(documentID);
    handleOpenModal();
    getRequestDocumentOtp(userId, recordId, documentID);
  };
  
  return (
    <>
    {document !==null?
      <>
        <PageMenu
          title={document?.name}
          breadcrumbs={breadcrumbs}
        >
          <div className="btns-header-container">
            <button onClick={()=> props.history.goBack()}><img src={IconArrowGray} alt="icon arrow right"/></button>
          </div>
        </PageMenu>
        <section className="container">
          <div className="row">
            <div className="col-lg-6 offset-3">
              <p>{document.description}</p>
              <p><strong>Tipo de documento:</strong>{document.type_document}</p>
              <div className="btns-container mt-5">
                {documentType=== "asistencial" &&                
                  <button className="btn-box btn-accent"
                    onClick={handleRequestDocument}
                  >Descargar</button>
                }
                {documentType=== "gestion" &&
                  <div className="d-inline">
                    <a
                      href={document.endpoint}
                      rel={document.document_name}
                      // target="_blank"
                      className="btn-box btn-accent"
                    >
                      Descargar
                    </a>
                  </div>
                }
              </div>
            </div>
          </div>
        </section>
      </>
      : null
    }
      <Modal
        disabledCloseButton={disabledCloseButton}
        collapsed={collapsed}
        onClose={handleCloseModal}
        title={"Introduzca el código"}
        subtitle={"Para visualizar el documento, introduzca el código que hemos enviado a su teléfono"}
      >
        {collapsed ? (
          <div className="ghost-form"></div>
        ) : (
          <>
          {requestDocumentOtp && !loadingGetRequestDocumentOtp && !errorGetRequestDocumentOtp?          
            <FormValidateOTP
              handleDisableCloseButton={handleDisableCloseButton}
              onClose={handleCloseModal}
              props={props}
              documentID={documentId}
              userId={userId}
              prestacionId={recordId}
            />
          :
          <>
            {errorGetRequestDocumentOtp?
              <div>
                <h3 className="modal_title">Error</h3>
                <p className="c-center">Lo sentimos, se ha producido un error</p>
              </div>
            :
              <p className="loader-text c-center">Loading...</p>
            }
          </>
          }
          </>
        )}
      </Modal>
    </>
  )
}

export default DownloadDocument;