import React from 'react'
import { useState } from "react";

export const useCreateSolicitudVolanteAsistencia = () => {

    const BASE_URL = process.env.REACT_APP_COMUNICACIONES_PATH || "";
    const FUNCTIONS_KEY = process.env.REACT_APP_COMUNICACIONES_KEY || "";

    const [loadingCreateVolanteAsistencia, setLoadingCreateVolanteAsistencia] = useState(false);
    const [successCreateVolanteAsistencia, setSuccessCreateVolanteAsistencia] = useState(false);
    const [errorCreateVolanteAsistencia, setErrorCreateVolanteAsistencia] = useState(null);


    const createVolanteAsistencia = async (id:string ,data: any) => {
        setLoadingCreateVolanteAsistencia(true);
        setErrorCreateVolanteAsistencia(null);
        setTimeout(() => {
            setLoadingCreateVolanteAsistencia(false);   
        }, 3000)
    }


    return {
        createVolanteAsistencia,
        loadingCreateVolanteAsistencia,
        successCreateVolanteAsistencia,
        errorCreateVolanteAsistencia
      }
}

export default useCreateSolicitudVolanteAsistencia;