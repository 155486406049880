import React, { useEffect, useState, createContext, useContext } from 'react'
import { useLocation, Link } from 'react-router-dom';
import PageMenu from 'components/blocks/PageMenu/PageMenu';
import IconArrowGray from "../../../img/icons/svg/botones/flecha-big-gris.svg";
import FieldsetContainer from 'components/blocks/Delta/Inputs/Containers/FieldsetContainer';
import { FormProvider, useForm } from 'react-hook-form';
import Loader from 'components/blocks/Loader/Loader';
import InputPattern from 'components/blocks/ManagementNewForms/Inputs/InputPattern';
import InputNIE from 'components/blocks/ManagementNewForms/Inputs/InputNIE';
import Province from 'components/blocks/Delta/Inputs/Province';
import TestCommunity from 'components/blocks/Delta/Inputs/TestCommunity';
import YesNoNoAnswer from 'components/blocks/Delta/Groups/YesNoNoAnswer';
import YesNoRadioButtons from 'components/blocks/ManagementNewForms/ButtonGroups/YesNoRadioButtons';
import InputChkBox from 'components/blocks/ManagementNewForms/Inputs/InputChkBox';

export const DeclaracionSituacionActividad: React.FC = () => {

    const location = useLocation();
    const title = "Declaración de Situación de Actividad"
    const breadcrumbs = ["Declaración de Situación de Actividad"];
    const formMethods = useForm();
    const { register, handleSubmit, setError, formState: { errors }, setValue, watch } = formMethods;

    const watchProvinceCode3 = watch("Solicitanteprovince", 0);
    const watchProvinceDescription3 = watch("Solicitanteprovince_description");
    const watchCommunityCode3 = watch("SolicitantecommunityCode");
    const watchCommunityDescription3 = watch("SolicitantecommunityDescription");

    const watchProvinceCode = watch("Establecimientoprovince", 0);
    const watchProvinceDescription = watch("Establecimientoprovince_description");
    const watchCommunityCode = watch("EstablecimientocommunityCode");
    const watchCommunityDescription = watch("EstablecimientocommunityDescription");


    const [disableTitularidad, setdisableTitularidad] = useState(false);
    const [disableGetionActividad, setdisableGetionActividad] = useState(false);
    const [disableGetionActividadFamiliar, setdisableGetionActividadFamiliar] = useState(false);

    const onSubmit = async (data: any) => {

        console.log(data);
    };

    useEffect(() => {

        if (watch("titularidad") == 'true') {
            setdisableTitularidad(true);
        }
        else (
            setdisableTitularidad(false)
        )

        if (watch("gestionActividad") == 'Familiar') {
            setdisableGetionActividadFamiliar(true);
            setdisableGetionActividad(true);
        }
        else if (watch("gestionActividad") == 'Empleado' || 'Otro') {
            setdisableGetionActividadFamiliar(false);
            setdisableGetionActividad(true);
        }
        if (watch("gestionActividad") == 'Cese') {
            setdisableGetionActividad(false);
            setdisableGetionActividadFamiliar(false);
        }


    }, [watch("titularidad"), watch("gestionActividad")]);


    return (
        <>
            <PageMenu title={title} breadcrumbs={breadcrumbs}>
                <div className="btns-header-container">
                    <Link to={`/inicio`}>
                        <img src={IconArrowGray} alt="icon arrow right" />
                    </Link>
                </div>
            </PageMenu>
            <FormProvider {...formMethods}>
                <div className="container form--simple-styled" id="formDeltawithoutleave">
                    <div className='paragraph small text-secondary  mt-4'>Resolución de 04/02/2004 del INSS y Resolución de 22/03/2004 del ISM, sobre cumplimiento por los trabajadores por cuenta propia de la
                        obligación establecida en Real Decreto 1273/2003, en la que se establece que la declaración deberá presentarse dentro de los 15 días siguientes
                        a la fecha de la baja médica, en el caso de incapacidad temporal y de los 15 días siguientes a la suspensión de la actividad, en los supuestos de
                        riesgo durante el embarazo.
                    </div>
                    <div className="row">
                        <div className="col-xl-12">
                            <FieldsetContainer legend="" disabled={false}>
                                <InputPattern
                                    id="SolicitanteNombreApellidos"
                                    name="SolicitanteNombreApellidos"
                                    label="Nombres y Apellidos"
                                    placeholder=""
                                    req='Necesita rellenar los nombres y apellidos'
                                    xl={6}
                                    md={6}
                                    errors={errors}
                                    register={register}


                                />
                                <InputPattern
                                    id="SolicitanteNumeroSeguridadSocial"
                                    name="SolicitanteNumeroSeguridadSocial"
                                    label="Número de afiliación a la Seguridad Social"
                                    placeholder=""
                                    req='Falta rellenar el Número de afiliación'
                                    xl={6}
                                    md={6}
                                    errors={errors}
                                    register={register}


                                />
                                <InputNIE
                                    id="SolicitanteNIE"
                                    name="SolicitanteNIE"
                                    label="NIE/NIF/DNI/Pasaporte"
                                    placeholder=""
                                    req='Falta rellenar el Documento de Identificación'
                                    xl={6}
                                    md={6}
                                    errors={errors}
                                    register={register}
                                    maxLength={20}
                                    watch={watch}
                                />
                                <InputPattern
                                    id="SolicitanteCalle"
                                    name="SolicitanteCalle"
                                    label="Calle, Via, Calleja, Avenida, etc."
                                    placeholder=""

                                    xl={6}
                                    md={6}
                                    errors={errors}
                                    register={register}


                                />
                                <div className="col-xl-6">
                                    <Province
                                        setValue={setValue}
                                        errors={errors}
                                        register={register}
                                        watchProvinceCode={watchProvinceCode3}
                                        watchProvinceDescription={watchProvinceDescription3}
                                        idCode="Solicitanteprovince"
                                        idDescription="Solicitanteprovince_description"
                                    />
                                </div>
                                <div className="col-xl-6">
                                    <TestCommunity
                                        errors={errors}
                                        register={register}
                                        setValue={setValue}
                                        idCode="SolicitantecommunityCode"
                                        idDescription="SolicitantecommunityDescription"
                                        filterByProvince={true}
                                        watchProvinceCode={watchProvinceCode3}
                                        watchCommunityCode={watchCommunityCode3}
                                        watchCommunityDescription={watchCommunityDescription3}
                                    />
                                </div>

                            </FieldsetContainer>



                        </div>




                    </div>

                    <div className="row">
                        <div className="col-xl-12">
                            <FieldsetContainer legend="" disabled={false}>
                                <div>
                                    <label className="input-block__label">Soy titular de un establecimiento mercantil, industrial o de otra naturaleza:</label>
                                    <YesNoRadioButtons
                                        yesId="titularidadYes"
                                        noId="titularidadNo"
                                        label='¿Es titular de la actividad?'
                                        name="titularidad"
                                        errors={errors}

                                        register={register}
                                    />
                                </div>
                                <div style={{ display: disableTitularidad ? 'block' : 'none' }}>
                                    <div className="row">
                                        <div className="col-xl-12">
                                            <FieldsetContainer legend="Dicho establecimiento tiene su domicilio social en" disabled={false}>
                                                <InputPattern
                                                    id="EstablecimientoCalle"
                                                    name="EstablecimientoCalle"
                                                    label="Calle, Via, Calleja, Avenida, etc."
                                                    placeholder=""

                                                    xl={6}
                                                    md={6}
                                                    errors={errors}
                                                    register={register}


                                                />
                                                <div className="col-xl-6">
                                                    <Province
                                                        setValue={setValue}
                                                        errors={errors}
                                                        register={register}
                                                        watchProvinceCode={watchProvinceCode}
                                                        watchProvinceDescription={watchProvinceDescription}
                                                        idCode="Establecimientoprovince"
                                                        idDescription="Establecimientoprovince_description"
                                                    />
                                                </div>
                                                <div className="col-xl-6">
                                                    <TestCommunity
                                                        errors={errors}
                                                        register={register}
                                                        setValue={setValue}
                                                        idCode="EstablecimientoommunityCode"
                                                        idDescription="EstablecimientocommunityDescription"
                                                        filterByProvince={true}
                                                        watchProvinceCode={watchProvinceCode}
                                                        watchCommunityCode={watchCommunityCode}
                                                        watchCommunityDescription={watchCommunityDescription}
                                                    />
                                                </div>
                                                <InputPattern
                                                    id="ActividadEconomica"
                                                    name="ActividadEconomica"
                                                    label="Cuya Actividad económica"
                                                    placeholder=""

                                                    xl={6}
                                                    md={6}
                                                    errors={errors}
                                                    register={register}


                                                />
                                                <InputPattern
                                                    id="CNAE2009"
                                                    name="CNAE2009"
                                                    label="CNAE-2009"
                                                    placeholder=""
                                                    maxLength={6}
                                                    xl={6}
                                                    md={6}
                                                    errors={errors}
                                                    register={register}


                                                />
                                            </FieldsetContainer>
                                        </div>
                                    </div>
                                    <div className="row">
                                        <div className="col-xl-12">
                                            <FieldsetContainer legend="Y que durante la situacion de incapacidad temporal / riesgo, la actividad queda gestionada por: " disabled={false}>



                                                <div className="input-block--radio-check__group d-flex flex-wrap flex-column align-items-start">
                                                    <label htmlFor="opcion1" className="input-block--radio-check__label ml-4">
                                                        <span className="text">Familiar</span>
                                                        <input
                                                            type="radio"
                                                            name="gestionActividad"
                                                            id="opcion1"
                                                            value="Familiar"
                                                            {...register("gestionActividad")}
                                                        />
                                                    </label>
                                                    <label htmlFor="opcion2" className="input-block--radio-check__label ">
                                                        <span className="text">Empleado</span>
                                                        <input
                                                            type="radio"
                                                            name="gestionActividad"
                                                            id="opcion2"
                                                            value="Empleado"
                                                            {...register("gestionActividad")}
                                                        />
                                                    </label>
                                                    <label htmlFor="opcion3" className="input-block--radio-check__label ">
                                                        <span className="text">Otra Persona</span>
                                                        <input
                                                            type="radio"
                                                            name="gestionActividad"
                                                            id="opcion3"
                                                            value="Otro"
                                                            {...register("gestionActividad")}
                                                        />
                                                    </label>
                                                    <label htmlFor="opcion4" className="input-block--radio-check__label ">
                                                        <span className="text">Cese temporal o definitivo de la actividad durante la situación de incapacidad temporal/riesgo durante el embarazo o durante la
                                                            lactancia natural del titular del establecimiento</span>
                                                        <input
                                                            type="radio"
                                                            name="gestionActividad"
                                                            id="opcion4"
                                                            value="Cese"
                                                            {...register("gestionActividad")}
                                                            defaultChecked
                                                        />
                                                    </label>
                                                </div>

                                                <div className="" style={{ display: disableGetionActividad ? 'block' : 'none' }} >
                                                    <FieldsetContainer legend=" " disabled={false}>
                                                        <InputPattern
                                                            id="GestionadoNombreApellidos"
                                                            name="GestionadoNombreApellidos"
                                                            label="Nombres y Apellidos"
                                                            placeholder=""

                                                            xl={12}
                                                            md={12}
                                                            errors={errors}
                                                            register={register}


                                                        />
                                                        <InputPattern
                                                            id="GestionadoNumeroSeguridadSocial"
                                                            name="GestionadoNumeroSeguridadSocial"
                                                            label="Número de afiliación a la Seguridad Social"
                                                            placeholder=""

                                                            xl={6}
                                                            md={6}
                                                            errors={errors}
                                                            register={register}


                                                        />
                                                        <InputNIE
                                                            id="GestionadoNIE"
                                                            name="GestionadoNIE"
                                                            label="NIE/NIF/DNI/Pasaporte"
                                                            placeholder=""
                                                            watch={watch}
                                                            xl={6}
                                                            md={6}
                                                            errors={errors}
                                                            register={register}
                                                            maxLength={20}
                                
                                                        />
                                                         <div style={{ display: disableGetionActividadFamiliar ? 'block' : 'none' }}>
                                                         <InputPattern
                                                            id="GestionadoParentesco"
                                                            name="GestionadoParentesco"
                                                            label="Parentesco"
                                                            placeholder=""

                                                            xl={12}
                                                            md={6}
                                                            errors={errors}
                                                            register={register}


                                                        />
                                                    </div>
                                                    </FieldsetContainer>

                                                   
                                                </div>



                                            </FieldsetContainer>
                                        </div>
                                    </div>
                                </div>
                                <div style={{ display: disableTitularidad ? 'none' : 'block' }}>
                                    <InputPattern
                                        id="DeclaracionRegimenEspecial"
                                        name="DeclaracionRegimenEspecial"
                                        label="Por el contrario declara que se encuentra incluido en el Régimen Especial de Trabajadores por Cuenta Propia o Autónomos en razón de la actividad económica de"
                                        placeholder=""

                                        xl={12}
                                        md={12}
                                        errors={errors}
                                        register={register}


                                    />
                                </div>


                            </FieldsetContainer>
                        </div>
                    </div>

                    <button className='btn-box btn-accent mt-3 mb-3' type="submit" onClick={handleSubmit(onSubmit)}>
                        Enviar
                    </button>
                    <div className='paragraph small text-secondary  mb-4'>En cumplimiento de lo establecido en la normativa sobre de Protección de Datos de Carácter Personal, Solimat Mutua Colaboradora con la Seguridad Social nº 72 le informa 
que sus datos de carácter personal serán objeto de tratamiento, con la finalidad del mantenimiento y cumplimiento de la relación. La base 
jurídica que legitima el tratamiento de sus datos es el cumplimiento de una obligación legal. Sus datos serán conservados de forma indefinida en nuestra 
entidad para atender a las posibles responsabilidades derivadas del tratamiento. Usted podrá revocar el consentimiento prestado, así como ejercitar sus derechos de 
acceso, rectificación, supresión, oposición, limitación del tratamiento, o portabilidad, en su caso, remitiendo un escrito a la siguiente dirección: Solimat, 
Mutua Colaboradora con la Seguridad Social nº 72, C/ Berna, 1, 45003, Toledo, adjuntando su NIF. Si considera que sus derechos no han sido debidamente 
atendidos, puede contactar con nuestro delegado de protección de datos lopd@solimat.com o interponer una reclamación frente a la Agencia Española de Protección 
de Datos
                    </div>
                </div>


            </FormProvider>

        </>
    )
}

export default DeclaracionSituacionActividad;
