import React, { useEffect, useState, createContext, useContext } from 'react'
import { useLocation, Link } from 'react-router-dom';
import PageMenu from 'components/blocks/PageMenu/PageMenu';
import IconArrowGray from "../../../img/icons/svg/botones/flecha-big-gris.svg";
import FieldsetContainer from 'components/blocks/Delta/Inputs/Containers/FieldsetContainer';
import { FormProvider, useForm } from 'react-hook-form';
import Loader from 'components/blocks/Loader/Loader';
import TrabajadorButtonGroup from 'components/blocks/ManagementNewForms/ButtonGroups/TrabajadorButtonGroup';
import InputPattern from 'components/blocks/ManagementNewForms/Inputs/InputPattern';
import TextAreaDefault from 'components/blocks/ManagementNewForms/TextArea/TextAreaDefault';
import ArrayFieldProcesosIT from 'components/blocks/ManagementNewForms/ButtonGroups/ArrayFieldProcesosIT';
import InputUpload from 'components/blocks/ManagementNewForms/Inputs/InputUpload';

export const SolicitudReconocimientoREL: React.FC = () => {

    const location = useLocation();
    const title = "Solicitud de reconocimiento de riesgo durante el embarazo o la lactancia natural"
    const breadcrumbs = ["Solicitud de reconocimiento de riesgo durante el embarazo o la lactancia natural"];
    const formMethods = useForm();
    const { register, handleSubmit, setError, formState: { errors }, setValue, watch, control } = formMethods;

    const onSubmit = async (data: any) => {

        console.log(data);
    };


    return (
        <>
            <PageMenu title={title} breadcrumbs={breadcrumbs}>
                <div className="btns-header-container">
                    <Link to={`/inicio`}>
                        <img src={IconArrowGray} alt="icon arrow right" />
                    </Link>
                </div>
            </PageMenu>
            <FormProvider {...formMethods}>
                <div className="container form--simple-styled" id="formDeltawithoutleave">
                    <div className='paragraph small text-secondary  mt-4'>IMPORTANTE: El certificado médico que solicita justificará, en su caso, la necesidad de puesto de trabajo o de las funciones
                        que realiza. Sólo si dicho cambio no se produce por los motivos previstos legalmente, podrá acceder con posterioridad a la
                        prestación de riesgo durante el embarazo la lactancia natural.
                    </div>
                    <TrabajadorButtonGroup errors={errors} register={register} label={'Datos Personales'} setValue={setValue} watch={watch}
                        acronimo='Solicitante' seguridadSocial={true} localizacion={false} telefono={true} nacimiento={true} email={true} >


                    </TrabajadorButtonGroup>


                    <div className="row">
                        <div className="col-xl-12">
                            <FieldsetContainer legend={'Datos de la Empresa'} disabled={false}>
                                <InputPattern
                                    id={`EmpresaRazonSocial`}
                                    name={`EmpresaRazonSocial`}
                                    label="Nombre o Razón Social"
                                    placeholder=""
                                    req='Falta nombre de la Empresa'
                                    xl={6}
                                    md={6}
                                    errors={errors}
                                    register={register}

                                />
                                <InputPattern
                                    id={`EmpresaCCC`}
                                    name={`EmpresaCCC`}
                                    label="Número Código Cuenta Cotización (CCC)"
                                    placeholder=""
                                    req='Falta Codigo Cuenta Cotizacion'
                                    xl={6}
                                    md={6}
                                    errors={errors}
                                    register={register}

                                />

                            </FieldsetContainer>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-xl-12">
                            <FieldsetContainer legend={''} disabled={false}>
                                <label className="input-block__label">Solicito el certificado médico sobre la necesidad de cambio de puesto de trabajo o de funciones compatibles
                                    con mi estado por: </label>
                                <div className="input-block--radio-check__group d-flex flex-wrap flex-column align-items-start">

                                    <label htmlFor="opcion1" className="input-block--radio-check__label ml-4">
                                        <span className="text">Riesgo durante el embarazo, ya que las condiciones del puesto de trabajo influyen negativamente en mi salud y/o en la del feto. </span>
                                        <input
                                            type="radio"
                                            name="TipoRiesgo"
                                            id="Embarazo"
                                            value="Embarazo"
                                            {...register("TipoRiesgo", { required: 'Necesita seleccionar una opción' })}
                                        />
                                    </label>
                                    <label htmlFor="opcion2" className="input-block--radio-check__label ">
                                        <span className="text">Riesgo durante la lactancia natural, ya que las condiciones del puesto de trabajo influyen negativamente en mi saludo y/o en la del feto.</span>
                                        <input
                                            type="radio"
                                            name="TipoRiesgo"
                                            id="Lactancia"
                                            value="Lactancia"
                                            {...register("TipoRiesgo")}
                                        />
                                    </label>
                                    {errors && errors ?
                                        <p className="error ml-2 ">
                                            {errors && errors['TipoRiesgo']?.message?.toString()}
                                        </p>
                                        : ''}
                                </div>

                                <div className="col-xl-12 col-md-12">
                                    <TextAreaDefault
                                        id="DescripcionAdaptacionTrabajo" name="DescripcionAdaptacionTrabajo" label="Si ha existido adaptación del puesto de trabajo, describa cómo se ha producido:" errors={errors} register={register}
                                        resizable={false}
                                        rows={5}

                                    />
                                </div>

                                <ArrayFieldProcesosIT control={control} register={register} name={'ProcesosIT'} errors={errors} disabled={false} setValue={setValue} watch={watch} />
                            </FieldsetContainer>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-xl-12">
                            <FieldsetContainer legend={'Documentos a Prensentar'} disabled={false}>
                                <InputUpload 
                                name={'ArchivoDniDelante'}
                                id={'ArchivoDniDelante'} 
                                label={'Foto DNI/NIE/NIF por Delante'} 
                                placeholder={''} 
                                errors={errors} 
                                register={register}
                                xl={6} 
                                md={6} 
                                setError={setError} 
                                req='El Dni es necesario'
                                />
                                <InputUpload 
                                name={'ArchivoDniDetras'} 
                                id={'ArchivoDniDetras'} 
                                label={'Foto DNI/NIE/NIF por Detras'} 
                                placeholder={''} 
                                errors={errors} 
                                register={register}
                                xl={6} 
                                md={6} 
                                setError={setError} 
                                
                                />
                                <InputUpload 
                                name={'InformeMedico'} 
                                id={'InformeMedico'} 
                                label={'Informe médico del facultativo del servicio público de salud sobre embarazo y fecha probable de parte o, en su caso, certificando la lactancia natural.'} 
                                placeholder={''} 
                                errors={errors} 
                                register={register}
                                xl={6} 
                                md={6} 
                                setError={setError} 
                                req='El Informe es necesario'
                                />
                                <InputUpload 
                                name={'ArchivoEvaluacionRiesgo'}
                                id={'ArchivoEvaluacionRiesgo'} 
                                label={' Evaluación de riesgos del puesto de trabajo, informe del servicio de prevención de la empresa sobre el riesgo del puesto de trabajo si lo tuviere. '} 
                                placeholder={''} 
                                errors={errors} 
                                register={register}
                                xl={6} 
                                md={6} 
                                setError={setError} 
                               
                                />
                            </FieldsetContainer>
                        </div>
                    </div>

                    {/* BOTON DE ENVIAR */}
                    <button className='btn-box btn-accent mt-3 mb-3' type="submit" onClick={handleSubmit(onSubmit)}>
                        Enviar
                    </button>

                    <div className='paragraph small text-secondary  mb-4'>En cumplimiento de lo establecido en la normativa sobre de Protección de Datos de Carácter Personal, Solimat Mutua Colaboradora con la Seguridad Social nº 72 le informa
                        que sus datos de carácter personal serán objeto de tratamiento, con la finalidad del mantenimiento y cumplimiento de la relación. La base
                        jurídica que legitima el tratamiento de sus datos es el cumplimiento de una obligación legal. Sus datos serán conservados de forma indefinida en nuestra
                        entidad para atender a las posibles responsabilidades derivadas del tratamiento. Usted podrá revocar el consentimiento prestado, así como ejercitar sus derechos de
                        acceso, rectificación, supresión, oposición, limitación del tratamiento, o portabilidad, en su caso, remitiendo un escrito a la siguiente dirección: Solimat,
                        Mutua Colaboradora con la Seguridad Social nº 72, C/ Berna, 1, 45003, Toledo, adjuntando su NIF. Si considera que sus derechos no han sido debidamente
                        atendidos, puede contactar con nuestro delegado de protección de datos lopd@solimat.com o interponer una reclamación frente a la Agencia Española de Protección
                        de Datos
                    </div>
                </div>


            </FormProvider>

        </>
    )
}

export default SolicitudReconocimientoREL;
