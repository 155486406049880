import FieldsetContainer from 'components/blocks/Delta/Inputs/Containers/FieldsetContainer';
import React, { useState } from 'react'
import { useForm, useFieldArray, Controller } from "react-hook-form";
import InputPattern from '../Inputs/InputPattern';
import InputHora from '../Inputs/InputHora';
import InputChkBox from '../Inputs/InputChkBox';

interface IInputProps {
    control: any;
    register: any;
    name: string;
    errors: any;
    disabled?: boolean;
}


//Este componente es el que se encarga de renderizar el array de horarios de Declaración empresarial sobre descripción y exposición a riesgos durante el embarazo o la lactancia natural
//no es muy reutilable en principio pero sirve de base para otros arrayfields
export const ArrayFieldHorario: React.FC<IInputProps> = ({ control, name, register, errors, disabled }) => {

  
    const { fields, append, remove } = useFieldArray({
        control,
        name: name
    });



    return (
        <>

            {fields.map((item, index) => (
                <div className="container form--simple-styled" id={`${name}.${index}`}>
                    <div className="row">
                        <div className="col-xl-12">
                            <FieldsetContainer legend="Especique Horas, Días y Frecuencia" disabled={disabled}>



                                <div className="col-xl-6 col-md-6">
                                    <InputHora
                                        id={`${name}.${index}.HoraDe`}
                                        name={`${name}.${index}.HoraDe`}
                                        label='Hora Desde:'
                                        placeholder='00:00'
                                        errors={errors}
                                        register={register}
                                        req='Necesita especificar hora de Comienzo'
                                        
                                    />


                                </div>
                                <div className="col-xl-6 col-md-6 col-12">
                                    <InputHora
                                        id={`${name}.${index}.HoraHasta`}
                                        name={`${name}.${index}.HoraHasta`}
                                        label='Hora Hasta:'
                                        placeholder='00:00'
                                        errors={errors}
                                        register={register}
                                        req='Necesita especificar hora finalización'
                                    />
                                </div>

                                <InputChkBox
                                    id={`${name}.${index}.Lunes`}
                                    name={`${name}.${index}.Lunes`}
                                    label="Lunes"
                                    placeholder=""

                                    xl={3}
                                    md={3}
                                    col={6}
                                    errors={errors}
                                    register={register}


                                />
                                <InputChkBox
                                    id={`${name}.${index}.Martes`}
                                    name={`${name}.${index}.Martes`}
                                    label="Martes"
                                    placeholder=""

                                    xl={3}
                                    md={3}
                                    col={6}
                                    errors={errors}
                                    register={register}


                                />
                                <InputChkBox
                                    id={`${name}.${index}.Miercoles`}
                                    name={`${name}.${index}.Miercoles`}
                                    label="Miercoles"
                                    placeholder=""

                                    xl={3}
                                    md={3}
                                    col={6}
                                    errors={errors}
                                    register={register}


                                />
                                <InputChkBox
                                    id={`${name}.${index}.Jueves`}
                                    name={`${name}.${index}.Jueves`}
                                    label="Jueves"
                                    placeholder=""

                                    xl={3}
                                    md={3}
                                    col={6}
                                    errors={errors}
                                    register={register}


                                />
                                <InputChkBox
                                    id={`${name}.${index}.Viernes`}
                                    name={`${name}.${index}.Viernes`}
                                    label="Viernes"
                                    placeholder=""

                                    xl={4}
                                    md={4}
                                    col={6}
                                    errors={errors}
                                    register={register}


                                />
                                <InputChkBox
                                    id={`${name}.${index}.Sabado`}
                                    name={`${name}.${index}.Sabado`}
                                    label="Sábado"
                                    placeholder=""

                                    xl={4}
                                    md={4}
                                    col={6}
                                    errors={errors}
                                    register={register}


                                />
                                <InputChkBox
                                    id={`${name}.${index}.Domingo`}
                                    name={`${name}.${index}.Domingo`}
                                    label="Domingo"
                                    placeholder=""
                                    xl={4}
                                    md={4}
                                    col={12}
                                    errors={errors}
                                    register={register}
                                />
                                <InputPattern
                                                id={`${name}.${index}.DuracionFrecuencia`}
                                                name={`${name}.${index}.DuracionFrecuencia`}
                                                label="Duración/Frecuencia"
                                                placeholder=""

                                                xl={12}
                                                md={12}
                                                errors={errors}
                                                register={register}


                                            />
                                <button id={`btnDelete${index}`}  type="button" onClick={() => remove(index)} className=' btn-box btn-secondary    '>
                                    Borrar Hora
                                </button>
                            </FieldsetContainer>



                        </div>
                    </div>
                </div>
            ))}
            <button
                className="mt-2 btn-box btn-accent"
                type="button"
                disabled={disabled}
                onClick={() => append({})}
            >Añadir Horario</button>
        </>
    )
}

export default ArrayFieldHorario;
