import React from 'react';
import { FieldValues, RefCallBack, UseFormRegister, useForm, useFormContext } from 'react-hook-form';

interface IInputProps {
    name: string,
    id: string,
    label: string,
    placeholder: string,
    errors: any,
    register: any,
    readonly?: boolean,
    defaultValue?: string
    disabled?: boolean
    maxLength?: number,
    handleOnChange?: (e: React.ChangeEvent<HTMLInputElement>) => void;
    value?: any,
    isvalidateNumber?: boolean,
    isvalidateNumberPostalCode?: boolean,
    req?: string

}

const InputHora: React.FC<IInputProps> = ({ name, id, label, placeholder, errors, register, readonly, defaultValue, disabled, maxLength, req }) => {
    const dividirNombre = (nombre: string) => {
        const partes = nombre.split('.');
        const nombreArray = partes[0];
        const indexArray = partes[1];
        const nombreCampo = partes[2];
        
      };

    return (
        <div className={`input-block ${errors && errors[name]?.message ? 'error' : ''}`}>
            <label htmlFor={id} className="input-block__label">{label}</label>
            <input
                type="text"
                name={name}
                id={id}
                placeholder={placeholder}
                {...register(name, { required: req, pattern: { value: /^(0[0-9]|1[0-9]|2[0-3]):([0-5][0-9])$/, message: 'Solo se permite formato de HH:MM (Ejemplo: 08:30)' } })}
                readOnly={readonly || false}
                disabled={disabled || false}
                defaultValue={defaultValue || ''}
                maxLength={5}

            />

            {errors && errors ? //Procesamiento de Error cuando se usa como input normal
                <p className="error m-0 ">
                    {errors && errors[name]?.message}
                </p>
                : ''}

            {errors?.[name.split('.')[0]]&& errors?.[name.split('.')[0]][name.split('.')[1]]?.[name.split('.')[2]]?.message ? //procesamiento de error cuando se usa dentro de un ArrayField
                <>
                <p className="error m-0 ">
                    {errors?.[name.split('.')[0]][name.split('.')[1]]?.[name.split('.')[2]]?.message}
                </p>
                </>
                
                 : ''}
        </div>
    )
}

export default InputHora;

// USE EXAMPLE
{/* <InputText 
  id="personalDataName"
  name="personalDataName"
  label="Nombre"
  placeholder="Nombre"
  errors={errors}
  register={register}
  req = 'MENSAJE DE ERROR CUANDO NO HAYA VALOR'
/> */}