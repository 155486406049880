import FieldsetContainer from 'components/blocks/Delta/Inputs/Containers/FieldsetContainer';
import React, { useState } from 'react'
import { useForm, useFieldArray, Controller } from "react-hook-form";
import InputPattern from '../Inputs/InputPattern';
import InputHora from '../Inputs/InputHora';
import InputChkBox from '../Inputs/InputChkBox';
import InputNIE from '../Inputs/InputNIE';
import InputDate from 'components/blocks/Delta/Inputs/InputDate';
import InputUpload from '../Inputs/InputUpload';

interface IInputProps {
    control: any;
    register: any;
    name: string;
    errors: any;
    disabled?: boolean;
    setError: any
}

//Este componente es el que se encarga de renderizar el array de trabajadores relacionados con la cantidad de ellos en 
//la Declaración empresarial sobre descripción y exposición a riesgos durante el embarazo o la lactancia natural
//no es muy reutilable en principio pero sirve de base para otros arrayfields


export const ArrayFieldUploads: React.FC<IInputProps> = ({ control, name, register, errors, disabled,setError }) => {


    const { fields, append, remove } = useFieldArray({
        control,
        name: name
    });



    return (
        <>

            {fields.map((item, index) => (
                <div className="container form--simple-styled" id={`${name}.${index}`}>
                    <div className="row">
                        <div className="col-xl-12">
                            <FieldsetContainer legend="Otros archivos/Mas datos necesarios a adjuntar" disabled={disabled}>



                                 <InputPattern
                                    id={`${name}.${index}.ArchivoAdjuntadoDescripcion`}
                                    name={`${name}.${index}.ArchivoAdjuntadoDescripcion`}
                                    label="Descripción del Archivo que se adjunta"
                                    placeholder=""
                                
                                    xl={6}
                                    md={6}
                                    errors={errors}
                                    register={register}


                                />
                                <InputUpload
                                    name={`${name}.${index}.ArchivoOtros`}
                                    id={`${name}.${index}.ArchivoOtros`}
                                    label={'Otros archivos a adjuntar'}
                                    placeholder={''}
                                    errors={errors}
                                    register={register}
                                    xl={6}
                                    md={6}
                                    setError={setError} />
                                <button id={`btnDelete${index}`} type="button" onClick={() => remove(index)} className=' btn-box btn-secondary   '>
                                    Borrar Archivo adjunto
                                </button>
                            </FieldsetContainer>



                        </div>
                    </div>
                </div>
            ))}
            <button
                className="mt-2 btn-box btn-accent"
                type="button"
                disabled={disabled || fields.length >= 10}
                onClick={() => append({})}
            >Añadir Otros archivos</button>
        </>
    )
}

export default ArrayFieldUploads;
