import React, { useEffect, useState, createContext, useContext } from 'react'
import { useLocation, Link } from 'react-router-dom';
import PageMenu from 'components/blocks/PageMenu/PageMenu';
import IconArrowGray from "../../../img/icons/svg/botones/flecha-big-gris.svg";
import FieldsetContainer from 'components/blocks/Delta/Inputs/Containers/FieldsetContainer';
import { FormProvider, useForm } from 'react-hook-form';
import Loader from 'components/blocks/Loader/Loader';
import TrabajadorButtonGroup from 'components/blocks/ManagementNewForms/ButtonGroups/TrabajadorButtonGroup';
import InputPattern from 'components/blocks/ManagementNewForms/Inputs/InputPattern';
import InputMultiRadio from 'components/blocks/ManagementNewForms/Inputs/InputMultiRadio';
import InputDate from 'components/blocks/Delta/Inputs/InputDate';
import InputChkBox from 'components/blocks/ManagementNewForms/Inputs/InputChkBox';
import ArrayFieldHijosACargo from 'components/blocks/ManagementNewForms/ButtonGroups/ArrayFieldHijosACargo';
import InputIBAN from 'components/blocks/ManagementNewForms/Inputs/InputIBAN';
import { clear } from 'console';
import InputUpload from 'components/blocks/ManagementNewForms/Inputs/InputUpload';
import ArrayFieldUploads from 'components/blocks/ManagementNewForms/ButtonGroups/ArrayFieldUploads';

export const SolicitudPagoDirectoIncapacidadTemporal: React.FC = () => {

    const location = useLocation();
    const title = "Solicitud de pago directo por incapacidad temporal"
    const breadcrumbs = ["Solicitud de pago directo por incapacidad temporal"];
    const formMethods = useForm();
    const { register, handleSubmit, setError, formState: { errors }, setValue, watch, control } = formMethods;

    const onSubmit = async (data: any) => {

        console.log(data);
    };

    const [disableCertificadoEmpresa, setDisableCertificadoEmpresa] = useState(false);

    return (
        <>
            <PageMenu title={title} breadcrumbs={breadcrumbs}>
                <div className="btns-header-container">
                    <Link to={`/inicio`}>
                        <img src={IconArrowGray} alt="icon arrow right" />
                    </Link>
                </div>
            </PageMenu>
            <FormProvider {...formMethods}>
                <div className="container form--simple-styled" id="formDeltawithoutleave">
                    <TrabajadorButtonGroup
                        errors={errors}
                        register={register}
                        label={'Datos Personales'}
                        setValue={setValue}
                        watch={watch}
                        acronimo='Solicitante'
                        nacimiento={true}
                        email={true}
                        telefono={true}
                        seguridadSocial={true}
                    >
                        <InputPattern
                            id="IRPFVoluntario"
                            name="IRPFVoluntario"
                            label="Tipo IRPF Voluntario"
                            placeholder=""

                            xl={6}
                            md={6}
                            errors={errors}
                            register={register}


                        />
                        <InputPattern
                            id="HijosACargo"
                            name="HijosACargo"
                            label="Nº de Hijos a Cargo"
                            placeholder=""
                            pattern={/^[0-9]+$/}
                            patternMessage='Solo se permiten valores numericos'
                            maxLength={2}
                            xl={6}
                            md={6}
                            errors={errors}
                            register={register}


                        />

                    </TrabajadorButtonGroup>


                    <div className="row">
                        <div className="col-xl-12">
                            <FieldsetContainer legend="Datos de la Prestación" disabled={false}>
                                <InputPattern
                                    id="PrestacionRazonSocial"
                                    name="PrestacionRazonSocial"
                                    label="Nombre o razón social de la empresa "
                                    placeholder=""

                                    xl={6}
                                    md={6}
                                    errors={errors}
                                    register={register}


                                />
                                <InputPattern
                                    id="PrestacionCCC"
                                    name="PrestacionCCC"
                                    label="Código Cuenta Cotización de la empresa"
                                    placeholder=""
                                    pattern={/^[0-9]+$/}
                                    patternMessage='Solo se permiten valores numericos'

                                    xl={6}
                                    md={6}
                                    errors={errors}
                                    register={register}


                                />
                                <InputMultiRadio
                                    errors={errors}
                                    register={register}
                                    opciones={["General", "Autonomo", "Mar"]}
                                    labels={["General", "Autonomo", "Mar"]}
                                    name={'PrestacionRegimenSeguridadSocial'}
                                    id={'PrestacionRegimenSeguridadSocial'}
                                    required='Es necesario elegir una opción'
                                    label='Régimen de la Seguridad Social'
                                />
                                <InputMultiRadio
                                    errors={errors}
                                    register={register}
                                    opciones={["Representante de comercio", "Agrario Cuenta Propia", "Artista", "Otro", " Hogar", "Torero", "Empaquetado Tomate"]}
                                    labels={["Representante de comercio", "Agrario Cuenta Propia", "Artista", "Otro", " Hogar", "Torero", " Empaquetado Tomate"]}
                                    name={'PrestacionColectivoEspecial'}
                                    id={'PrestacionColectivoEspecial'}
                                    required='Es necesario elegir una opción'
                                    label='Colectivo/Sistema Especial'
                                />
                                <InputPattern
                                    id="PrestacionTipoContrato"
                                    name="PrestacionTipoContrato"
                                    label="Tipo de Contrato"
                                    placeholder=""

                                    maxLength={255}

                                    xl={6}
                                    md={6}
                                    errors={errors}
                                    register={register}


                                />
                                <div className="col-xl-6 col-md-6">
                                    <InputDate
                                        id="PrestacionFechaBajaMedica"
                                        name="PrestacionFechaBajaMedica"
                                        label="Fecha de Baja Médica"
                                        maxDate={new Date(Date.now())}
                                        placeholder=""
                                        errors={errors}
                                        register={register}
                                        setValue={setValue}
                                    />
                                </div>
                                <InputMultiRadio
                                    errors={errors}
                                    register={register}
                                    opciones={["Si", "No"]}
                                    labels={["Sí", "No"]}
                                    name={'PrestacionBajaUltimos6Meses'}
                                    id={'PrestacionBajaUltimos6Meses'}

                                    label='¿Ha estado de baja médica en los seis meses anteriores por el mismo motivo?'
                                />
                                <InputMultiRadio
                                    errors={errors}
                                    register={register}
                                    opciones={["EnfermedadComun", "AccidenteNoLaboral", "EnfermedadProfesional", "AccidenteDeTrabajo"]}
                                    labels={["Enfermedad Común (EC)", "Accidente No Laboral (ANL)", "Enfermedad Profesional (EP)", "Accidente de Trabajo (AT)"]}
                                    name={'PrestacionCausaBaja'}
                                    id={'PrestacionCausaBaja'}
                                    required='Es necesario elegir una opción'
                                    label='Causa de Baja'
                                />


                            </FieldsetContainer>
                        </div>
                    </div>



                    <div className="row">
                        <div className="col-xl-12">
                            <FieldsetContainer legend="Causa del Pago Directo (sólo para trabajadores/as por cuenta ajena)" disabled={false}>
                                <InputMultiRadio
                                    errors={errors}
                                    register={register}
                                    opciones={["Despido", "Resolución judicial, administrativa o acto firme", "Extinción por fallecimiento, jubilación o invalidez", "Extinción como persona jurídica", "Baja voluntaria", "Extinción recogida en el contrato"]}
                                    labels={["Despido", "Resolución judicial, administrativa o acto firme", "Extinción por fallecimiento, jubilación o invalidez", "Extinción como persona jurídica", "Baja voluntaria", "Extinción recogida en el contrato"]}
                                    name={'CausaExtincionRelacionLaboral'}
                                    id={'CausaExtincionRelacionLaboral'}

                                    label='Extinción de la relación laboral por alguna de estas causas '
                                />
                                <InputMultiRadio
                                    errors={errors}
                                    register={register}
                                    opciones={["12 Meses", "18 Meses", "Disconformidad alta INSS"]}
                                    labels={["12 Meses", "18 Meses", "Disconformidad alta INSS"]}
                                    name={'AgotamientoPlazo'}
                                    id={'AgotamientoPlazo'}

                                    label='Agotamiento del plazo máximo de'
                                />
                                <InputChkBox
                                    id="TrabajadorSituacionJubilacionParcial"
                                    name="TrabajadorSituacionJubilacionParcial"
                                    label="Trabajador en situación de jubilación parcia"
                                    placeholder=""
                                    col={12}
                                    xl={6}
                                    md={6}
                                    errors={errors}
                                    register={register}


                                />

                                <InputChkBox
                                    id="TrabajadoresPagoDelegado"
                                    name="TrabajadoresPagoDelegado"
                                    label="Trabajadores/as excluidos/as de pago delegado"
                                    placeholder=""
                                    col={12}
                                    xl={6}
                                    md={6}
                                    errors={errors}
                                    register={register}

                                />

                                <InputChkBox
                                    id="IncumplimientoObligacionPagoDelegado"
                                    name="IncumplimientoObligacionPagoDelegado"
                                    label="Incumplimiento de la obligación de pago delegado por parte del empresario"
                                    placeholder=""
                                    col={12}
                                    xl={6}
                                    md={6}
                                    errors={errors}
                                    register={register}

                                />

                                <InputChkBox
                                    id="RecaidaPorAccidente"
                                    name="RecaidaPorAccidente"
                                    label="Recaída por accidente de trabajo o enfermedad profesional en situación de desempleo"
                                    placeholder=""
                                    col={12}
                                    xl={6}
                                    md={6}
                                    errors={errors}
                                    register={register}

                                />

                                <InputChkBox
                                    id="SuspensionRelacionLaboral"
                                    name="SuspensionRelacionLaboral"
                                    label="Suspensión (Trabajadores/as Fijos-discontinuos o Excedencia voluntaria)"
                                    placeholder=""
                                    col={12}
                                    xl={6}
                                    md={6}
                                    errors={errors}
                                    register={register}

                                />

                                <InputChkBox
                                    id="EmpresasPocosEmpleados"
                                    name="EmpresasPocosEmpleados"
                                    label="Empresas de menos de diez trabajadores/as y más de seis meses consecutivos de abono de la prestación en pago delegado"
                                    placeholder=""
                                    col={12}
                                    xl={6}
                                    md={6}
                                    errors={errors}
                                    register={register}

                                />
                            </FieldsetContainer>
                        </div>
                    </div>

                    <div className="row">
                        <div className="col-xl-12">
                            <FieldsetContainer legend="Hijos/as a Cargo (solamente para baja médica de EC-ANL cuando causa pago directo por extinción de la relación laboral)" disabled={false}>
                                <ArrayFieldHijosACargo control={control} name={'HijosACargo'} errors={errors} register={register} watch={watch} setValue={setValue} />
                            </FieldsetContainer>
                        </div>
                    </div>

                    <div className="row">
                        <div className="col-xl-12">
                            <FieldsetContainer legend="Datos Bancarios" disabled={false}>
                                <InputIBAN
                                    id='SolicitanteIBAN'
                                    name='SolicitanteIBAN'
                                    label='IBAN'
                                    placeholder=''
                                    xl={12}
                                    md={12}
                                    errors={errors}
                                    register={register}

                                    req='Falta IBAN'

                                />
                            </FieldsetContainer>
                        </div>
                    </div>
                    <div className='paragraph small text-secondary  mt-4'>Con la firma de la presente solicitud, declaro bajo mi responsabilidad que son ciertos todos los datos que consigno en la misma y quedo enterado de la
                        obligación de comunicar a la Mutua cualquier variación de los datos en ella expresados, que pudieran producirse durante la percepción de la prestación
                        de Incapacidad Temporal.</div>
                    <div className="row">
                        <div className="col-xl-12">
                            <FieldsetContainer legend="Archivos a Adjuntar" disabled={false}>
                                <InputUpload
                                    name={'ArchivoDniDelante'}
                                    id={'ArchivoDniDelante'}
                                    label={'Foto DNI/NIE/NIF por Delante'}
                                    placeholder={''}
                                    errors={errors}
                                    register={register}
                                    xl={6}
                                    md={6}
                                    setError={setError}
                                    req='El Dni es necesario'
                                />
                                <InputUpload
                                    name={'ArchivoDniDetras'}
                                    id={'ArchivoDniDetras'}
                                    label={'Foto DNI/NIE/NIF por Detras'}
                                    placeholder={''}
                                    errors={errors}
                                    register={register}
                                    xl={6}
                                    md={6}
                                    setError={setError} />
                                <label className="input-block__label mb-2 mt-4">Archivos a Adjuntar para trabajadores por cuenta ajena</label>
                                <InputUpload
                                    name={'ArchivoTrabajadorAjenaParteBajaMedica'}
                                    id={'ArchivoTrabajadorAjenaParteBajaMedica'}
                                    label={'Si la baja médica es por contingencia común (EC/ANL) parte de baja médica'}
                                    placeholder={''}
                                    errors={errors}
                                    register={register}
                                    xl={6}
                                    md={6}
                                    setError={setError} />
                                <InputUpload
                                    name={'ArchivoTrabajadorAjenaParteConfirmacion'}
                                    id={'ArchivoTrabajadorAjenaParteConfirmacion'}
                                    label={'Partes de confirmación posteriores al último presentado en su empresa'}
                                    placeholder={''}
                                    errors={errors}
                                    register={register}
                                    xl={6}
                                    md={6}
                                    setError={setError} />
                                <label className="input-block__label mb-2 mt-4">El Certificado de Empresa se deberá adjuntar en los siguientes casos con la siguiente información:</label>
                                <label className="input-block__label ml-3 mb-3"> 1. Si la causa del pago directo del apartado de esta solicitud es por extinción de la relación laboral y la causa de su baja médica del apartado 2 es
                                    EC o ANL, certificado de empresa con los 180 últimos días cotizados en su empresa o empresas anteriores</label>
                                <label className="input-block__label ml-3 mb-3"> 2. Para el resto de supuestos de pago directo: certificado de empresa del mes anterior a la baja.</label>
                                <label className="input-block__label ml-3 mb-3"> 2.1. Representantes de comercio: justificante de pago de las cotizaciones a la Seguridad Social de los tres meses anteriores a su baja médica, así como
                                    certificación de la Tesorería General de la Seguridad Social de estar al corriente.</label>
                                <label className="input-block__label ml-3 mb-3"> 2.2. Artistas y profesionales taurinos: declaración de actividades (TC 4/6) y justificantes de actuaciones (TC 4/5), que no hayan sido presentados en la
                                    Tesorería General de la Seguridad Social.</label>
                                <label className="input-block__label ml-3 mb-3"> 2.3. Agrarios cuenta Ajena: certificado de empresa del mes anterior a la baja, si la baja es por Enfermedad Comun certificado de los 12 meses anteriores al
                                    mes de la baja. .</label>
                                <label className="input-block__label ml-3 mb-3"> 2.4. Sistema Especial de Hogar: certificado del empleador o empleadores, del mes anterior a la baja médica</label>

                                <InputUpload
                                    name={'ArchivoCertificadoEmpresa'}
                                    id={'ArchivoCertificadoEmpresa'}
                                    label={'Certificado de Empresa'}
                                    placeholder={''}
                                    errors={errors}
                                    register={register}
                                    xl={12}
                                    md={12}
                                    setError={setError} />
                                <label className="input-block__label mb-2 mt-4">Trabajadores por cuenta propia</label>
                                <InputUpload
                                    name={'ArchivoTrabajadorPropiaParteConfirmacion'}
                                    id={'ArchivoTrabajadorPropiaParteConfirmacion'}
                                    label={'Parte Médico'}
                                    placeholder={''}
                                    errors={errors}
                                    register={register}
                                    xl={6}
                                    md={6}
                                    setError={setError} />
                                <InputUpload
                                    name={'ArchivoTrabajadorPropiaParteConfirmacion'}
                                    id={'ArchivoTrabajadorPropiaParteConfirmacion'}
                                    label={'Parte de Confirmación'}
                                    placeholder={''}
                                    errors={errors}
                                    register={register}
                                    xl={6}
                                    md={6}
                                    setError={setError} />

                                <InputUpload
                                    name={'ArchivoTrabajadorPropiaJustificantePagoCotizaciones'}
                                    id={'ArchivoTrabajadorPropiaJustificantePagoCotizaciones'}
                                    label={' Justificante de pago de sus cotizaciones de los dos últimos meses anteriores al mes de la baja médica.'}
                                    placeholder={''}
                                    errors={errors}
                                    register={register}
                                    xl={6}
                                    md={6}
                                    setError={setError} />
                                    <InputUpload
                                    name={'ArchivoTrabajadorPropiaDeclaracionActividad'}
                                    id={'ArchivoTrabajadorPropiaDeclaracionActividad'}
                                    label={'Declaración de situación de actividad'}
                                    placeholder={''}
                                    errors={errors}
                                    register={register}
                                    xl={6}
                                    md={6}
                                    setError={setError} />


                                <ArrayFieldUploads control={control} register={register} name={'ArchivoOtros'} errors={errors}  setError={setError} />
                            </FieldsetContainer>
                        </div>
                    </div>


                    {/* BOTON DE ENVIAR */}
                    <button className='btn-box btn-accent mt-3 mb-3' type="submit" onClick={handleSubmit(onSubmit)}>
                        Enviar
                    </button>


                    <div className='paragraph small text-secondary  mb-4'>En cumplimiento de lo establecido en la normativa sobre de Protección de Datos de Carácter Personal, Solimat Mutua Colaboradora con la Seguridad Social nº 72 le informa que sus datos de
                        carácter personal serán objeto de tratamiento, con la finalidad del mantenimiento y cumplimiento de la relación. La base jurídica que legitima el tratamiento de sus datos es el
                        cumplimiento de una obligación legal. Sus datos serán conservados de forma indefinida en nuestra entidad para atender a las posibles responsabilidades derivadas del tratamiento. Usted
                        podrá revocar el consentimiento prestado, así como ejercitar sus derechos de acceso, rectificación, supresión, oposición, limitación del tratamiento, o portabilidad, en su caso,
                        remitiendo un escrito a la siguiente dirección: Solimat, Mutua Colaboradora con la Seguridad Social nº 72, C/ Berna, 1, 45003, Toledo, adjuntando su NIF. Si considera que sus
                        derechos no han sido debidamente atendidos, puede contactar con nuestro delegado de protección de datos lopd@solimat.com o interponer una reclamación frente a la Agencia
                        Española de Protección de Datos.</div>

                    <div className='paragraph small text-secondary  mb-4'>En el caso de que no haya presentado toda la documentación que le hemos solicitado, dispone de un plazo de diez días para presentarla. Transcurrido dicho plazo, si no lo aporta, se le
                        tendrá por desistido, de acuerdo con lo previsto en el art. 68 de la Ley 39/2015, de 1 de octubre, del Procedimiento Administrativo Común de las Administraciones Públicas. (B.O.E. 2 de
                        octubre de 2015).</div>
                </div>


            </FormProvider>

        </>
    )
}

export default SolicitudPagoDirectoIncapacidadTemporal;
