import React, { useEffect, useState, createContext, useContext } from 'react'
import { useLocation, Link } from 'react-router-dom';
import PageMenu from 'components/blocks/PageMenu/PageMenu';
import IconArrowGray from "../../../img/icons/svg/botones/flecha-big-gris.svg";
import FieldsetContainer from 'components/blocks/Delta/Inputs/Containers/FieldsetContainer';
import { FormProvider, useForm } from 'react-hook-form';
import Loader from 'components/blocks/Loader/Loader';
import InputPattern from 'components/blocks/ManagementNewForms/Inputs/InputPattern';
import RadioButtonGroup from 'components/blocks/ManagementNewForms/ButtonGroups/RadioButtonGroup';
import TextAreaDefault from 'components/blocks/ManagementNewForms/TextArea/TextAreaDefault';
import InputDate from 'components/blocks/Delta/Inputs/InputDate';
import InputNIE from 'components/blocks/ManagementNewForms/Inputs/InputNIE';

export const CertificadoPrestacionEconomicaREL: React.FC = () => {

    const location = useLocation();
    const title = "Certificado empresarial para la solicitud de la prestación económica por riesgo durante el embarazo o la lactancia natural"
    const breadcrumbs = ["Certificado empresarial para la solicitud de la prestación económica por riesgo durante el embarazo o la lactancia natural"];
    const formMethods = useForm();
    const { register, handleSubmit, setError, formState: { errors }, setValue, watch } = formMethods;

    const onSubmit = async (data: any) => {

        console.log(data);
    };

    return (
        <>
            <PageMenu title={title} breadcrumbs={breadcrumbs}>
                <div className="btns-header-container">
                    <Link to={`/inicio`}>
                        <img src={IconArrowGray} alt="icon arrow right" />
                    </Link>
                </div>
            </PageMenu>
            <FormProvider {...formMethods}>
                <div className="container form--simple-styled" id="formDeltawithoutleave">
                    <div className="row">
                        <div className="col-xl-12">
                            <FieldsetContainer legend="Datos de la empresa" disabled={false}>
                                <InputPattern
                                    id="NombreEmpresa"
                                    name="NombreEmpresa"
                                    label="Nombre de la empresa"
                                    placeholder=""

                                    xl={6}
                                    md={12}
                                    errors={errors}
                                    register={register}
                                    req='Falta Nombre de la Empresa'

                                />

                                <InputPattern
                                    id="CCC"
                                    name="CCC"
                                    label="Codigo Cuenta Cotización"
                                    placeholder="CCC"
                                    xl={6}
                                    md={12}
                                    errors={errors}
                                    register={register}
                                    req='Falta Código Cuenta Cotización'
                                    pattern={/^[0-9]+$/}
                                    patternMessage='Solo se aceptan valores numericos'
                                />

                                <InputPattern
                                    id="NombreRepresentanteEmpresa"
                                    name="NombreRepresentanteEmpresa"
                                    label="Nombre de la Persona que firma el documento"
                                    placeholder=""

                                    xl={6}
                                    md={12}
                                    errors={errors}
                                    register={register}
                                    req='Nombre de la representante'

                                />
                                <InputPattern
                                    id="CargoRepresentanteEmpresa"
                                    name="CargoRepresentanteEmpresa"
                                    label="Cargo de la Persona que firma el documento"
                                    placeholder=""

                                    xl={6}
                                    md={12}
                                    errors={errors}
                                    register={register}
                                    req='Cargo de la representante'

                                />
                                <div className="col-xl-12 col-md-12">
                                    <RadioButtonGroup
                                        nombreGrupo='TipoRiesgo'
                                        chkname1='Riesgo Embarazo'
                                        chkname2='Riesgo Lactancia Natural'
                                        chklabel1='Riesgo durante el Embarazo'
                                        chklabel2='Riesgo durante la Lactancia Natural'
                                        name='TipoRiesgo'
                                        req='Necesita especificar una opción'
                                        label='Declara haber tenido conocimiento de la situación de:'
                                        errors={errors}
                                        register={register}
                                        setValue={setValue} />
                                </div>
                            </FieldsetContainer>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-xl-12">
                            <FieldsetContainer legend="Datos del trabajador" disabled={false}>
                                <InputPattern
                                    id="NombreTrabajador"
                                    name="NombreTrabajador"
                                    label="Nombre de la Trabajadora"
                                    placeholder=""

                                    xl={6}
                                    md={12}
                                    errors={errors}
                                    register={register}
                                    req='Falta Nombre de la Trabajadora'

                                />
                                <InputNIE
                                    id="DNITrabajadora"
                                    name="DNITrabajadora"
                                    label="DNI de la Trabajadora"
                                    placeholder=""
                                    watch={watch}
                                    xl={6}
                                    md={6}
                                    errors={errors}
                                    register={register}
                                    req='DNI de la Trabajadora'

                                />
                            </FieldsetContainer>
                        </div>
                    </div>

                    <div className="row">
                        <div className="col-xl-12">
                            <FieldsetContainer legend="Declara:" disabled={false}>
                                <div className="col-xl-12 col-md-12">
                                    <TextAreaDefault
                                        id="DescripcionAdopcionMedidas" name="DescripcionAdopcionMedidas" label="1.- Que habiendo intentado la adopción de las medidas necesarias para evitar la exposición de dicha 
trabajadora al riesgo, conforme a lo dispuesto en el art. 26 de la ley de Prevención de Riesgos laborales, 
según redacción dada por la Ley 3/2007 de 22 de marzo, estas no han sido posibles o son insuficientes 
por los siguientes motivos:"
                                        errors={errors} register={register}
                                        resizable={false}
                                        rows={4}
                                        req='Falta la Adopción de Medidas'
                                    />
                                </div>
                                <div className="col-xl-12 col-md-12">
                                    <RadioButtonGroup
                                        nombreGrupo='PuestoExtento'
                                        chkname1='Si'
                                        chkname2='No'
                                        chklabel1='Si'
                                        chklabel2='No'
                                        req='Necesita especificar una opción'
                                        name='PuestoExtento'
                                        label='2.- Que el puesto de trabajo desempeñado por la trabajadora es de los que figuran como 
exentos de riesgo en la relación de puestos de trabajo que ha confeccionado la empresa, previa consulta 
a los representantes de los trabajadores (art. 26 de la LPRL). '
                                        errors={errors}
                                        register={register}
                                        setValue={setValue} />
                                </div>
                                <div className="col-xl-12 col-md-12">
                                    <InputDate
                                        id="accident_accidentDate"
                                        name="accident_accidentDate"
                                        label="3.- En consecuencia y dado que resulta técnica y objetivamente imposible el cambio de puesto, se declara 
el paso de la trabajadora arriba citada a la situación de 
 suspensión del contrato de trabajo con fecha:"
                                        placeholder=""
                                        errors={errors}
                                        register={register}
                                        setValue={setValue}

                                    />


                                </div>
                            </FieldsetContainer>
                        </div>
                    </div>



                    <button className='btn-box btn-accent mt-3 mb-3' type="submit" onClick={handleSubmit(onSubmit)}>
                        Enviar
                    </button>

                    <div className='paragraph small text-secondary  mb-4'>En cumplimiento de lo establecido en la normativa sobre de Protección de Datos de Carácter Personal, Solimat Mutua Colaboradora con la Seguridad Social nº 72 le informa que sus datos de
                        carácter personal serán objeto de tratamiento, con la finalidad del mantenimiento y cumplimiento de la relación. La base jurídica que legitima el tratamiento de sus datos es el
                        cumplimiento de una obligación legal. Sus datos serán conservados de forma indefinida en nuestra entidad para atender a las posibles responsabilidades derivadas del tratamiento. Usted
                        podrá revocar el consentimiento prestado, así como ejercitar sus derechos de acceso, rectificación, supresión, oposición, limitación del tratamiento, o portabilidad, en su caso,
                        remitiendo un escrito a la siguiente dirección: Solimat, Mutua Colaboradora con la Seguridad Social nº 72, C/ Berna, 1, 45003, Toledo, adjuntando su NIF. Si considera que sus
                        derechos no han sido debidamente atendidos, puede contactar con nuestro delegado de protección de datos lopd@solimat.com o interponer una reclamación frente a la Agencia
                        Española de Protección de Datos.
                    </div>
                </div>


            </FormProvider>

        </>
    )
}

export default CertificadoPrestacionEconomicaREL;
