import React, { useState, useEffect } from 'react';
import { Route, Redirect } from 'react-router-dom';
import Layout from "../components/layouts/Layout";

const DeltaRoute = ({ component: Component, ...rest }) => {
  const [isAutonomo, setIsAutonomo] = useState(null); 
  const [loading, setLoading] = useState(true); 

  useEffect(() => {
    const roleSelected = JSON.parse(localStorage.getItem('role') || "[]");

    if (roleSelected.includes("autonomo")) {
      setIsAutonomo(true);
    } else {
      setIsAutonomo(false);
    }
    setLoading(false); 
  }, []); 
  if (loading) {
    return null; 
  }

  return (
    <Layout>
      <Route
        {...rest}
        render={props =>
          isAutonomo ? (
            <Component {...props} />
          ) : (
            <Redirect to="/404" />
          )
        }
      />
    </Layout>
  );
};

export default DeltaRoute;
