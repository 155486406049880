import React from 'react';
import { FieldValues, RefCallBack, UseFormRegister, useForm, useFormContext } from 'react-hook-form';
import { validate } from 'uuid';

interface IInputProps {
    name: string,
    id: string,
    label: string,
    placeholder: string,
    errors: any,
    register: any,
    readonly?: boolean,
    defaultValue?: string
    disabled?: boolean
    req?: string
    xl?: number
    md?: number
}

const InputIBAN: React.FC<IInputProps> = ({ name, id, label, placeholder, errors, register, readonly, defaultValue, disabled, req, xl, md }) => {

    


    const handleOnChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        const valor = e.target.value;
        const longitud = valor.length;
        const ultimoCaracter = e.target.value.slice(-1);
        e.target.value = e.target.value.toUpperCase();

        if (longitud <= 2 && !/^[a-zA-Z]$/.test(ultimoCaracter)) {
            e.target.value = valor.slice(0, -1);

        } else if (longitud > 2 && longitud <= 24) {
            if (!/^[0-9]$/.test(ultimoCaracter)) {
                e.target.value = valor.slice(0, -1);

            }
        }

        

    }

    const Validacion = (value: string) => {
       const  longitud = value.length
        if (longitud === 24) {
            if (!validateIban(value)) {
         
                return "El IBAN no es correcto, reviselo"

            }
        }
    }

    const validateIban = (iban: string): boolean => {
        // Longitudes válidas de IBAN por país
        const ibanLengths: { [key: string]: number } = {
            'ES': 24,

            // Añade más países según sea necesario
        };

        const countryCode = iban.slice(0, 2);
        const length = ibanLengths[countryCode];

        // Verificar longitud
        if (!length || iban.length !== length) {
            return false;
        }

        // Reorganizar IBAN para la validación
        const rearrangedIban = iban.slice(4) + iban.slice(0, 4);
        const numericIban = rearrangedIban
            .split('')
            .map((char) => (isNaN(Number(char)) ? char.charCodeAt(0) - 55 : char))
            .join('');

        // Validación usando el módulo 97
        const remainder = BigInt(numericIban) % BigInt(97);
        return remainder === BigInt(1);
    };





    return (
        <div className={`col-xl-${xl} col-md-${md}`}>


            <div className={`input-block ${errors && errors[name]?.message ? 'error' : ''}`}>
                <label htmlFor={id} className="input-block__label">{label}</label>
                <input
                    type="text"
                    name={name}
                    id={id}
                    placeholder={placeholder}
                    {...register(name, { required: req , minLength: {value: 24, message: 'El IBAN no tiene el formato adecuado'}, validate: Validacion })}
                    readOnly={readonly || false}
                    disabled={disabled || false}
                    defaultValue={defaultValue || ''}
                    maxLength={24}
                    onChange={handleOnChange}

                />

                {errors && errors ?
                    <p className="error m-0 ">
                        {errors && errors[name]?.message}
                    </p>
                    : ''}


            </div>
        </div>
    )
}

export default InputIBAN;

// USE EXAMPLE
{/* <InputText 
  id="personalDataName"
  name="personalDataName"
  label="Nombre"
  placeholder="Nombre"
  errors={errors}
  register={register}
  req = 'MENSAJE DE ERROR CUANDO NO HAYA VALOR'
/> */}