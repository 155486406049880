import React, { useEffect, useState, createContext, useContext } from 'react'
import { useLocation, Link } from 'react-router-dom';
import PageMenu from 'components/blocks/PageMenu/PageMenu';
import IconArrowGray from "../../../img/icons/svg/botones/flecha-big-gris.svg";
import FieldsetContainer from 'components/blocks/Delta/Inputs/Containers/FieldsetContainer';
import { FormProvider, useForm } from 'react-hook-form';
import Loader from 'components/blocks/Loader/Loader';
import RadioButtonGroup from 'components/blocks/ManagementNewForms/ButtonGroups/RadioButtonGroup';
import InputPattern from 'components/blocks/ManagementNewForms/Inputs/InputPattern';
import TextAreaDefault from 'components/blocks/ManagementNewForms/TextArea/TextAreaDefault';
import Province from 'components/blocks/Delta/Inputs/Province';
import TestCommunity from 'components/blocks/Delta/Inputs/TestCommunity';
import InputIBAN from 'components/blocks/ManagementNewForms/Inputs/InputIBAN';
import InputNIE from 'components/blocks/ManagementNewForms/Inputs/InputNIE';

export const SolicitudGastosContingenciaProfesional: React.FC = () => {

    const location = useLocation();
    const title = "Solicitud de Abono de Gastos Contingencias Profesionales";
    const breadcrumbs = ["Solicitud de Abono de Gastos Contingencias Profesionales"];
    
    const formMethods = useForm();
    const { register, handleSubmit, setError, formState: { errors }, setValue, watch, clearErrors } = formMethods;


    const watchProvinceCode = watch("provinceSolicitante", 0);
    const watchProvinceDescription = watch("province_descriptionSolicitante");
    const watchCommunityCode = watch("communityCodeSolicitante");
    const watchCommunityDescription = watch("communityDescriptionSolicitante");


    const onSubmit = async (data: any) => {
        
        console.log(data); 
    };


    return (
        <>
            <PageMenu title={title} breadcrumbs={breadcrumbs}>
                <div className="btns-header-container">
                    <Link to={`/inicio`}>
                        <img src={IconArrowGray} alt="icon arrow right" />
                    </Link>
                </div>
            </PageMenu>
            <FormProvider {...formMethods}>
                <div className="container form--simple-styled" id="formDeltawithoutleave">
                    <div className="row">
                        <div className="col-xl-12">
                            <FieldsetContainer legend="Tipo y Descripción de Gastos" disabled={false}>
                                <div className="col-xl-4 col-md-6">
                                    <RadioButtonGroup
                                        nombreGrupo='TipoGasto'
                                        chkname1='Gastos de Farmacia'
                                        chkname2='Gastos de Transporte'
                                        chklabel1='Gastos Farmacia'
                                        chklabel2='Gastos Transporte'
                                        name='tipoContingencia'
                                        label='Tipo de Contingencia'
                                        req='Necesita especificar una opción'
                                        errors={errors}
                                        register={register}
                                        setValue={setValue} />
                                </div>

                                <div className="col-xl-8 col-md-6">
                                    <TextAreaDefault

                                        errors={errors}
                                        register={register}
                                        id='DescripcionContingencia'
                                        name='DescripcionContingencia'
                                        label='Descripcion de la Contingencia'
                                        placeholder=""

                                    />
                                </div>


                            </FieldsetContainer>
                        </div>
                    </div>

                    <div className="row">
                        <div className="col-xl-12">
                            <FieldsetContainer legend="Datos de Identificiación" disabled={false}>

                                <InputPattern
                                    id="NombreApellidoSolicitante"
                                    name="NombreApellidoSolicitante"
                                    label="Nombre y Apellido"
                                    placeholder=""
                                    xl={12}
                                    md={12}
                                    errors={errors}
                                    register={register}
                                    req='Falta Nombre y Apellido'
                                />
                                <InputNIE
                                    id="DNISolicitante"
                                    name="DNISolicitante"
                                    label="DNI del Solicitante"
                                    placeholder=""
                                    watch={watch}
                                    xl={6}
                                    md={6}
                                    errors={errors}
                                    register={register}
                                    req='DNI del Solicitante'

                                />
                                <InputPattern
                                    id="DireccionResidenciaSolicitante"
                                    name="DireccionResidenciaSolicitante"
                                    label="Dirección de Residencia"
                                    placeholder=""
                                    xl={6}
                                    md={12}
                                    errors={errors}
                                    register={register}
                                    req='Falta Dirección de Residencia'
                                />

                                <div className="col-xl-6 col-md-6">
                                    <Province
                                        setValue={setValue}
                                        errors={errors}
                                        register={register}
                                        watchProvinceCode={watchProvinceCode}
                                        watchProvinceDescription={watchProvinceDescription}
                                        idCode="provinceSolicitante"
                                        idDescription="province_descriptionSolicitante"
                                        required='Falta Provincia'
                                    />
                                </div>
                                <div className="col-xl-6 col-md-6">
                                    <TestCommunity
                                        errors={errors}
                                        register={register}
                                        setValue={setValue}
                                        idCode="communityCodeSolicitante"
                                        idDescription="communityDescriptionSolicitante"
                                        filterByProvince={true}
                                        watchProvinceCode={watchProvinceCode}
                                        watchCommunityCode={watchCommunityCode}
                                        watchCommunityDescription={watchCommunityDescription}
                                        required='Falta el Municipio'
                                    />
                                </div>
                                <InputPattern
                                    id="TelefonoSolicitante"
                                    name="TelefonoSolicitante"
                                    label="Telefono"
                                    placeholder="666999666"
                                    maxLength={9}
                                    pattern={/^\d+$/}
                                    patternMessage='Solo se aceptan numeros'
                                    xl={6}
                                    md={12}
                                    errors={errors}
                                    register={register}

                                />
                                <InputPattern
                                    id="MovilSolicitante"
                                    name="MovilSolicitante"
                                    label="Movil"
                                    placeholder=""
                                    maxLength={9}
                                    pattern={/^\d+$/}
                                    patternMessage='Solo se aceptan numeros'
                                    xl={6}
                                    md={12}
                                    errors={errors}
                                    register={register}

                                />


                            </FieldsetContainer>
                        </div>
                    </div>

                    <div className="row">
                        <div className="col-xl-12">
                            <FieldsetContainer legend="Datos de Abono" disabled={false}>
        
                            <InputPattern
                                    id="SucursalBancaria"
                                    name="SucursalBancaria"
                                    label="Sucursal Bancaria"
                                    placeholder=""
                                   
                                    xl={6}
                                    md={12}
                                    errors={errors}
                                    register={register}
                                    req='Falta nombre de la sucursal'

                                />
                            <InputIBAN
                                id='IBAN'
                                name='IBAN'
                                label='IBAN'
                                placeholder=''
                                xl={6}
                                md={12}
                                errors={errors}
                                register={register}
                                
                                req='Falta IBAN'
                              
                            />
                                </FieldsetContainer>
                                </div>
                            </div>

                    <button className='btn-box btn-accent mt-3 mb-3' type="submit" onClick={handleSubmit(onSubmit)}>
                        Enviar
                    </button>
                    <div className='paragraph font-weight-bold text-info  mt-2'>Solo se abonarán gastos que estén debidamente justificados y autorizados por 
                    Solimat Mutua Colaboradora con la Seguridad Social nº 72.</div>
                        <br></br>
                        <br></br>
                    <div className='paragraph small text-secondary  mb-4'>En cumplimiento de lo establecido en la normativa sobre de Protección de Datos de Carácter Personal, Solimat Mutua Colaboradora con la Seguridad Social nº 72 le informa que sus datos de 
carácter personal serán objeto de tratamiento, con la finalidad del mantenimiento y cumplimiento de la relación. La base jurídica que legitima el tratamiento de sus datos es el 
cumplimiento de una obligación legal. Sus datos serán conservados de forma indefinida en nuestra entidad para atender a las posibles responsabilidades derivadas del tratamiento. Usted 
podrá revocar el consentimiento prestado, así como ejercitar sus derechos de acceso, rectificación, supresión, oposición, limitación del tratamiento, o portabilidad, en su caso, 
remitiendo un escrito a la siguiente dirección: Solimat, Mutua Colaboradora con la Seguridad Social nº 72, C/ Berna, 1, 45003, Toledo, adjuntando su NIF. Si considera que sus 
derechos no han sido debidamente atendidos, puede contactar con nuestro delegado de protección de datos lopd@solimat.com o interponer una reclamación frente a la Agencia 
Española de Protección de Datos.</div>

                </div>


            </FormProvider>

            

        </>
    )
}

export default SolicitudGastosContingenciaProfesional;
