import FieldsetContainer from 'components/blocks/Delta/Inputs/Containers/FieldsetContainer';
import React, { useState } from 'react'
import { useForm, useFieldArray, Controller } from "react-hook-form";
import InputPattern from '../Inputs/InputPattern';
import InputHora from '../Inputs/InputHora';
import InputChkBox from '../Inputs/InputChkBox';
import InputNIE from '../Inputs/InputNIE';
import InputDate from 'components/blocks/Delta/Inputs/InputDate';

interface IInputProps {
    control: any;
    register: any;
    name: string;
    errors: any;
    disabled?: boolean;
    watch: any;
    setValue: any;
}

//Este componente es el que se encarga de renderizar el array de trabajadores relacionados con la cantidad de ellos en 
//la Declaración empresarial sobre descripción y exposición a riesgos durante el embarazo o la lactancia natural
//no es muy reutilable en principio pero sirve de base para otros arrayfields


export const ArrayFieldHijosACargo: React.FC<IInputProps> = ({ control, name, register, errors, disabled, watch, setValue }) => {


    const { fields, append, remove } = useFieldArray({
        control,
        name: name
    });



    return (
        <>

            {fields.map((item, index) => (
                <div className="container form--simple-styled" id={`${name}.${index}`}>
                    <div className="row">
                        <div className="col-xl-12">
                            <FieldsetContainer legend="Datos del Hijo a Cargo" disabled={disabled}>



                                <InputPattern
                                    id={`${name}.${index}.HijoNombre`}
                                    name={`${name}.${index}.HijoNombre`}
                                    label="Nombres y Apellidos"
                                    placeholder=""
                                    req='Debe añadir el Nombre del Hijo'
                                    xl={6}
                                    md={6}
                                    errors={errors}
                                    register={register}


                                />
                                <InputNIE
                                    id={`${name}.${index}.HijoDNI`}
                                    name={`${name}.${index}.HijoDNI`}
                                    label="NIE/NIF/DNI/Pasaporte"
                                    placeholder=""
                                    req='Falta rellenar el Documento de Identificación'
                                    xl={6}
                                    md={6}
                                    errors={errors}
                                    register={register}
                                    maxLength={20}
                                    watch={watch}

                                />
                                <div className="col-xl-6 col-md-6">
                                    <InputDate
                                        id={`${name}.${index}.HijoFechaNacimiento`}
                                        name={`${name}.${index}.HijoFechaNacimiento`}
                                        label="Fecha de Nacimiento"
                                        maxDate={new Date(Date.now())}
                                        placeholder=""
                                        errors={errors}
                                        register={register}
                                        setValue={setValue}
                                    />
                                </div>
                                <InputPattern
                                    id={`${name}.${index}.HijoEstadoCivil`}
                                    name={`${name}.${index}.HijoEstadoCivil`}
                                    label="Estado Civil"
                                    placeholder=""

                                    xl={6}
                                    md={6}
                                    errors={errors}
                                    register={register}


                                />
                                <InputChkBox
                                    id={`${name}.${index}.HijoIncapacitado`}
                                    name={`${name}.${index}.HijoIncapacitado`}
                                    label="Marque si su hijo está Incapacitado"
                                    placeholder=""
                                    col={12}
                                    xl={6}
                                    md={6}
                                    errors={errors}
                                    register={register}

                                />
                                <InputChkBox
                                    id={`${name}.${index}.HijoTrabajando`}
                                    name={`${name}.${index}.HijoTrabajando`}
                                    label="Marque si su hijo es Trabajador"
                                    placeholder=""
                                    col={12}
                                    xl={6}
                                    md={6}
                                    errors={errors}
                                    register={register}

                                />
                                <InputPattern
                                    id={`${name}.${index}.HijoRentasAnnoAnterior`}
                                    name={`${name}.${index}.HijoRentasAnnoAnterior`}
                                    label="Rentas año anterior"
                                    placeholder=""
                                    pattern={/^[0-9]+$/}
                                    patternMessage="Solo se aceptan valores numericos"
                                    xl={6}
                                    md={6}
                                    errors={errors}
                                    register={register}


                                />
                                 <InputPattern
                                    id={`${name}.${index}.HijoRentasAnnoCurso`}
                                    name={`${name}.${index}.HijoRentasAnnoCurso`}
                                    label="Rentas año anterior"
                                    placeholder=""
                                    pattern={/^[0-9]+$/}
                                    patternMessage="Solo se aceptan valores numericos"
                                    xl={6}
                                    md={6}
                                    errors={errors}
                                    register={register}


                                />
                                <button id={`btnDelete${index}`} type="button" onClick={() => remove(index)} className=' btn-box btn-secondary   '>
                                    Borrar Hijo a Cargo
                                </button>
                            </FieldsetContainer>



                        </div>
                    </div>
                </div>
            ))}
            <button
                className="mt-2 btn-box btn-accent"
                type="button"
                disabled={disabled}
                onClick={() => append({})}
            >Añadir Hijo a Cargo</button>
        </>
    )
}

export default ArrayFieldHijosACargo;
