import React from 'react';
import { FieldValues, RefCallBack, UseFormRegister, useForm, useFormContext } from 'react-hook-form';

interface IInputProps {
  name: string,
  id: string,
  label: string,
  placeholder: string,
  errors: any,
  register: any,
  readonly?: boolean,
  defaultValue?: string
  disabled?: boolean
  maxLength?: number,
  handleOnChange?: (e: React.ChangeEvent<HTMLInputElement>) => void;
  value?: any,
  isvalidateNumber?: boolean,
  isvalidateNumberPostalCode?: boolean,
  req?: string
  pattern?: RegExp,
  patternMessage?: string
  xl?: number
  md?: number
}

const InputPattern: React.FC<IInputProps> = ({ name, id, label, placeholder, errors, register, readonly, defaultValue, disabled, maxLength, req, pattern, patternMessage, xl, md }) => {


  return (
    <div className={`col-xl-${xl} col-md-${md}`}>


      <div className={`input-block ${errors && errors[name]?.message ? 'error' : ''}`}>
        <label htmlFor={id} className="input-block__label">{label}</label>
        <input
          type="text"
          name={name}
          id={id}
          placeholder={placeholder}
          {...register(name, { required: req, pattern: { value: pattern, message: patternMessage } })}
          readOnly={readonly || false}
          disabled={disabled || false}
          defaultValue={defaultValue || ''}
          maxLength={maxLength}


        />

        {errors && errors ?
          <p className="error m-0 ">
            {errors && errors[name]?.message}
          </p>
          : ''}
        {errors?.[name.split('.')[0]] && errors?.[name.split('.')[0]][name.split('.')[1]]?.[name.split('.')[2]]?.message ? //procesamiento de error cuando se usa dentro de un ArrayField
          <>
            <p className="error m-0 ">
              {errors?.[name.split('.')[0]][name.split('.')[1]]?.[name.split('.')[2]]?.message}
            </p>
          </>

          : ''}

      </div>
    </div>
  )
}

export default InputPattern;

// USE EXAMPLE
{/* <InputText 
  id="personalDataName"
  name="personalDataName"
  label="Nombre"
  placeholder="Nombre"
  errors={errors}
  register={register}
  req = 'MENSAJE DE ERROR CUANDO NO HAYA VALOR'
/> */}