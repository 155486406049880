import React from 'react';
import { FieldValues, RefCallBack, UseFormRegister, useForm, useFormContext } from 'react-hook-form';

interface IInputProps {
  errors: any;
  name: string;
  register: any;
  setValue?: any;
  label: string;
  chkname1: string;
  chkname2: string;
  chklabel1: string;
  chklabel2: string;
  nombreGrupo: string;
  req?: string
}

export const RadioButtonGroup: React.FC<IInputProps> = ({ errors, register, setValue, label, chkname1, chkname2, chklabel1, chklabel2, nombreGrupo, req }) => {
  return (
    <>
      <fieldset className={`input-block input-block--fieldset ${errors && errors[nombreGrupo]?.message ? 'error' : ''}`}>
      <legend className="input-block--fieldset__legend">{label}</legend>
      <div className="input-block--radio-check__group d-flex">
        <label htmlFor={chkname1} className="input-block--radio-check__label">
          <span className="text">{chklabel1}</span>
          <input
            type="radio"
            name={nombreGrupo}
            id={chkname1}
            value={chkname1}
            
            
            {...register(nombreGrupo, { required: req })}
           
          />
        </label>
        <label htmlFor={chkname2} className="input-block--radio-check__label">
          <span className="text">{chklabel2}</span>
          <input
            type="radio"
            name={nombreGrupo}
            id={chkname2}
            value={chkname2}
            
            {...register(nombreGrupo)}
          />
        </label>
      </div>
      {errors && errors ?
                <p className="error m-0 ">
                    {errors && errors[nombreGrupo]?.message}
                </p>
                : ''}
    </fieldset>
    
    </>
  );
};

export default RadioButtonGroup;