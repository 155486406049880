import FieldsetContainer from 'components/blocks/Delta/Inputs/Containers/FieldsetContainer';
import React, { useState } from 'react'
import { useForm, useFieldArray, Controller } from "react-hook-form";
import InputPattern from '../Inputs/InputPattern';
import InputHora from '../Inputs/InputHora';
import InputChkBox from '../Inputs/InputChkBox';

interface IInputProps {
    control: any;
    register: any;
    name: string;
    errors: any;
    disabled?: boolean;
}

//Este componente es el que se encarga de renderizar el array de trabajadores relacionados con la cantidad de ellos en 
//la Declaración empresarial sobre descripción y exposición a riesgos durante el embarazo o la lactancia natural
//no es muy reutilable en principio pero sirve de base para otros arrayfields

export const ArrayFieldPuestosTrabajoRel: React.FC<IInputProps> = ({ control, name, register, errors, disabled }) => {

  
    const { fields, append, remove } = useFieldArray({
        control,
        name: name
    });



    return (
        <>

            {fields.map((item, index) => (
                <div className="container form--simple-styled" id={`${name}.${index}`}>
                    <div className="row">
                        <div className="col-xl-12">
                            <FieldsetContainer legend="Especifique puesto y número de trabajadores en el puesto" disabled={disabled}>



                            <InputPattern
                                                id={`${name}.${index}.Puesto`}
                                                name={`${name}.${index}.Puesto`}
                                                label="Puesto"
                                                placeholder=""

                                                xl={6}
                                                md={6}
                                                errors={errors}
                                                register={register}
                                                req='Especifique el nombre del puesto'

                                            />
                            <InputPattern
                                                id={`${name}.${index}.CantidadTrabajadores`}
                                                name={`${name}.${index}.CantidadTrabajadores`}
                                                label="Nº de Trabajadores"
                                                placeholder=""
                                                pattern={/^[0-9]+$/}
                                                patternMessage='Solo se permiten valores numericos'
                                                xl={6}
                                                md={6}
                                                errors={errors}
                                                register={register}
                                                req='Especifique la cantidad de trabajadores en el puesto'

                                            />

                                <button id={`btnDelete${index}`}  type="button" onClick={() => remove(index)} className=' btn-box btn-secondary   '>
                                    Borrar Puesto
                                </button>
                            </FieldsetContainer>



                        </div>
                    </div>
                </div>
            ))}
            <button
                className="mt-2 btn-box btn-accent"
                type="button"
                disabled={disabled}
                onClick={() => append({})}
            >Añadir Puesto</button>
        </>
    )
}

export default ArrayFieldPuestosTrabajoRel;
