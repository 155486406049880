import React, { useState, useContext, useEffect } from "react";
import { Link, withRouter } from "react-router-dom";
import { HeaderContext } from "../../../contexts/HeaderContext";
import { PromptContext } from "../../../contexts/SideMenuUserContext";
import IconSettings from "../../../img/icons/svg/ajustes-mini-icon.svg";
import IconCommunications from "../../../img/icons/svg/hamburger/comentarios-small-blanco.svg";
import IconLogout from "../../../img/icons/svg/cerrar-sesion-mini-icon.svg";
import IconAyuda from "../../../img/icons/ayuda/ayuda.png"
import iconAdvertencia from '../../../img/icons/ayuda/advertencia2.png'
import IconUserPatientWhite from "../../../img/icons/svg/perfil-seleccion-icon-01_white.svg";
import IconUserCompanyWhite from "../../../img/icons/svg/perfil-seleccion-icon-02_white.svg";
import IconUserGestorWhite from "../../../img/icons/svg/perfil-seleccion-icon-03_white.svg";
// import IconSurveys from "../../../img/icons/svg/hamburger/survey-icon-white.svg";
import IconUserCircle from "../../../img/icons/svg/hamburger/user-blue-big.svg";
import useDecode from "../../../hooks/useDecode";
import { SignalRContext } from "../../../contexts/SignalRContext";
import { useMsal } from "@azure/msal-react/dist";
import useLayout from "../../../hooks/useLayout";
import "./SideMenu.scss";
import { createBrowserHistory } from "history";
import { ROLES } from "hooks/useGetRole";
import Moment from "moment";

interface Imembership {
  id: string;
  name: string;
  description: string;
  user_id: string;
  account_id: string;
  company_id: string;
  role: number;
}

const SideMenuUser: React.FC = (props: any) => {
  const { handleAbortSignalR } = useContext(SignalRContext);
  const { headerState, setHeaderState } = useContext(HeaderContext);
  // const { promptState, setPromptState } = useContext(PromptContext);  // TO DELETE IF NOT USE FORM WHEN SWITCH
  const { roleSelected } = useContext(PromptContext);
  const [rolePermisionsType, setRolePermisionsType] = useState(null);
  const history = createBrowserHistory();
  const [allMembership, setAllMembership] = useState<any[]>([]);
  const [userToken, setUserToken] = useState<any>({});
  const { instance } = useMsal();
  const { rolePermision } = useLayout();
  const [lastLogin, setLastLogin] = useState('');
const [interactionInProgress, setInteractionInProgress] = useState(false)
const [loggingOut, setLoggingOut] = useState(false);

  // const {
  //   collapsed,
  //   childrenProps,
  //   handleOpenModal,
  //   handleCloseModal,
  //   disabledCloseButton,
  //   handleDisableCloseButton
  // } = useModal();

  const tokenResponse = useDecode("token");
  useEffect(() => {
    // CHECK TOKEN
    if (tokenResponse !== undefined) {
      setUserToken(tokenResponse);
      saveLastLogin(tokenResponse);
    } else {
      // props.history.push("/"); ojo esta comentado
    }

    // let roleSelected = localStorage.getItem("role");
    let LSallMembership = localStorage.getItem("allMembership");
    // let roleSelectedId = "";
    if (typeof rolePermision === "string") {
      // let roleJson = JSON.parse(roleSelected);
      // roleSelectedId = roleJson.id;
      // let rolePermisionType = roleJson.role;
      // setRoleSelected(roleJson);
      setRolePermisionsType(rolePermision);
    }
    if (typeof LSallMembership === "string") {
      let allMembershipParse = JSON.parse(LSallMembership);
      setAllMembership(allMembershipParse);
    }
  }, [headerState]);

  const handleOnSwithMembership = (item: Imembership) => {
    setHeaderState({ ...headerState, identity: userToken.extension_id });
    // handleOpenModal(); // TO DELETE IF NOT USE FORM WHEN SWITCH
    // setPromptState(item);   // TO DELETE IF NOT USE FORM WHEN SWITCH
    redirection(item, item.role);
  };

  const redirection = (data: any, role: number) => {
    const path = checkPath(role);
    const membershipStr = JSON.stringify(data);

    if (path?.includes("http")) {
      // window.location.href = path + '/inicio-registro/' + userToken.identity + '/' + membershipStr;
      window.location.href =
        path +
        "/inicio-registro/" +
        userToken.extension_id +
        "/" +
        membershipStr;
    } else {
      props.history.replace({
        pathname: path,
        data: data,
      });
    }
  };

  const checkPath = (permission: number) => {
    switch (permission) {
      case 1: //Colaborator
      case 2: //Company
      case 4: //SuperCollaborator
      case 5: //SuperCompany
      case 6: //Freelance
        const companiesPath = process.env.REACT_APP_COMPANIESSPA_HOSTNAME;
        return companiesPath;
      case 3:
        return "/inicio";
      default:
   
        break;
    }
  };

  const closeSideMenu = () => {
    setHeaderState({ sideMenuVisible: false });
  };
  // const handleLogout = async () => {
  //   try {
    
  //     if (loggingOut || interactionInProgress) {
  //       return;
  //     }
  //     setLoggingOut(true);
  //     setInteractionInProgress(true); 
  //     await instance.handleRedirectPromise();
  //   } catch (error) {
  //     console.error("Error handling redirect:", error);
  //   } finally {
  //     setInteractionInProgress(false); 
  //   }
  //   try {
    
  //     await instance.logoutRedirect({
  //       postLogoutRedirectUri: "/inicio" 
  //     });
  //   } catch (error) {
  //     console.error("Logout error:", error);
  //   } finally {
  //     setLoggingOut(false);
  //   }
  // };
  const handleLogout = async () => {
    try {
      if (loggingOut || interactionInProgress) {
        return;
      }
  
      setLoggingOut(true);
      setInteractionInProgress(true);
      await instance.handleRedirectPromise();
      
    } catch (error) {
      console.error("Error handling redirect:", error);
    } finally {
      setInteractionInProgress(false);
    }
  
    try {
      // Este será el último paso que cierra sesión y redirige a /inicio
      await instance.logoutRedirect({
        postLogoutRedirectUri: "/inicio",  // Redirige a /inicio después del cierre de sesión
      });
    } catch (error) {
      console.error("Logout error:", error);
    } finally {
      setLoggingOut(false);
    }
  };
  
  
  
  const saveLastLogin = (tokenResponse: any) => {
    if (tokenResponse && tokenResponse.extension_last_signin) {
      const unixTimeInSeconds = Number(tokenResponse.extension_last_signin);
      const dateFromUnix = Moment.unix(unixTimeInSeconds);
      if (dateFromUnix.isValid()) {
        const formatted = dateFromUnix.format("DD/MM/YY HH:mm:ss");
        setLastLogin(formatted);
      }
    }
  }
  return (
    <>
      {(allMembership.length >= 1 || true) && (
        <div
          className={`side-menu-wrapper side-menu ${headerState.sideMenuVisible ? "" : "collapsed"} 
                    ${rolePermisionsType !== ROLES.PACIENTE ? "company" : ""}`}
        >
          <div className="bg" onClick={closeSideMenu}></div>
          <div className="side-menu_block">
            <div className="btn-close btn-close-white" onClick={closeSideMenu}>
              {/* <button onClick={closeSideMenu}>
                <img src={IconCloseNav} alt="" />
              </button> */}
            </div>
            <div className="user-info">
              <div className="icon-container">
                <img src={IconUserCircle} alt="" />
              </div>
              <div className="user-info-text">
                <h2 className="user-name">
                  {userToken.extension_first_name
                    ? userToken.extension_first_name
                    : "-"}{" "}
                  {userToken.extension_first_last_name
                    ? userToken.extension_first_last_name
                    : "-"}
                </h2>
                {/* Nombre tipo usuario */}
                <p className="user-type">{roleSelected.name}</p>
              </div>
            </div>
            <div className="nav-list-container">
              <nav>
                {/* {allMembership.length > 1 &&
                  allMembership
                    .filter(
                      (item: any) =>
                        item.id !== roleSelected.id &&
                        item.role !== 3 &&
                        item.role !== 1 &&
                        item.role !== 4 &&
                        item.role !== 0
                    )
                    .map((item: any, index: number) => {
                      return (
                        <div
                          onClick={() => handleOnSwithMembership(item)}
                          key={index}
                        >
                          <img
                            src={
                              item.role === 2
                                ? IconUserGestorWhite
                                : IconUserCompanyWhite
                            }
                            alt=""
                          />
                          <span className="link-text">{item.name}</span>
                        </div>
                      );
                    })}
                {allMembership.length > 1 &&
                  allMembership
                    .filter(
                      (item: any) =>
                        item.id !== roleSelected.id &&
                        item.role !== 3 &&
                        item.role !== 2 &&
                        item.role !== 5 &&
                        item.role !== 0
                    )
                    .map((item: any, index: number) => {
                      return (
                        <div
                          onClick={() => handleOnSwithMembership(item)}
                          key={index}
                        >
                          <img
                            src={
                              item.role === 0
                                ? IconUserGestorWhite
                                : IconUserCompanyWhite
                            }
                            alt=""
                          />
                          <span className="link-text">{item.name}</span>
                        </div>
                      );
                    })}
                {allMembership.length > 1 &&
                  roleSelected.role !== 3 &&
                  allMembership.filter(
                    (item: any) => item.role === 3 || item.role === 6
                  ).length >= 1 && (
                    <div
                      onClick={() =>
                        handleOnSwithMembership({
                          id: "",
                          name: "Paciente",
                          description: "",
                          user_id: `${allMembership[0].user_id}`,
                          account_id: "",
                          company_id: "",
                          role: 3,
                        })
                      }
                    >
                      <img src={IconUserPatientWhite} alt="" />
                      <span className="link-text">Paciente</span>
                    </div>
                  )} */}
                <Link to="/ajustes" onClick={closeSideMenu}>
                  <img src={IconSettings} alt="" />
                  <span className="link-text">Ajustes de usuario</span>
                </Link>
                <Link to="/comunicaciones" onClick={closeSideMenu}>
                  <img src={IconCommunications} alt="" />
                  <span className="link-text">Comunicaciones</span>
                </Link>
                <Link to="/ayuda" onClick={closeSideMenu}>
                  <img src={IconAyuda} alt="" />
                  <span className="link-text">Ayuda</span>
                </Link>
                <Link to="/avisos" onClick={closeSideMenu}>
                  <img src={iconAdvertencia} alt="" />
                  <span className="link-text">Avisos</span>
                </Link>
                {/* <Link to="/mis-encuestas" onClick={closeSideMenu}>
                  <img src={IconSurveys} alt="" />
                  <span className="link-text">Encuestas</span>
                </Link> */}
                <a onClick={handleLogout}>
                  <img src={IconLogout} alt="" />
                  <span className="link-text">Cerrar sesión</span>
                </a>

                {lastLogin && <div><span>Última conexión: {lastLogin}</span></div>}
              </nav>
            </div>
          </div>
        </div>
      )}
      {/* //TO DELETE IF NOT USE FORM WHEN SWITCH */}
      {/* <Modal
        disabledCloseButton={disabledCloseButton}
        collapsed={collapsed}
        onClose={handleCloseModal}
        title="Introduzca su contraseña"
        subtitle="Para cambiar de usuario, necesitamos verificar su contraseña"
      >
        {collapsed ? (
          <div className="ghost-form"></div>
        ) : (
          <FormPasswordUserNav
            handleDisableCloseButton={handleDisableCloseButton}
            onClose={handleCloseModal}
            props={props}
            userToken={userToken}
          />
        )}
      </Modal> */}
    </>
  );
};

export default withRouter(SideMenuUser);
