import React from 'react';
import { FieldValues, RefCallBack, UseFormRegister, useForm, useFormContext } from 'react-hook-form';

interface IInputProps {
    name: string,
    id: string,
    errors: any,
    register: any,
    required?: string
    opciones: string[]
    labels: string[]
    label: string
}

const InputMultiRadio: React.FC<IInputProps> = ({ name, id, errors, register, required, opciones, label, labels }) => {


    return (
        <>
            <label className="input-block__label">{label}</label>
            <div className="input-block--radio-check__group d-flex flex-wrap flex-column align-items-start">


                {opciones.map((opcion, index) => (
                    <label key={index} htmlFor={`${id}_${index}`} className="input-block--radio-check__label ml-4">
                        <span className="text">{labels[index]}</span>
                        <input
                            type="radio"
                            name={name}
                            id={`${id}_${index}`}
                            value={opcion}
                            {...register(name, { required: required })}
                        />
                    </label>
                ))}


                {errors && errors ?
                    <p className="error m-0 ml-4">
                        {errors && errors[name]?.message}
                    </p>
                    : ''}
                {errors?.[name.split('.')[0]] && errors?.[name.split('.')[0]][name.split('.')[1]]?.[name.split('.')[2]]?.message ? //procesamiento de error cuando se usa dentro de un ArrayField
                    <>
                        <p className="error m-0 ">
                            {errors?.[name.split('.')[0]][name.split('.')[1]]?.[name.split('.')[2]]?.message}
                        </p>
                    </>

                    : ''}
            </div>
        </>

    )
}

export default InputMultiRadio;

// USE EXAMPLE
{/* <InputText 
  id="personalDataName"
  name="personalDataName"
  label="Nombre"
  placeholder="Nombre"
  errors={errors}
  register={register}
  req = 'MENSAJE DE ERROR CUANDO NO HAYA VALOR'
/> */}