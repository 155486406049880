import React, { useEffect, useState, createContext, useContext } from 'react'
import { useLocation, Link } from 'react-router-dom';
import PageMenu from 'components/blocks/PageMenu/PageMenu';
import IconArrowGray from "../../../img/icons/svg/botones/flecha-big-gris.svg";
import FieldsetContainer from 'components/blocks/Delta/Inputs/Containers/FieldsetContainer';
import { FormProvider, useForm } from 'react-hook-form';
import Loader from 'components/blocks/Loader/Loader';
import InputNIE from 'components/blocks/ManagementNewForms/Inputs/InputNIE';
import InputPattern from 'components/blocks/ManagementNewForms/Inputs/InputPattern';
import InputDate from 'components/blocks/Delta/Inputs/InputDate';
import InputChkBox from 'components/blocks/ManagementNewForms/Inputs/InputChkBox';
import TextAreaDefault from 'components/blocks/ManagementNewForms/TextArea/TextAreaDefault';
import Province from 'components/blocks/Delta/Inputs/Province';
import TestCommunity from 'components/blocks/Delta/Inputs/TestCommunity';
import AccordionForm from 'components/blocks/ManagementNewForms/Accordion/AccordionForm';

export const SolicitudInformacionEnfermedadesProfesionales: React.FC = () => {

    const location = useLocation();
    const title = "Solicitud de Información de Enfermedades Profesionales"
    const breadcrumbs = ["Solicitud de Información de Enfermedades Profesionales"];
    const formMethods = useForm();
    const { register, handleSubmit, setError, formState: { errors }, setValue, watch } = formMethods;

    const watchProvinceCode3 = watch("provinceCentroTrabajo", 0);
    const watchProvinceDescription3 = watch("province_descriptionCentroTrabajo");
    const watchCommunityCode3 = watch("communityCodeCentroTrabajo");
    const watchCommunityDescription3 = watch("communityDescriptionCentroTrabajo");

    const [disableETT, setDisableETT] = useState(false);
    const onSubmit = async (data: any) => {

        console.log(data);
    };


    return (
        <>
            <PageMenu title={title} breadcrumbs={breadcrumbs}>
                <div className="btns-header-container">
                    <Link to={`/inicio`}>
                        <img src={IconArrowGray} alt="icon arrow right" />
                    </Link>
                </div>
            </PageMenu>
            <FormProvider {...formMethods}>
                <div className="container form--simple-styled" id="">
                    <div className='paragraph small text-secondary  mt-4'>De acuerdo con el R.D. 1299/2006, de 10 de noviembre, por el que se aprueba el cuadro de enfermedades profesionales, desarrollado por la Orden TAS
                        1/2007, de 2 de enero, esta Mutua notificará a la Dirección General de Ordenación del Ministerio de Trabajo e Inmigración el parte de enfermedad Profesional, teniendo la emrpresa el deber de enviar en el plazo de 48 horas a partir de la recepción de este documento, la información siguiente al fax:
                    </div>
                    <div className="row">
                        <div className="col-xl-12">
                            <FieldsetContainer legend="Datos trabajador/a" disabled={false}>
                                <InputPattern
                                    id="NombreTrabajador"
                                    name="NombreTrabajador"
                                    label="Nombre del Trabajador"
                                    placeholder=""

                                    xl={6}
                                    md={6}
                                    errors={errors}
                                    register={register}
                                    req='Falta Nombre'

                                />
                                <InputPattern
                                    id="Apellido1Trabajador"
                                    name="Apellido1Trabajador"
                                    label="Primer Apellido"
                                    placeholder=""

                                    xl={6}
                                    md={6}
                                    errors={errors}
                                    register={register}
                                    req='Falta primer apellido'

                                />
                                <InputPattern
                                    id="Apellido2Trabajador"
                                    name="Apellido2Trabajador"
                                    label="Segundo Apellido"
                                    placeholder=""

                                    xl={6}
                                    md={6}
                                    errors={errors}
                                    register={register}
                                    req='Falta segundo apellido'

                                />
                                <InputNIE
                                    id="TrabajadorNIE"
                                    name="TrabajadorNIE"
                                    label="NIE/NIF/DNI/Pasaporte"
                                    placeholder=""
                                    req='Falta rellenar el Documento de Identificación'
                                    xl={6}
                                    md={6}
                                    errors={errors}
                                    register={register}
                                    maxLength={20}
                                    watch={watch}
                                />
                                <InputPattern
                                    id="CausaBajaTrabajador"
                                    name="CausaBajaTrabajador"
                                    label="Causa Baja"
                                    placeholder=""

                                    xl={6}
                                    md={12}
                                    errors={errors}
                                    register={register}
                                    req='Falta causa de baja'

                                />

                                <div className="pt-3 col-xl-6 col-md-12">
                                    <InputDate
                                        id="accident_accidentDate"
                                        name="accident_accidentDate"
                                        label="Fecha del accidente"
                                        placeholder="Fecha del accidente"
                                        errors={errors}
                                        register={register}
                                        setValue={setValue}

                                    />
                                </div>
                                <InputPattern
                                    id="BaseCotizacionMesAnteriorTrabajador"
                                    name="BaseCotizacionMesAnteriorTrabajador"
                                    label="Base cotización mensual en el mes anterior"
                                    placeholder=""

                                    xl={4}
                                    md={4}
                                    errors={errors}
                                    register={register}


                                />
                                <InputPattern
                                    id="DiasCotizadosTrabajador"
                                    name="DiasCotizadosTrabajador"
                                    label="Días Cotizados"
                                    placeholder=""

                                    xl={4}
                                    md={4}
                                    errors={errors}
                                    register={register}


                                />
                                <InputPattern
                                    id="TelefonoTrabajador"
                                    name="TelefonoTrabajador"
                                    label="Teléfono"
                                    placeholder=""
                                    pattern={/^[0-9]+$/}
                                    xl={4}
                                    md={4}
                                    errors={errors}
                                    register={register}
                                    req='Falta Teléfono'
                                    maxLength={9}
                                />
                                <InputPattern
                                    id="BaseCotizacionAnualHorasExtraTrabajador"
                                    name="BaseCotizacionAnualHorasExtraTrabajador"
                                    label="Base de cotización anual por horas extras"
                                    placeholder=""
                                    pattern={/^[0-9]+$/}
                                    xl={6}
                                    md={6}
                                    errors={errors}
                                    register={register}

                                />
                                <InputPattern
                                    id="BaseCotizacionAnualOtrosTrabajador"
                                    name="BaseCotizacionAnualOtrosTrabajador"
                                    label="Base de cotización anual por otros conceptos"
                                    placeholder=""
                                    pattern={/^[0-9]+$/}
                                    xl={6}
                                    md={6}
                                    errors={errors}
                                    register={register}
                                />
                            </FieldsetContainer>
                        </div>



                    </div>

                    <div className="row">
                        <div className="col-xl-12">
                            <FieldsetContainer legend="Datos de la Empresa" disabled={false}>
                                <InputPattern
                                    id="NombreEmpresa"
                                    name="NombreEmpresa"
                                    label="Nombre de la Empresa"
                                    placeholder=""

                                    xl={6}
                                    md={6}
                                    errors={errors}
                                    register={register}
                                    req='Falta Nombre de la Empresa'

                                />
                                <InputPattern
                                    id="CCC"
                                    name="CCC"
                                    label="Codigo Cuenta Cotización"
                                    placeholder="CCC"
                                    errors={errors}
                                    xl={6}
                                    md={6}

                                    register={register}
                                    req='Falta Código Cuenta Cotización'
                                    pattern={/^[0-9]+$/}
                                    patternMessage='Solo se aceptan valores numericos'
                                />
                                <InputPattern
                                    id="PersonaContactoEmpresa"
                                    name="PersonaContactoEmpresa"
                                    label="Persona de Contacto"
                                    placeholder=""

                                    xl={6}
                                    md={6}
                                    errors={errors}
                                    register={register}
                                    req=''

                                />
                                <InputPattern
                                    id="TelefonoEmpresa"
                                    name="TelefonoEmpresa"
                                    label="Teléfono de Contacto"
                                    placeholder=""
                                    errors={errors}
                                    register={register}
                                    maxLength={9}
                                    xl={6}
                                    md={6}
                                    pattern={/^[0-9]{9}$/i}
                                    patternMessage='No es un numero de telefono valido'
                                />
                                <div className="row">
                                    <div className="col-xl-12 mb-3">
                                        <FieldsetContainer legend="Modalidad de organización preventiva adoptada por la empresa" disabled={false}>
                                            <InputChkBox
                                                id="OrganizacionPreventivaNinguna"
                                                name="OrganizacionPreventivaNinguna"
                                                label="Ninguna"
                                                placeholder=""
                                                col={6}
                                                xl={4}
                                                md={4}
                                                errors={errors}
                                                register={register}


                                            />
                                            <InputChkBox
                                                id="OrganizacionPreventivaPropioEmpresario"
                                                name="OrganizacionPreventivaPropioEmpresario"
                                                label="Propio Empresario"
                                                placeholder=""
                                                col={6}
                                                xl={4}
                                                md={4}
                                                errors={errors}
                                                register={register}


                                            />
                                            <InputChkBox
                                                id="OrganizacionPreventivaPrevencionPropio"
                                                name="OrganizacionPreventivaPrevencionPropio"
                                                label="Servicio Prevencion Propio"
                                                placeholder=""
                                                col={6}
                                                xl={4}
                                                md={4}
                                                errors={errors}
                                                register={register}


                                            />
                                            <InputChkBox
                                                id="OrganizacionPreventivaPrevencionAjeno"
                                                name="OrganizacionPreventivaPrevencionAjeno"
                                                label="Servicio Prevencion Ajeno"
                                                placeholder=""
                                                col={6}
                                                xl={4}
                                                md={4}
                                                errors={errors}
                                                register={register}


                                            />
                                            <InputChkBox
                                                id="OrganizacionPreventivaPrevencionMancomunado"
                                                name="OrganizacionPreventivaPrevencionMancomunado"
                                                label="Servicio Prevencion Mancomunado"
                                                placeholder=""
                                                col={6}
                                                xl={4}
                                                md={4}
                                                errors={errors}
                                                register={register}


                                            />
                                            <InputChkBox
                                                id="OrganizacionPreventivaTrabajadorDesignado"
                                                name="OrganizacionPreventivaTrabajadorDesignado"
                                                label="Trabajador Designado"
                                                placeholder=""
                                                col={6}
                                                xl={4}
                                                md={4}
                                                errors={errors}
                                                register={register}


                                            />
                                        </FieldsetContainer>
                                    </div>
                                </div>
                                <InputChkBox
                                    id="ExistenciaEvaluacionRiesgosTrabajo"
                                    name="ExistenciaEvaluacionRiesgosTrabajo"
                                    label="Existencia de evaluación de riesgos del puesto de trabajo"
                                    placeholder=""
                                    col={12}
                                    xl={6}
                                    md={6}
                                    errors={errors}
                                    register={register}


                                />
                                <InputChkBox
                                    id="ExistenciaInformacionRepresentantes"
                                    name="ExistenciaInformacionRepresentantes"
                                    label="Existencia de información a los representantes de los trabajadores en materia de prevención (art. 36.2c de la LPRL)"
                                    placeholder=""
                                    col={12}
                                    xl={6}
                                    md={6}
                                    errors={errors}
                                    register={register}


                                />
                                <InputChkBox
                                    id="ReconocimientoMedicoEfectuado"
                                    name="ReconocimientoMedicoEfectuado"
                                    label="¿Se ha efectuado reconocimiento médico? (art. 196 Ley General de la Seguridad Social)"
                                    placeholder=""
                                    col={12}
                                    xl={6}
                                    md={6}
                                    errors={errors}
                                    register={register}


                                />
                                <InputChkBox
                                    id="ExistenciaInformeRiesgoEP"
                                    name="ExistenciaInformeRiesgoEP"
                                    label="Existe informe de riesgo de EP por el empresario (art. 16.3 de la LPRL)"
                                    placeholder=""
                                    col={12}
                                    xl={6}
                                    md={6}
                                    errors={errors}
                                    register={register}


                                />
                            </FieldsetContainer>
                        </div>
                    </div>

                    <div className="row">
                        <div className="col-xl-12">
                            <FieldsetContainer legend="Datos puesto de Trabajo" disabled={false}>
                                <div className="row">
                                    <div className="col-xl-12 mb-3">
                                        <FieldsetContainer legend="Puesto de Trabajo Actual" disabled={false}>
                                            <InputPattern
                                                id="CodigoCNOPuestoActual"
                                                name="CodigoCNOPuestoActual"
                                                label="Código CNO"
                                                placeholder=""
                                                errors={errors}
                                                register={register}

                                                xl={6}
                                                md={6}

                                            />
                                            <InputPattern
                                                id="DescripcionCNOPuestoActual"
                                                name="DescripcionCNOPuestoActual"
                                                label="Descripción CNO"
                                                placeholder=""
                                                errors={errors}
                                                register={register}

                                                xl={6}
                                                md={6}

                                            />
                                            <InputPattern
                                                id="TiempoTrabajoPuestoActual"
                                                name="TiempoTrabajoPuestoActual"
                                                label="Tiempo de Trabajo (meses)"
                                                placeholder=""
                                                errors={errors}
                                                register={register}

                                                xl={6}
                                                md={6}

                                            />
                                            <InputPattern
                                                id="CodigoTrabajoPuestoActual"
                                                name="CodigoTrabajoPuestoActual"
                                                label="CodigoTrabajoPuestoActual"
                                                placeholder=""
                                                errors={errors}
                                                register={register}

                                                xl={6}
                                                md={6}

                                            />
                                            <TextAreaDefault
                                                id="DescripcionTrabajoPuestoActual"
                                                name="DescripcionTrabajoPuestoActual"
                                                label="Descripcion del Trabajo"
                                                errors={errors} register={register}
                                                resizable={false}
                                                rows={3}

                                            />
                                        </FieldsetContainer>
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col-xl-12 mb-3">
                                        <FieldsetContainer legend="Puesto trabajo anterior (si se presume como posible inicio de la enfermedad profesional)" disabled={false}>
                                            <InputPattern
                                                id="CodigoCNOPuestoAnterior"
                                                name="CodigoCNOPuestoAnterior"
                                                label="Código CNO"
                                                placeholder=""
                                                errors={errors}
                                                register={register}

                                                xl={6}
                                                md={6}

                                            />
                                            <InputPattern
                                                id="DescripcionCNOPuestoAnterior"
                                                name="DescripcionCNOPuestoAnterior"
                                                label="Descripción CNO"
                                                placeholder=""
                                                errors={errors}
                                                register={register}

                                                xl={6}
                                                md={6}

                                            />
                                            <InputPattern
                                                id="TiempoTrabajoPuestoAnterior"
                                                name="TiempoTrabajoPuestoAnterior"
                                                label="Tiempo de Trabajo (meses)"
                                                placeholder=""
                                                errors={errors}
                                                register={register}

                                                xl={6}
                                                md={6}

                                            />
                                            <InputPattern
                                                id="CodigoTrabajoPuestoAnterior"
                                                name="CodigoTrabajoPuestoAnterior"
                                                label="CodigoTrabajoPuestoAnterior"
                                                placeholder=""
                                                errors={errors}
                                                register={register}

                                                xl={6}
                                                md={6}

                                            />
                                            <TextAreaDefault
                                                id="DescripcionTrabajoPuestoAnterior"
                                                name="DescripcionTrabajoPuestoAnterior"
                                                label="Descripcion del Trabajo"
                                                errors={errors} register={register}
                                                resizable={false}
                                                rows={3}

                                            />
                                        </FieldsetContainer>

                                    </div>
                                </div>
                                <InputPattern
                                    id="CentroTrabajoDistintoAlCCC"
                                    name="CentroTrabajoDistintoAlCCC"
                                    label="Centro de trabajo donde presta servicios si es distinto al del CCC"
                                    placeholder=""
                                    errors={errors}
                                    register={register}

                                    xl={6}
                                    md={6}

                                />
                                <InputPattern
                                    id="DireccionPuestoDeTrabajo"
                                    name="DireccionPuestoDeTrabajo"
                                    label="Dirección"
                                    placeholder=""
                                    errors={errors}
                                    register={register}

                                    xl={6}
                                    md={6}

                                />
                                <InputPattern
                                    id="TelefonoPuestoDeTrabajo"
                                    name="TelefonoPuestoDeTrabajo"
                                    label="Teléfono"
                                    placeholder=""
                                    errors={errors}
                                    register={register}
                                    maxLength={9}
                                    xl={6}
                                    md={6}
                                    pattern={/^[0-9]{9}$/i}
                                    patternMessage='No es un numero de telefono valido'
                                />

                                <InputPattern
                                    id="CodigoPostalPuestoDeTrabajo"
                                    name="CodigoPostalPuestoDeTrabajo"
                                    label="Código Postal"
                                    placeholder=""
                                    errors={errors}
                                    register={register}
                                    maxLength={5}
                                    xl={6}
                                    md={6}
                                    pattern={/^[0-9]{9}$/i}
                                    patternMessage='No es un CP válido'
                                />
                                <div className="col-xl-6">
                                    <Province
                                        setValue={setValue}
                                        errors={errors}
                                        register={register}
                                        watchProvinceCode={watchProvinceCode3}
                                        watchProvinceDescription={watchProvinceDescription3}
                                        idCode="provinceCentroTrabajo"
                                        idDescription="province_descriptionCentroTrabajo"
                                    />
                                </div>
                                <div className="col-xl-6">
                                    <TestCommunity
                                        errors={errors}
                                        register={register}
                                        setValue={setValue}
                                        idCode="communityCodeCentroTrabajo"
                                        idDescription="communityDescriptionCentroTrabajo"
                                        filterByProvince={true}
                                        watchProvinceCode={watchProvinceCode3}
                                        watchCommunityCode={watchCommunityCode3}
                                        watchCommunityDescription={watchCommunityDescription3}
                                    />
                                </div>
                                <div className='mt-4'></div>
                                <InputChkBox
                                    id="TrabajadorSubcontratadoETT"
                                    name="TrabajadorSubcontratadoETT"
                                    label="¿Realiza el trabajador su actividad como subcontratado o cedido por una ETT?"
                                    placeholder=""
                                    col={12}
                                    xl={12}
                                    md={12}
                                    justify='center'
                                    errors={errors}
                                    register={register}
                                    functionOnChange={setDisableETT}

                                />
                                <div style={{ display: disableETT ? 'block' : 'none' }}>
                                    <AccordionForm title="Trabajador subcontratado o cedido por una ETT" expandedHide={false} expandedInit={false}>
                                        <div className="row ">
                                            <div className="col-xl-12 mb-3">
                                                <FieldsetContainer legend="" >

                                                    <InputPattern
                                                        id="CCCSubcontratadoETT"
                                                        name="CCCSubcontratadoETT"
                                                        label="Código cuenta cotización de la empresa donde presta servicios"
                                                        placeholder=""
                                                        errors={errors}
                                                        register={register}
                                                        pattern={/^[0-9]{9}$/i}
                                                        patternMessage='No es un CCC valido'
                                                        xl={6}
                                                        md={6}

                                                    />

                                                    <InputPattern
                                                        id="CNAESubcontratadoETT"
                                                        name="CNAESubcontratadoETT"
                                                        label="CNAE de la empresa donde presta servicios el trabajador"
                                                        placeholder=""
                                                        errors={errors}
                                                        register={register}


                                                        xl={6}
                                                        md={6}

                                                    />
                                                    <InputPattern
                                                        id="PlantillaEmpresaSubcontratadoETT"
                                                        name="PlantillaEmpresaSubcontratadoETT"
                                                        label="Plantilla actual de la empresa donde presta servicios"
                                                        placeholder=""
                                                        errors={errors}
                                                        register={register}


                                                        xl={12}
                                                        md={12}

                                                    />
                                                    <div className="row">
                                                        <div className="col-xl-12 mb-3">
                                                            <FieldsetContainer legend="Modalidad de organización preventiva adoptada por la empresa donde presta servicios el trabajador" disabled={false}>
                                                                <InputChkBox
                                                                    id="OrganizacionPreventivaNingunaSubcontratadoETT"
                                                                    name="OrganizacionPreventivaNingunaSubcontratadoETT"
                                                                    label="Ninguna"
                                                                    placeholder=""
                                                                    col={6}
                                                                    xl={4}
                                                                    md={4}
                                                                    errors={errors}
                                                                    register={register}


                                                                />
                                                                <InputChkBox
                                                                    id="OrganizacionPreventivaPropioEmpresarioSubcontratadoETT"
                                                                    name="OrganizacionPreventivaPropioEmpresarioSubcontratadoETT"
                                                                    label="Propio Empresario"
                                                                    placeholder=""
                                                                    col={6}
                                                                    xl={4}
                                                                    md={4}
                                                                    errors={errors}
                                                                    register={register}


                                                                />
                                                                <InputChkBox
                                                                    id="OrganizacionPreventivaPrevencionPropioSubcontratadoETT"
                                                                    name="OrganizacionPreventivaPrevencionPropioSubcontratadoETT"
                                                                    label="Servicio Prevencion Propio"
                                                                    placeholder=""
                                                                    col={6}
                                                                    xl={4}
                                                                    md={4}
                                                                    errors={errors}
                                                                    register={register}


                                                                />
                                                                <InputChkBox
                                                                    id="OrganizacionPreventivaPrevencionAjenoSubcontratadoETT"
                                                                    name="OrganizacionPreventivaPrevencionAjenoSubcontratadoETT"
                                                                    label="Servicio Prevencion Ajeno"
                                                                    placeholder=""
                                                                    col={6}
                                                                    xl={4}
                                                                    md={4}
                                                                    errors={errors}
                                                                    register={register}


                                                                />
                                                                <InputChkBox
                                                                    id="OrganizacionPreventivaPrevencionMancomunadoSubcontratadoETT"
                                                                    name="OrganizacionPreventivaPrevencionMancomunadoSubcontratadoETT"
                                                                    label="Servicio Prevencion Mancomunado"
                                                                    placeholder=""
                                                                    col={6}
                                                                    xl={4}
                                                                    md={4}
                                                                    errors={errors}
                                                                    register={register}


                                                                />
                                                                <InputChkBox
                                                                    id="OrganizacionPreventivaTrabajadorDesignadoSubcontratadoETT"
                                                                    name="OrganizacionPreventivaTrabajadorDesignadoSubcontratadoETT"
                                                                    label="Trabajador Designado"
                                                                    placeholder=""
                                                                    col={6}
                                                                    xl={4}
                                                                    md={4}
                                                                    errors={errors}
                                                                    register={register}


                                                                />
                                                            </FieldsetContainer>
                                                        </div>
                                                    </div>
                                                    <InputChkBox
                                                        id="ExistenciaEvaluacionRiesgosSubcontratadoETT"
                                                        name="ExistenciaEvaluacionRiesgosSubcontratadoETT"
                                                        label="Existencia de evaluación de riesgos del puesto de trabajo"
                                                        placeholder=""
                                                        col={6}
                                                        xl={6}
                                                        md={6}
                                                        errors={errors}
                                                        register={register}


                                                    />
                                                    <InputChkBox
                                                        id="ExistenciaInformacionPrevencionSubcontratadoETT"
                                                        name="ExistenciaInformacionPrevencionSubcontratadoETT"
                                                        label="Existencia de información al trabajador en materia de prevención"
                                                        placeholder=""
                                                        col={6}
                                                        xl={6}
                                                        md={6}
                                                        errors={errors}
                                                        register={register}


                                                    />
                                                </FieldsetContainer>
                                            </div>
                                        </div>
                                    </AccordionForm>
                                </div>

                            </FieldsetContainer>
                        </div>
                    </div>

                    <div className='paragraph small text-secondary  mt-4'>Esta solicitud va a ser tramitada por medios informáticos. Los datos personales que figuran en ella serán incorporados a un fichero de base de datos para el cálculo y control de la prestación
                        económica que se le reconozca, y permanecerán bajo custodia de esta Entidad. En cualquier momento puede ejercitar su derecho de acceso, rectificación, cancelación y oposición sobre los datos
                        incorporados al fichero art. 5 de la ley 15/1999 de 13 diciembre d Protección de datos de carácter personal, BOE del día 14</div>


                    <button className='btn-box btn-accent mt-3 mb-3' type="submit" onClick={handleSubmit(onSubmit)}>
                        Enviar
                    </button>

                    <div className='paragraph small text-secondary  mt-4'>Según establece el artículo 6, párrafo segundo, de la Orden TAS 1/2007, de 2 de enero, de no remitirse esta información, la Mutua tiene la obligación de poner en conocimiento de la autoridad laboral competente este 
                    incumplimiento y de tramitar el parte de enfermedad profesional.</div>

                    <div className='paragraph small text-secondary  mt-4 mb-3'>En cumplimiento de lo establecido en la normativa sobre de Protección de Datos de Carácter Personal, Solimat Mutua Colaboradora con la Seguridad Social nº 72 le informa que sus datos de carácter personal 
serán objeto de tratamiento, con la finalidad del mantenimiento y cumplimiento de la relación. La base jurídica que legitima el tratamiento de sus datos es el cumplimiento de una obligación legal. Sus datos 
serán conservados de forma indefinida en nuestra entidad para atender a las posibles responsabilidades derivadas del tratamiento. Usted podrá revocar el consentimiento prestado, así como ejercitar sus derechos de 
acceso, rectificación, supresión, oposición, limitación del tratamiento, o portabilidad, en su caso, remitiendo un escrito a la siguiente dirección: Solimat, Mutua Colaboradora con la Seguridad Social nº 
72, C/ Berna, 1, 45003, Toledo, adjuntando su NIF. Si considera que sus derechos no han sido debidamente atendidos, puede contactar con nuestro delegado de protección de datos lopd@solimat.com o 
interponer una reclamación frente a la Agencia Española de Protección de Datos. .</div>
                    
                </div>


            </FormProvider>

        </>
    )
}

export default SolicitudInformacionEnfermedadesProfesionales;
