import React, { useEffect, useState } from 'react';
import { useHistory } from "react-router-dom";
import { useForm, Controller } from 'react-hook-form';
import JSZip from 'jszip';
import PageMenu from "../../blocks/PageMenu/PageMenu";
import IconArrowGray from "../../../img/icons/svg/botones/flecha-big-gris.svg";
import IconCheck from "../../../img/icons/symbols/check-confirm.png";
import useGetRoyalDecreeFormById from 'hooks/useGetRoyalDecreeFormById';
import useUpdateIncompatibilityDocuments from 'hooks/useUpdateIncompatibilityDocuments';
import moment from "moment";
import useDecode from "hooks/useDecode";
import useCheckRecordStatusAndTypes from 'hooks/useCheckRecordStatusAndTypes';
import Delete from "../../../img/icons/svg/botones/delete.png";
import IMGnotice from "../../../img/icons/symbols/notice.png";
import './formRoyalDecree22021.scss';
import { TooltipComponent } from '@syncfusion/ej2-react-popups';
import { Modal, Button } from 'react-bootstrap';
import Loader from 'components/blocks/Loader/Loader';
const FormRoyalDecree22021: React.FC = (props: any) => {
    const { getRoyalDecreeFormById, loadingGetRoyalDecreeFormById, royalDecreeFormById, successGetRoyalDecreeFormById } = useGetRoyalDecreeFormById();
    const { UpdateIncompatibilityDocuments, loadingUpdateIncompatibilityDocuments, successUpdateIncompatibilityDocuments, errorUpdateIncompatibilityDocuments } = useUpdateIncompatibilityDocuments();
    const tokenResponse = useDecode("token");
    const userId = tokenResponse && tokenResponse.extension_id;
    const params = props.match.params;
    const paramRecordId = params.id.toString().split("_");
    const prestacionId = paramRecordId.length > 0 ? paramRecordId.length > 1 ? paramRecordId.at(1) : paramRecordId.at(0) : params.id;
    const formId = params.idform;
    const { checkFormStatusRD, checkFormStatusDocumentsRD } = useCheckRecordStatusAndTypes();
    const history = useHistory();
    const { control, handleSubmit, setValue, watch } = useForm();
    const [showModal, setShowModal] = useState(false);
    const [showModalSuccessfully, setShowModalSuccessfully] = useState(null);
    const [currentObservations, setCurrentObservations] = useState('');
    const noData = 'Sin datos'
    const [selectedFiles, setSelectedFiles] = useState<{ [key: string]: { files: File[]; incompatibilityId: string; incompatibilityName: string; documentName: string } }>({});
    const [isSubmitting, setIsSubmitting] = useState(false); 

    useEffect(() => {
        getRoyalDecreeFormById(userId, prestacionId, formId);
        setSelectedFiles({})
    }, [userId, prestacionId, formId, successUpdateIncompatibilityDocuments]);

    useEffect(() => {

        if (successUpdateIncompatibilityDocuments) {
            handleShowModalsuccessfully()
        }
    }, [successGetRoyalDecreeFormById, successUpdateIncompatibilityDocuments]);
    const handleFileChange = (
        e: React.ChangeEvent<HTMLInputElement>,
        documentId: string,
        incompatibilityId: string,
        incompatibilityName: string,
        documentName: string
    ) => {
        const allFiles = Array.from(e.target.files as FileList);

        const prohibitedExtensions = ['exe', 'bat', 'cmd', 'sh', 'msi', 'vbs', 'scr', 'js', 'jar', 'html', 'htm', 'mhtml', 'ods', 'jfif'];

        const validFiles = allFiles.filter(file => {
            const fileExtension = file.name.split('.').pop()?.toLowerCase();
            return !prohibitedExtensions.includes(fileExtension || '');
        });

        const invalidFiles = allFiles.filter(file => !validFiles.includes(file));

        if (invalidFiles.length > 0) {
            alert('Algunos archivos tienen extensiones no permitidas. Por favor, seleccione otros archivos.');
            e.target.value = '';
            return;
        }

        if (validFiles.length > 0) {
            setSelectedFiles(prevSelectedFiles => {
                const existingFiles = prevSelectedFiles[documentId]?.files || [];
                return {
                    ...prevSelectedFiles,
                    [documentId]: {
                        files: [...existingFiles, ...validFiles],
                        incompatibilityId,
                        incompatibilityName,
                        documentName,
                    },
                };
            });
        }
    };

    // const handleFileChange = (

    //     e: React.ChangeEvent<HTMLInputElement>,
    //     documentId: string,
    //     incompatibilityId: string,
    //     incompatibilityName: string,
    //     documentName: string
    // ) => {
    //     const newFiles = Array.from(e.target.files as FileList);
    //     const prohibitedExtensions = ['ods', 'mhtml', 'html', 'htm', 'jfif','exe'];

    //     const invalidFiles = newFiles.filter(file => {
    //         const fileExtension = file.name.split('.').pop()?.toLowerCase();
    //         return prohibitedExtensions.includes(fileExtension || '');
    //     });

    //     if (invalidFiles.length > 0) {
    //         alert('Algunos archivos tienen extensiones no permitidas. Por favor, seleccione otros archivos.');
    //         e.target.value = ''
    //         return;
    //     }

    //     if (newFiles.length > 0) {
    //         setSelectedFiles(prevSelectedFiles => {
    //             const existingFiles = prevSelectedFiles[documentId]?.files || [];
    //             return {
    //                 ...prevSelectedFiles,
    //                 [documentId]: {
    //                     files: [...existingFiles, ...newFiles],
    //                     incompatibilityId,
    //                     incompatibilityName,
    //                     documentName,
    //                 },
    //             };
    //         });
    //     }
    // };

    const removeFile = (documentId: string, fileIndex: number) => {
        setSelectedFiles(prevSelectedFiles => {
            const updatedFiles = prevSelectedFiles[documentId]?.files.filter((_, index) => index !== fileIndex);

            if (updatedFiles.length === 0) {
                const { [documentId]: removed, ...rest } = prevSelectedFiles;
                return rest;
            }

            return {
                ...prevSelectedFiles,
                [documentId]: {
                    ...prevSelectedFiles[documentId],
                    files: updatedFiles
                }
            };
        });
    };
    const convertFileToBase64 = async (file: File): Promise<string | null> => {
        return new Promise((resolve, reject) => {
            const reader = new FileReader();
            reader.readAsDataURL(file);
            reader.onload = () => {
                if (reader.result) {
                    const result = reader.result as string;

                    const base64Data = result.split(',')[1];
                    resolve(base64Data && base64Data);
                } else {

                    resolve(null);
                }
            };
            reader.onerror = (error) => {
                console.error(`Error al leer el archivo ${file.name}:`, error);
                reject(error);
            };
        });
    };
    const createZipFile = async (files: File[]): Promise<string> => {
        const zip = new JSZip();

        for (const file of files) {
            const base64 = await convertFileToBase64(file);
            if (base64) {
                zip.file(file.name, base64, { base64: true });
            } else {
                console.error(`El archivo ${file.name} está vacío o no se pudo leer.`);
            }
        }

        const zipContent = await zip.generateAsync({ type: "base64" });
        return zipContent;
    };
    const onSubmit = async () => {
        setIsSubmitting(true); 

        const incompatibilitiesMap = new Map();

        for (const documentId in selectedFiles) {
            const { files, incompatibilityId, documentName } = selectedFiles[documentId];

            let zipContent: string | null = null;

            if (files.length > 1) {
                zipContent = await createZipFile(files);
            } else if (files.length === 1) {
                zipContent = await convertFileToBase64(files[0]);
            }

            if (zipContent) {
                if (!incompatibilitiesMap.has(incompatibilityId)) {
                    incompatibilitiesMap.set(incompatibilityId, {
                        id: incompatibilityId,
                        documentos: [],
                    });
                }

                incompatibilitiesMap.get(incompatibilityId).documentos.push({
                    id: documentId,
                    nombre: `${documentName}`,
                    extension: files.length > 1 ? 'zip' : files[0].name.split('.').pop(),
                    base64: zipContent,
                });
            }
        }

        const incompatibilidades = Array.from(incompatibilitiesMap.values());

        const finalObject = {
            id: royalDecreeFormById?.Id,
            incompatibilidades: incompatibilidades,
        };

        UpdateIncompatibilityDocuments(userId, prestacionId, formId, finalObject);
        if (!loadingGetRoyalDecreeFormById) {
            setIsSubmitting(false)
        }
    }
    const hasSelectedFiles = Object.keys(selectedFiles).length > 0;

    const handleShowModal = (observations: string) => {
        setCurrentObservations(observations);
        setShowModal(true);
    };

    const handleCloseModal = () => {
        setShowModal(false);
        setCurrentObservations('');
    };
    const handleShowModalsuccessfully = () => {

        setShowModalSuccessfully(true);
    };

    const handleCloseModalsuccessfully = () => {
        setShowModalSuccessfully(false);
        setCurrentObservations('');
    };
    const handlePrevetAttach = (estate: number) => {
        switch (estate) {
            case 0:
                return false; // "Pendiente Adjuntar Documentos"
            case 1:
                return false; //"Pendiente Validar Gestor"
            case 2:
                return true; //"Aceptado"
            case 3:
                return true; //"Aceptado sin Requerir Documento"
            case 4:
                return false; //"Rechazado"
            case 5:
                return true; //"Obsoleto"
            default:
                return true; //"Estado Desconocido"
        }
    }
    return (
        <>
            <PageMenu title='Real Decreto 2/2021'>
                <div className="btns-header-container">
                    <button onClick={() => history.goBack()}>
                        <img src={IconArrowGray} alt="icon arrow right" />
                    </button>
                </div>
            </PageMenu>
            {loadingGetRoyalDecreeFormById && <Loader activeLoader={true} label="Cargando Formulario"></Loader>}
            <div className='container container-incopatibilidad'>
                <div className="container purpose-header">
                    <p style={{ textAlign: 'justify' }}>{royalDecreeFormById?.Encabezado ? royalDecreeFormById?.Encabezado : noData}</p>
                </div>
                <div className="container container-validations-observations">
                    <h2 className='title-incompatibilidades'>Estado General de Auditoría</h2>
                    <p><b>{royalDecreeFormById?.EstadoGeneralRevision ? checkFormStatusRD(royalDecreeFormById?.EstadoGeneralRevision) : noData}</b></p>
                </div>
                <div className="container container-validations-observations">
                    <h2 className='title-incompatibilidades'>Validaciones y Observaciones</h2>
                    <p>{royalDecreeFormById?.ValidacionesObserv ? royalDecreeFormById?.ValidacionesObserv : noData}</p>
                </div>
                <div className="container container-operational-description">
                    <h2 className='title-incompatibilidades'>Descripción Operativa</h2>
                    <p>{royalDecreeFormById?.DescripcionOperativa ? royalDecreeFormById?.DescripcionOperativa : noData}</p>
                    <ul className='ul-operational-description'>
                        {
                            royalDecreeFormById?.Incompatibilidades.map((inco: any, i: number) => (
                                <li key={i} className='li-operational-description'>{inco.Nombre}</li>

                            ))
                        }
                    </ul>
                </div>
                <div className="container-title-incompatibilidades">
                    <h1 className='title-incompatibilidades'>Incompatibilidades</h1>
                </div>
                {royalDecreeFormById?.Incompatibilidades.map((incopa: any, i: number) => (
                    <div className="container-incopatibilidad" key={incopa.Id}>
                        <fieldset className={`fieldset-container`}>

                            <legend className="col-12">
                                <span className="legend-text">{incopa.Nombre}</span>
                            </legend>
                            <h6 className='incompatibility-status'>{`Estado:  ${checkFormStatusRD(incopa.EstadoRevision)}`}</h6>
                            {incopa?.Documentos && Array.isArray(incopa.Documentos) &&
                                (incopa.Documentos.length === 0 || (incopa.Documentos.length === 1 && Object.keys(incopa.Documentos[0]).length === 0)) ? (
                                <div className="container">
                                    <h3 className='no-documents'>No requiere documentación o ya está solicitada en una Incompatibilidad anterior</h3>
                                </div>
                            ) : null}
                            {incopa.Documentos.some(doc => Object.keys(doc).length > 0) ? incopa?.Documentos.map((doc: any, j: number) => (
                                <div key={doc.Id}>
                                    <div className="container-documentos" >
                                        <div className='col-sm-12 col-md-12 col-lg-3'>
                                            <div className="column-name">Documento solicitado</div>
                                            <div className="name-and-document-container">

                                                <p className='name-doc-incopatibilidad-rd'>{doc.NombrePortal ? `${doc.NombrePortal}` : noData}</p>
                                                {doc.SasUri &&
                                                    <TooltipComponent content="Archivo adjunto">
                                                        <a
                                                            href={doc.SasUri}
                                                            rel={doc.NombrePortal}
                                                            // target="_blank"
                                                            className="link-bold"
                                                        >
                                                            <span className="file-icon-blue"></span>
                                                        </a>
                                                    </TooltipComponent>
                                                }
                                            </div>
                                        </div>
                                        <div className='col-sm-12 col-md-12 col-lg-3'>
                                            <div className="column-name">Fecha Presentación</div>
                                            <p className='presentation-date-rd'>{doc.FechaPresentacion ? moment(doc.FechaPresentacion).format('DD-MM-YYYY') : noData}</p>
                                        </div>
                                        <div className='col-sm-12 col-md-12 col-lg-4'>
                                            <div className="column-name">Estado Documento</div>
                                            <div className='name-and-icon-information'>
                                                <p>{checkFormStatusDocumentsRD(doc.EstadoDoc)}</p>
                                                {doc.Observaciones &&
                                                    <TooltipComponent content="Observaciones">
                                                        <span className='information-icon-orange' onClick={() => handleShowModal(doc.Observaciones)}></span>
                                                    </TooltipComponent>
                                                }

                                            </div>
                                        </div>
                                        <div className='col-sm-12 col-md-3 col-lg-2'>
                                            <Controller
                                                name={`filesRd-${i}-${j}`}
                                                control={control}
                                                defaultValue={null}
                                                render={({ field }) => (
                                                    <>
                                                        {loadingUpdateIncompatibilityDocuments || !incopa.Nombre ? <label htmlFor={`filesRd-${i}-${j}`} className={`btn-communication btn-load-file-rd btn-disabled `}>
                                                            Adjuntar archivo
                                                            <input
                                                                type="file"
                                                                id={`filesRd-${i}-${j}`}
                                                                className="input-file input-file-rd "
                                                                disabled
                                                            />
                                                        </label> :
                                                            <label htmlFor={`filesRd-${i}-${j}`} className={`btn-communication btn-load-file-rd ${handlePrevetAttach(doc.EstadoDoc) ? 'btn-disabled' : ''} `}>
                                                                Adjuntar archivo
                                                                <input
                                                                    type="file"
                                                                    id={`filesRd-${i}-${j}`}
                                                                    className="input-file input-file-rd "
                                                                    onChange={(e) => {
                                                                        handleFileChange(e, doc.Id, incopa.Id, incopa.Nombre, doc.NombrePortal);
                                                                        field.onChange(e.target.files[0]);
                                                                    }}
                                                                    disabled={handlePrevetAttach(doc.EstadoDoc)}
                                                                    accept=".pdf,.doc, .docx, .zip, .7z, .txt, .xls, .xlsx, .csv, .odt, .ods, .jpg, .jpeg, .png, .webp"
                                                                    multiple
                                                                />

                                                            </label>}
                                                    </>
                                                )}
                                            />

                                        </div>
                                        {/* <span style={{ fontSize: '16px', color: '#CE9238', textAlign: 'center', display: 'block', width: '100%', marginTop: '10px', background: 'white', padding: '6px' }}>
                                            Si es necesario adjuntar varios archivos, por favor comprímalos en un archivo ZIP antes de adjuntarlos.
                                        </span> */}
                                    </div>
                                    {selectedFiles[doc.Id] && (
                                        <div className='container-file-info'>
                                            <p className='selected-file-text-rd'>
                                                {`(${selectedFiles[doc.Id].documentName})`}
                                            </p>
                                            <div className='container-files-list-rd'>
                                                {selectedFiles[doc.Id].files.map((file, index) => (
                                                    <div key={index} className='container-name-file-rd'>
                                                        <p className='name-file-rd'>
                                                            {file.name}
                                                        </p>
                                                        <img
                                                            src={Delete}
                                                            alt="borrar"
                                                            className="remove-file-btn"
                                                            onClick={() => removeFile(doc.Id, index)}
                                                        />
                                                    </div>
                                                ))}
                                            </div>
                                        </div>
                                    )}
                                </div>
                            )) : ''}
                        </fieldset>
                    </div>
                ))}
                <div className="container container-btn-send-documents-rd">
                    {loadingUpdateIncompatibilityDocuments ? <Button className={`btn-box btn-accent loading btn-otpep active`}><div className="loader-btn"></div>
                    </Button> :
                        <button
                            type="submit"
                            className="btn-box btn-accent"
                            onClick={handleSubmit(onSubmit)}
                            disabled={!hasSelectedFiles || isSubmitting}
                        >
                            Enviar Documentos
                        </button>

                    }

                </div>
                <div className="container container-audit-status-summary">
                    <h2 className='title-incompatibilidades'>Resumen Estado Auditoria</h2>
                    <p>{royalDecreeFormById?.ResumenEstadoAuditoria ? royalDecreeFormById?.ResumenEstadoAuditoria : noData}</p>
                </div>
                <div className="container container-next-actions">
                    <h2 className='title-incompatibilidades'>Próximas Acciones</h2>
                    <p>{royalDecreeFormById?.ProximasAcciones ? royalDecreeFormById?.ProximasAcciones : noData}</p>
                </div>
                <div className="container-lopd">
                    <p className='text-lopd'>
                        {royalDecreeFormById?.LOPD}
                    </p>
                </div>
            </div>
            <Modal show={showModal} onHide={handleCloseModal} centered>
                <Modal.Header closeButton={false} className='header-modal-observaciones-rd'>
                    <span className='information-icon-orange big'></span>
                    <Modal.Title>

                        <h2 className='title-obsevaciones-rd'>Observaciones</h2>

                    </Modal.Title>
                </Modal.Header>
                <Modal.Body className='body-modal-observaciones-rd'>
                    <p className='text-body-modal-observaciones-rd'>{currentObservations ? currentObservations : noData}</p>

                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={handleCloseModal}>
                        Cerrar
                    </Button>
                </Modal.Footer>
            </Modal>
            {/* successfully OR ERROR */}
            <Modal show={showModalSuccessfully} onHide={handleCloseModalsuccessfully} centered>
                <Modal.Header closeButton={false} className='header-modal-observaciones-rd'>
                    {errorUpdateIncompatibilityDocuments && errorUpdateIncompatibilityDocuments ? (
                        <>
                            <img src={IMGnotice} alt="error" />
                            <Modal.Title>
                                <h2 className='title-obsevaciones-rd'>¡Error al enviar documentos !</h2>
                                <p className='text-obsevaciones-error-rd'>Por favor intentelo de nuevo</p>
                            </Modal.Title>
                        </>
                    ) : (
                        <>
                            <span className='icon-check-blue big'></span>
                            <Modal.Title>
                                <h2 className='title-obsevaciones-rd'>Documentos enviados con éxito</h2>
                            </Modal.Title>
                        </>
                    )}
                </Modal.Header>
                <Modal.Footer>
                    <Button variant="secondary" onClick={handleCloseModalsuccessfully}>
                        Cerrar
                    </Button>
                </Modal.Footer>
            </Modal>

        </>
    );
};

export default FormRoyalDecree22021;