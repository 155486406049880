import React, { useState, useEffect } from "react";
import Moment from "moment";
import useDecode from "../../../hooks/useDecode";

import "./Message.scss";

interface IMessage {
  userChatCommunication: any;
  userId: string;
}

const Message: React.FC<IMessage> = ({ userChatCommunication }) => {
  const tokenResponse = useDecode("token");
  const [userTokenId, setUserTokenId] = useState("");

  useEffect(() => {
    if (tokenResponse !== undefined) {
      setUserTokenId(tokenResponse.extension_id);
    }
    Moment().locale("es");
  }, []);
  return (
    <ul className="container-message-list">

      <li
        className={`message_block ${userChatCommunication?.userId === userTokenId ? "user" : ""}`}
      >
        <h2 className="message-header">
          <span className="message-author">
            {userChatCommunication?.autorUsuario === false ? userChatCommunication?.nombreAutor + ':' : "Usted :"}
          </span>
          <time className="message-date" dateTime="date">
            {Moment(userChatCommunication?.created).format("LT")}h -{" "}
            {Moment(userChatCommunication?.created).format("L")}
          </time>
        </h2>
        <div className="container-subject">
          <span className="label-message">Asunto:</span>
          <span className="subject-body">{userChatCommunication?.asunto}</span>
        </div>
        <div className="container-message">
          <span className="label-message">Mensaje:</span>
          <textarea className="message-body" readOnly defaultValue={userChatCommunication?.mensaje} ></textarea>
        </div>

        {userChatCommunication?.files.length > 0 ? (
          <div className="container-files-message">
            <span className="label-message-files">Documentos enviados:</span>
            <div className="files_block">
              {userChatCommunication?.files.map((item: any, index: number) => {
                return (
                  <div className="file-link-container" key={index}>
                    <a
                      href={item.sasUri}
                      // target="_blank"
                      className="file-link"
                    >
                      <span className="file-icon"></span>
                      <span className="file-text">{item.originalName}</span>
                    </a>
                  </div>
                );
              })}
            </div>
          </div>
        ) : <div className="container-files-message">
          <span className="label-message-files">Documentos enviados:</span>
          <div className="files_block">
            <div className="file-link-container" >
              <span className="label-message-files">Sin documentos adjuntos</span>
            </div>

          </div>
        </div>}
      </li>

    </ul>
  );
};

export default Message;
