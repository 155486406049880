import PageMenu from 'components/blocks/PageMenu/PageMenu';
import React, { useEffect, useState, createContext, useContext } from 'react';
import { useLocation } from 'react-router-dom';
import IconArrowGray from "../../../img/icons/svg/botones/flecha-big-gris.svg";
import { Link } from "react-router-dom";
import InputText from 'components/blocks/Delta/Inputs/InputText';
import FieldsetContainer from 'components/blocks/Delta/Inputs/Containers/FieldsetContainer';
import { FormProvider, useForm } from 'react-hook-form';
import Form from '@solimat/solimat-web-endpoint/lib/forms-builder/gridfield.service';
import InputPattern from 'components/blocks/ManagementNewForms/Inputs/InputPattern';
import Province from 'components/blocks/Delta/Inputs/Province';
import TestCommunity from 'components/blocks/Delta/Inputs/TestCommunity';
import InputHora from 'components/blocks/ManagementNewForms/Inputs/InputHora';
import InputDate from 'components/blocks/Delta/Inputs/InputDate';
import TextAreaDefault from 'components/blocks/ManagementNewForms/TextArea/TextAreaDefault';
import Loader from 'components/blocks/Loader/Loader';
import useCreateSolicitudVolanteAsistencia from 'services/managementNewForms/useCreateSolicitudVolanteAsistencia';
import InputNIE from 'components/blocks/ManagementNewForms/Inputs/InputNIE';



export const SolicitudVolanteAsistencia: React.FC = () => {

  const location = useLocation();
  const title = "Solicitud Volante de Asistencia Sanitaria"
  const breadcrumbs = ["Volante Asistencia"];
  const formMethods = useForm();
  const { register, handleSubmit, setError, formState: { errors }, setValue, watch } = formMethods;
  const { createVolanteAsistencia, loadingCreateVolanteAsistencia, successCreateVolanteAsistencia, errorCreateVolanteAsistencia } = useCreateSolicitudVolanteAsistencia();
  const watchProvinceCode = watch("ProvinciaEmpresa", 0);
  const watchProvinceDescription = watch("ProvinciaDescripcionEmpresa");
  const watchCommunityCode = watch("ComunidadCodigoEmpresa");
  const watchCommunityDescription = watch("ComunidadDescripcionEmpresa");

  const watchProvinceCode2 = watch("ProvinciaTrabajador", 0);
  const watchProvinceDescription2 = watch("ProvinciaDescripcionTrabajador");
  const watchCommunityCode2 = watch("ComunidadCodigoTrabajador");
  const watchCommunityDescription2 = watch("ComunidadDescripcionTrabajador");

  const watchProvinceCode3 = watch("ProvinciaConcertado", 0);
  const watchProvinceDescription3 = watch("ProvinciaDescripcionConcertado");
  const watchCommunityCode3 = watch("ComunidadCodigoConcertado");
  const watchCommunityDescription3 = watch("ComunidadDescripcionConcertado");


  




  const onSubmit = async (data: any, event) => {
    try {
      createVolanteAsistencia('', data);
      console.log(data);
    } catch (error) {
      console.error(error);
    }
  };




  return (
    <>
      <PageMenu title={title} breadcrumbs={breadcrumbs}>
        <div className="btns-header-container">
          <Link to={`/inicio`}>
            <img src={IconArrowGray} alt="icon arrow right" />
          </Link>
        </div>
      </PageMenu>
      <FormProvider {...formMethods}>
        <div className="container form--simple-styled" id="formDeltawithoutleave">
          <div className="row">
            <div className="col-xl-12">
              <FieldsetContainer legend="Datos de la empresa" disabled={false}>
                <div className="col-xl-4 col-md-6">
                  <InputPattern
                    id="RazonSocial"
                    name="RazonSocial"
                    label="Razón Social de la Empresa"
                    placeholder="Razón Social"
                    errors={errors}
                    register={register}
                    req='Falta Razón Social de la Empresa'
                  />
                </div>

                <div className="col-xl-4 col-md-6">
                  <InputPattern
                    id="NAF"
                    name="NAF"
                    label="Número de Afiliación"
                    placeholder="NAF"
                    errors={errors}
                    register={register}
                    req='Falta Número de Afiliación'
                    pattern={/^[0-9]+$/}

                    patternMessage='Solo se aceptan valores numericos'
                  />
                </div>
                <br />
                <div className="col-xl-4 col-md-6">
                  <InputPattern
                    id="DomicilioEmpresa"
                    name="DomicilioEmpresa"
                    label="Domicilio de la Empresa"
                    placeholder="Domicilio"
                    errors={errors}
                    register={register}
                    req='Falta Domicilio'

                  />
                </div>
                <div className="col-xl-6">
                  <Province
                    setValue={setValue}
                    errors={errors}
                    register={register}
                    watchProvinceCode={watchProvinceCode}
                    watchProvinceDescription={watchProvinceDescription}
                    idCode="ProvinciaEmpresa"
                    idDescription="Provincia_DescripcionEmpresa"
                    required='Falta Provincia de la Empresa'
                  />
                </div>
                <div className="col-xl-6">
                  <TestCommunity
                    errors={errors}
                    register={register}
                    setValue={setValue}
                    idCode="ComunidadCodigoEmpresa"
                    idDescription="ComunidadDescripcionEmpresa"
                    filterByProvince={true}
                    watchProvinceCode={watchProvinceCode}
                    watchCommunityCode={watchCommunityCode}
                    watchCommunityDescription={watchCommunityDescription}
                    required='Falta el Municipio de la Empresa'
                  />
                </div>
                <div className="col-xl-12 col-md-12">
                  <InputPattern
                    id="DependenciasEmpresa"
                    name="DependenciasEmpresa"
                    label="Dependencias"
                    placeholder=""
                    errors={errors}
                    register={register}
                  />
                </div>
              </FieldsetContainer>
            </div>
          </div>

          <div className="row">
            <div className="col-xl-12">
              <FieldsetContainer legend="Datos del Trabajador/a" disabled={false}>
                <div className="col-xl-4 col-md-12">
                  <InputPattern
                    id="NombreApellidoTrabajador"
                    name="NombreApellidoTrabajador"
                    label="Nombre y Apellido del Trabajador"
                    placeholder=""
                    errors={errors}
                    register={register}
                    req='Falta Nombre y Apellido del Trabajador'
                  />
                </div>


                <InputNIE
                  id="DNITrabajador"
                  name="DNITrabajador"
                  label="DNI del Trabajador"
                  placeholder=""
                  watch={watch}
                  xl={4}
                  md={12}
                  errors={errors}
                  register={register}
                  req='DNI del Trabajador'

                />

                <div className="col-xl-4 col-md-6">
                  <InputPattern
                    id="DomicilioTrabajador"
                    name="DomicilioTrabajaor"
                    label="Domicilio del Trabajador"
                    placeholder="Domicilio"
                    errors={errors}
                    register={register}
                    req='Falta Domicilio del Trabajador'

                  />
                </div>
                <div className="col-xl-6">
                  <Province
                    setValue={setValue}
                    errors={errors}
                    register={register}
                    watchProvinceCode={watchProvinceCode2}
                    watchProvinceDescription={watchProvinceDescription2}
                    idCode="ProvinciaTrabajador"
                    idDescription="Provincia_DescripcionTrabajador"
                    required='Falta Provincia del Trabajador'
                  />
                </div>
                <div className="col-xl-6">
                  <TestCommunity
                    errors={errors}
                    register={register}
                    setValue={setValue}
                    idCode="ComunidadCodigoTrabajador"
                    idDescription="ComunidadDescripcionTrabajador"
                    filterByProvince={true}
                    watchProvinceCode={watchProvinceCode2}
                    watchCommunityCode={watchCommunityCode2}
                    watchCommunityDescription={watchCommunityDescription2}
                    required='Falta el Municipio del Trabajador'
                  />
                </div>
                <div className="col-xl-6">
                  <InputHora
                    id='HoraAccidente'
                    name='HoraAccidente'
                    label='Hora Accidente'
                    placeholder='10:00'
                    errors={errors}
                    register={register}
                    req='Falta Hora Accidente'
                  />

                </div>

                <div className="col-xl-6 col-md-12">
                  <InputDate
                    id="AccidenteFecha"
                    name="AccidenteFecha"
                    label="Fecha del accidente"
                    placeholder="Fecha del accidente"
                    errors={errors}
                    register={register}
                    setValue={setValue}

                  />


                </div>
                <div className="col-xl-12 col-md-12">
                  <TextAreaDefault
                    id="AccidenteDescripcion" name="AccidenteDescripcion" label="Descripcion del Accidente" errors={errors} register={register}
                    resizable={false}
                    rows={5}
                    req='Falta Descripcion del Accidente'
                  />
                </div>


              </FieldsetContainer>
            </div>
          </div>


          <div className="row">
            <div className="col-xl-12">
              <FieldsetContainer legend="Espacio para centros reservados" disabled={false}>
                <div className="col-xl-6 col-md-12">
                  <InputPattern
                    id="NombreCentroConcertado"
                    name="NombreCentroConcertado"
                    label="Nombre del Centro Concertado"
                    placeholder=""
                    errors={errors}
                    register={register}

                  />
                </div>
                <div className="col-xl-6 col-md-12">
                  <InputPattern
                    id="TelefonoConcertado"
                    name="TelefonoConcertado"
                    label="Teléfono del Centro Concertado"
                    placeholder=""
                    errors={errors}
                    register={register}
                    maxLength={9}
                    pattern={/^[0-9]{9}$/i}
                    patternMessage='No es un numero de telefono valido'
                  />
                </div>
                <div className="col-xl-6 col-md-12">
                  <InputPattern
                    id="DomicilioCentroConcertado"
                    name="DomicilioCentroConcertado"
                    label="Domicilio del Centro Concertado"
                    placeholder=""
                    errors={errors}
                    register={register}

                  />
                </div>

                <div className="col-xl-6">
                  <Province
                    setValue={setValue}
                    errors={errors}
                    register={register}
                    watchProvinceCode={watchProvinceCode3}
                    watchProvinceDescription={watchProvinceDescription3}
                    idCode="ProvinciaConcertado"
                    idDescription="Provincia_DescripcionConcertado"
                  />
                </div>
                <div className="col-xl-6">
                  <TestCommunity
                    errors={errors}
                    register={register}
                    setValue={setValue}
                    idCode="ComunidadCodigoConcertado"
                    idDescription="ComunidadDescripcionConcertado"
                    filterByProvince={true}
                    watchProvinceCode={watchProvinceCode3}
                    watchCommunityCode={watchCommunityCode3}
                    watchCommunityDescription={watchCommunityDescription3}
                  />
                </div>

              </FieldsetContainer>

            </div>
          </div>

          <button className='btn-box btn-accent mt-3 mb-3' type="submit" onClick={handleSubmit(onSubmit)}>
            Enviar
          </button>
          {loadingCreateVolanteAsistencia && (
            <Loader activeLoader={true} label="Enviando Formulario" />
          )}


        </div>

      </FormProvider>
    </>

  )
}

export default SolicitudVolanteAsistencia;



