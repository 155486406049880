import React, { useState, useEffect, useContext } from 'react';
import PageMenu from "../../blocks/PageMenu/PageMenu";
import { SignalRContext } from "../../../contexts/SignalRContext";
import useUpdateNotification from "../../../services/notifications/useUpdateNotification";
import { useCheckTypeNotification } from "hooks/useCheckTypeNotification";
import "./notifications.scss";
import useDecode from "hooks/useDecode";
import { getValue } from '@syncfusion/ej2-base';
import { EmitType } from "@syncfusion/ej2-base";
import useGetRole from "hooks/useGetRole";

import {
  GridComponent,
  ColumnsDirective,
  ColumnDirective,
  Page,
  PageSettingsModel,
  Sort,
  Inject,
  RowDataBoundEventArgs
} from "@syncfusion/ej2-react-grids";
import Loader from 'components/blocks/Loader/Loader';
// import { TooltipComponent } from '@syncfusion/ej2-react-popups';
import useGetAllNotificationsByUser from "../../../services/notifications/useGetAllNotificationsByUser";

const Notifications: React.FC = (props:any) => {
  const {checkTypeNotification} = useCheckTypeNotification();
  const tokenResponse = useDecode("token");
  const userId = tokenResponse && tokenResponse.extension_id;
  const {isImpersonation}= useGetRole
  const impersonation = isImpersonation()
  const {
    notification,
    setIsUpdated,
  } = useContext(SignalRContext);

  const {
    updateNotification,
    responseUpdate,

  } = useUpdateNotification();

  const {
  getAllUserNotifications,
  allUserNotifications,
  loadingGetAllUserNotifications
  } = useGetAllNotificationsByUser();

  const [viewedActiveTab, setViewedActiveTab] = useState(false);
  const [allViewedNotifications, setAllViewedNotifications] = useState<any[]>([]);
  const [allNotViewedNotifications, setAllNotViewedNotifications] = useState<any[]>([]);
useEffect(() => {
  getAllUserNotifications(userId)

}, [])

  useEffect(() => {
    setIsUpdated(false)
    if (responseUpdate === false) {
      const viewedNotifications = allUserNotifications?.filter((item: any) => item.leido);
      const notViewedNotifications = allUserNotifications?.filter((item: any) => !item.leido);
      setAllViewedNotifications(viewedNotifications);
      setAllNotViewedNotifications(notViewedNotifications);

      if (notViewedNotifications.length < 1 && viewedNotifications?.length >= 1) {
        setViewedActiveTab(true);
      }
    }
  }, [allUserNotifications,notification])
  const handleSelectTab = (viewed:boolean) => {
    setViewedActiveTab(viewed);
  }

  const rowSelected = (args: any) => {
    
    const redirectPath = checkTypeNotification(args.data);
    if (redirectPath?.pathname && redirectPath?.pathname !== '') {
      if (args.data.leido) {
        props.history.push({
          pathname: `${redirectPath.pathname}`,
        });
      } else {
           
        updateNotification(userId, args.data?.id);
        setIsUpdated(true)
        props.history.push({
          pathname: `${redirectPath.pathname}`,
        });
        

      }
    } else {
      if(!args.data.leido) {
        updateNotification(userId, args.data?.id);
       
      }
    }
  };

  const [pageOptions] = useState<PageSettingsModel>({
    pageSize: 10,
    pageSizes: ["5", "10", "15", "20", "Todos"],
  })
  const [sortingOptions] = useState<Object>({
    columns: [{ field: "fechaHora", direction: "Descending" }],
  })

  const rowDataBound: EmitType<any> = (args: RowDataBoundEventArgs) => {
    if (args.row) {
      if (getValue('type_notification', args.data) !== 3) {
        args.row.classList.add('link__row');
      }
    }
    
  }
  const queryCellInfo = (args: any) => {
    if (args.column.field === 'mensaje' && args.data[args.column.field]) {
      args.cell.title = args.data[args.column.field];
      
    }
  };
  
  return (
    <>
    {loadingGetAllUserNotifications && <Loader activeLoader label='Descargando Notificaciones'></Loader>}
      <PageMenu
        title="Notificaciones"
        breadcrumbs={["Notificaciones"]}
      />
      <section className="container">
        <div className="row">
          <div className="col-12">
            <div className="notifications-tabs">
              <div
                onClick={() => handleSelectTab(false)}
                className={`tab ${viewedActiveTab ? '' : 'active'}`}
              >
                <span className={`${allNotViewedNotifications?.length >= 1 ? 'news' : ''}`}>NO LEIDAS</span>
              </div>
              <div
                onClick={() => handleSelectTab(true)}
                className={`tab ${viewedActiveTab ? 'active' : ''}`}
              >
                <span className="text">LEIDAS</span>
              </div>
            </div>
            <div className="notifications__grid">
              <GridComponent
                dataSource={viewedActiveTab ? allViewedNotifications : allNotViewedNotifications}
                allowPaging={true}
                allowSorting={true}
                rowSelected={!impersonation ? rowSelected : null}
                pageSettings={pageOptions}
                sortSettings={sortingOptions}
                rowDataBound={rowDataBound}
                queryCellInfo={queryCellInfo}
                enablePersistence={true}
              >
                <ColumnsDirective>
                  <ColumnDirective
                    field="titulo"
                    headerText="Titulo"
                    textAlign="Left"
                    width="200"
                  ></ColumnDirective>
                  
                  <ColumnDirective
                    field="mensaje"
                    headerText="Mensaje"
                    textAlign="Left"
                    width="auto"
                  ></ColumnDirective>
                  <ColumnDirective
                    field="fechaHora"
                    headerText="Fecha"
                    type="date"
                    format="dd/MM/yyyy"
                    textAlign="Left"
                    width="120"
                  ></ColumnDirective>
                  <ColumnDirective
                    field="fechaHora"
                    headerText="Hora"
                    type="date"
                    format="HH:mm"
                    textAlign="Left"
                    width="100"
                  ></ColumnDirective>
                </ColumnsDirective>
                <Inject services={[Page, Sort]} />
              </GridComponent>
            </div>
          </div>
        </div>
      </section>
    </>
  )
}

export default Notifications;