import FieldsetContainer from 'components/blocks/Delta/Inputs/Containers/FieldsetContainer';
import React from 'react';
import { FieldValues, RefCallBack, UseFormRegister, useForm, useFormContext } from 'react-hook-form';
import InputPattern from '../Inputs/InputPattern';
import Province from 'components/blocks/Delta/Inputs/Province';
import TestCommunity from 'components/blocks/Delta/Inputs/TestCommunity';
import InputNIE from '../Inputs/InputNIE';
import InputDate from 'components/blocks/Delta/Inputs/InputDate';

interface IInputProps {
    errors: any,
    register: any,
    label: string
    setValue: any
    watch: any
    children?: any
    seguridadSocial?: boolean
    acronimo?: string
    telefono?: boolean
    localizacion?: boolean
    nacimiento?: boolean
    email?: boolean
}

const TrabajadorButtonGroup: React.FC<IInputProps> = ({ errors, register, setValue, watch, label, children, seguridadSocial, acronimo, telefono, localizacion, nacimiento, email }) => {



    const watchProvinceCode = watch(`${acronimo ? acronimo : 'Trabajador'}Province`, 0);
    const watchProvinceDescription = watch(`${acronimo ? acronimo : 'Trabajador'}Province_description`);
    const watchCommunityCode = watch(`${acronimo ? acronimo : 'Trabajador'}CommunityCode`);
    const watchCommunityDescription = watch(`${acronimo ? acronimo : 'Trabajador'}CommunityDescription`);
    return (
        <>
            <div className="row">
                <div className="col-xl-12">
                    <FieldsetContainer legend={label} disabled={false}>

                        <InputPattern
                            id={`${acronimo ? acronimo : 'Trabajador'}Nombre`}
                            name={`${acronimo ? acronimo : 'Trabajador'}Nombre`}
                            label="Nombre del Trabajador"
                            placeholder=""
                            req='Falta Nombre del Trabajador'
                            xl={6}
                            md={6}
                            errors={errors}
                            register={register}


                        />
                        <InputPattern
                            id={`${acronimo ? acronimo : 'Trabajador'}Apellido1`}
                            name={`${acronimo ? acronimo : 'Trabajador'}Apellido1`}
                            label="1º Apellido del Trabajador"
                            placeholder=""
                            req='Falta 1º Apellido del Trabajador'
                            xl={6}
                            md={6}
                            errors={errors}
                            register={register}


                        />
                        <InputPattern
                            id={`${acronimo ? acronimo : 'Trabajador'}Apellido2`}
                            name={`${acronimo ? acronimo : 'Trabajador'}Apellido2`}
                            label="2º Apellido del Trabajador"
                            placeholder=""
                            req='Falta 2º Apellido del Trabajador'
                            xl={6}
                            md={6}
                            errors={errors}
                            register={register}


                        />

                        <InputNIE
                            id={`${acronimo ? acronimo : 'Trabajador'}NIE`}
                            name={`${acronimo ? acronimo : 'Trabajador'}NIE`}
                            label="NIE/NIF/DNI/Pasaporte"
                            placeholder=""
                            req='Falta rellenar el Documento de Identificación'
                            xl={6}
                            md={6}
                            errors={errors}
                            register={register}
                            maxLength={20}
                            watch={watch}
                            
                        />
                        {email &&
                            <InputPattern
                                id={`${acronimo ? acronimo : 'Trabajador'}Email`}
                                name={`${acronimo ? acronimo : 'Trabajador'}Email`}
                                label="Email"
                                placeholder=""
                                req='Falta Email'
                                xl={6}
                                md={6}
                                errors={errors}
                                register={register}


                            />}
                        {telefono &&
                            <InputPattern
                                id={`${acronimo ? acronimo : 'Trabajador'}Telefono`}
                                name={`${acronimo ? acronimo : 'Trabajador'}Telefono`}
                                label="Telefono"
                                placeholder=""
                                req='Falta Teléfono'
                                xl={6}
                                md={6}
                                errors={errors}
                                register={register}
                            />
                        }
                        {nacimiento &&
                            <div className="col-xl-6 col-md-6">
                                <InputDate
                                    id={`${acronimo ? acronimo : 'Trabajador'}FechaNacimiento`}
                                    name={`${acronimo ? acronimo : 'Trabajador'}FechaNacimiento`}
                                    label="Fecha de Nacimiento"
                                    placeholder=""
                                    req='Falta Fecha de Nacimiento'
                                    maxDate={new Date(Date.now())}
                                    errors={errors}
                                    register={register}

                                />
                            </div>
                        }
                        {!localizacion &&
                            <>
                                <InputPattern
                                    id={`${acronimo ? acronimo : 'Trabajador'}Domicilio`}
                                    name={`${acronimo ? acronimo : 'Trabajador'}Domicilio`}
                                    label="Domicilio (Calle/Plaza/Avenida/...)"
                                    placeholder=""
                                    req='Falta Domicilio'
                                    xl={6}
                                    md={6}
                                    errors={errors}
                                    register={register}


                                />
                                <InputPattern
                                    id={`${acronimo ? acronimo : 'Trabajador'}CodigoPostal`}
                                    name={`${acronimo ? acronimo : 'Trabajador'}CodigoPostal`}
                                    label="Código Postal"
                                    placeholder=""
                                    errors={errors}
                                    register={register}
                                    maxLength={5}
                                    req='Falta Código Postal'
                                    xl={6}
                                    md={6}
                                    pattern={/^[0-9]+$/i}
                                    patternMessage='No es un CP válido'
                                />


                                <div className="col-xl-6 col-md-6">
                                    <Province
                                        setValue={setValue}
                                        errors={errors}
                                        register={register}
                                        watchProvinceCode={watchProvinceCode}
                                        watchProvinceDescription={watchProvinceDescription}
                                        idCode={`${acronimo ? acronimo : 'Trabajador'}Province`}
                                        idDescription={`${acronimo ? acronimo : 'Trabajador'}Province_description`}
                                        required=''
                                    />
                                </div>
                                <div className="col-xl-6 col-md-6">
                                    <TestCommunity
                                        errors={errors}
                                        register={register}
                                        setValue={setValue}
                                        idCode={`${acronimo ? acronimo : 'Trabajador'}CommunityCode`}
                                        idDescription={`${acronimo ? acronimo : 'Trabajador'}CommunityDescription`}
                                        filterByProvince={true}
                                        watchProvinceCode={watchProvinceCode}
                                        watchCommunityCode={watchCommunityCode}
                                        watchCommunityDescription={watchCommunityDescription}
                                        required='Falta el Municipio del Trabajador'
                                    />
                                </div>
                            </>}

                        {seguridadSocial && (
                            <InputPattern
                                id={`${acronimo ? acronimo : 'Trabajador'}SeguridadSocial`}
                                name={`${acronimo ? acronimo : 'Trabajador'}SeguridadSocial`}
                                label="Nº Seguridad Social"
                                placeholder=""
                                errors={errors}
                                register={register}
                                maxLength={12}
                                xl={6}
                                md={6}
                                pattern={/^[0-9]+$/i}
                                patternMessage='Solo se aceptan números'
                            />
                        )}



                        {children}

                    </FieldsetContainer>
                </div>
            </div>
        </>
    )
}

export default TrabajadorButtonGroup;

// USE EXAMPLE
{/* <InputText 
  id="personalDataName"
  name="personalDataName"
  label="Nombre"
  placeholder="Nombre"
  errors={errors}
  register={register}
  req = 'MENSAJE DE ERROR CUANDO NO HAYA VALOR'
/> */}
