import React, { useEffect, useState, createContext, useContext } from 'react'
import { useLocation, Link } from 'react-router-dom';
import PageMenu from 'components/blocks/PageMenu/PageMenu';
import IconArrowGray from "../../../img/icons/svg/botones/flecha-big-gris.svg";
import FieldsetContainer from 'components/blocks/Delta/Inputs/Containers/FieldsetContainer';
import { FormProvider, useForm } from 'react-hook-form';
import Loader from 'components/blocks/Loader/Loader';
import InputPattern from 'components/blocks/ManagementNewForms/Inputs/InputPattern';
import InputNIE from 'components/blocks/ManagementNewForms/Inputs/InputNIE';
import EmpresaButtonGroup from 'components/blocks/ManagementNewForms/ButtonGroups/EmpresaButtonGroup';
import TrabajadorButtonGroup from 'components/blocks/ManagementNewForms/ButtonGroups/TrabajadorButtonGroup';
import RadioButtonGroup from 'components/blocks/ManagementNewForms/ButtonGroups/RadioButtonGroup';
import InputDate from 'components/blocks/Delta/Inputs/InputDate';
import ArrayFieldContingencias from 'components/blocks/ManagementNewForms/ButtonGroups/ArrayFieldContingencias';

export const CertificadoEmpleadorPrestacionEmpleadosHogar: React.FC = () => {

    const location = useLocation();
    const title = "Certificado del empleador para la solicitud de prestación económica del sistema de empleados de hogar "
    const breadcrumbs = ["Certificado del empleador para la solicitud de prestación económica del sistema de empleados de hogar "];
    const formMethods = useForm();
    const { register, handleSubmit, setError, formState: { errors }, setValue, watch, control } = formMethods;

    const onSubmit = async (data: any) => {

        console.log(data);
    };


    return (
        <>
            <PageMenu title={title} breadcrumbs={breadcrumbs}>
                <div className="btns-header-container">
                    <Link to={`/inicio`}>
                        <img src={IconArrowGray} alt="icon arrow right" />
                    </Link>
                </div>
            </PageMenu>
            <FormProvider {...formMethods}>
                <div className="container form--simple-styled" id="formDeltawithoutleave">
                    <div className="row">
                        <div className="col-xl-12">
                            <FieldsetContainer legend="" disabled={false}>
                                <InputPattern
                                    id="DonCertifica"
                                    name="DonCertifica"
                                    label="D./Dña."
                                    placeholder=""

                                    xl={6}
                                    md={6}
                                    errors={errors}
                                    register={register}
                                    req='Quien certifica el documento'

                                />
                                <InputNIE
                                    id="DNIRepresentante"
                                    name="DNIRepresentante"
                                    label="DNI del Representante"
                                    placeholder=""
                                    watch={watch}
                                    xl={6}
                                    md={6}
                                    errors={errors}
                                    register={register}
                                    req='DNI del Representante'

                                />
                                <label className="input-block__label">en calidad de empleador CERTIFICA: Que son ciertos los datos relativos a las circunstancias personales, profesionales y de cotización, que se consignan a
                                    efectos de solicitar la prestación de:</label>

                                <div className="input-block--radio-check__group d-flex flex-wrap flex-column align-items-start">
                                    <label htmlFor="opcion1" className="input-block--radio-check__label ml-4">
                                        <span className="text">Incapacidad Temporal por Contingencia Profesional</span>
                                        <input
                                            type="radio"
                                            name="TipoIncapacidad"
                                            id="TipoIncapacidad"
                                            value="Incapacidad Temporal por Contingencia Profesional"
                                            {...register("TipoIncapacidad", { required: 'Debe seleccionar al menos una opción' })}
                                        />
                                    </label>
                                    <label htmlFor="opcion2" className="input-block--radio-check__label ">
                                        <span className="text">Riesgo durante el Embarazo o Lactancia Natural</span>
                                        <input
                                            type="radio"
                                            name="TipoIncapacidad"
                                            id="TipoIncapacidad"
                                            value="Riesgo durante el Embarazo o Lactancia Natural"
                                            {...register("TipoIncapacidad")}
                                        />
                                    </label>
                                    <label htmlFor="opcion3" className="input-block--radio-check__label ">
                                        <span className="text">Incapacidad Temporal por Contingencia Común</span>
                                        <input
                                            type="radio"
                                            name="TipoIncapacidad"
                                            id="TipoIncapacidad"
                                            value="Incapacidad Temporal por Contingencia Común"
                                            {...register("TipoIncapacidad")}
                                        />
                                    </label>
                                    <label htmlFor="opcion4" className="input-block--radio-check__label ">
                                        <span className="text">Cuidado de menores con cáncer o enfermedad grave</span>
                                        <input
                                            type="radio"
                                            name="TipoIncapacidad"
                                            id="TipoIncapacidad"
                                            value="Cuidado de menores con cáncer o enfermedad grave"
                                            {...register("TipoIncapacidad")}
                                        />
                                    </label>
                                </div>
                            </FieldsetContainer>
                        </div>
                    </div>

                    <EmpresaButtonGroup
                        errors={errors}
                        register={register}
                        label={'Datos del Empleador'}
                        setValue={setValue}
                        razonSocial={true}
                        acronimo={'Empleador'}
                        telefono={true}
                        watch={watch}>



                    </EmpresaButtonGroup>

                    <TrabajadorButtonGroup
                        errors={errors}
                        register={register}
                        label={'Datos del Empleador'}
                        setValue={setValue}
                        seguridadSocial={true}
                        telefono={true}
                        localizacion={true}
                        watch={watch}>

                        <div className="col-xl-6 col-md-6">
                            <RadioButtonGroup errors={errors} name={'TrabajadorTipoContrato'}
                                register={register}

                                label={'Tipo de Contrato'}
                                chkname1={'Fijo'}
                                chkname2={'Parcial'}
                                chklabel1={'Contrato Fijo'}
                                chklabel2={'Contrato Parcial'}
                                nombreGrupo={'TipoContrato'}
                            />
                        </div>
                        <InputPattern
                            id="TrabajadorTramoCotizacion"
                            name="TrabajadorTramoCotizacion"
                            label="Tramo de Cotización"
                            placeholder=""
                            xl={6}
                            md={6}
                            errors={errors}
                            register={register}

                        />
                        <div className="col-xl-6 col-md-6">
                            <InputDate
                                id="TrabajadorInicioActividad"
                                name="TrabajadorInicioActividad"
                                label=" "
                                maxDate={new Date(Date.now())}
                                placeholder=""
                                errors={errors}
                                register={register}
                                setValue={setValue}

                            />
                        </div>
                        <div className="col-xl-6 col-md-6">
                            <InputDate
                                id="TrabajadorFechaSituacionActividad"
                                name="TrabajadorFechaSituacionActividad"
                                label="Fecha Situación de IT o Riesgo"

                                placeholder=""
                                errors={errors}
                                register={register}
                                setValue={setValue}

                            />
                        </div>
                    </TrabajadorButtonGroup>

                    <ArrayFieldContingencias
                        register={register}
                        errors={errors} control={control}
                        name={'TrabajadorContingencias'}
                        legend={' Base de cotización de los meses anteriores a la fecha de inicio de la prestación '}
                        contingenciasProfesionales={true}
                        horasExtra={false}


                    />

                    {/* BOTON DE ENVIAR */}
                    <button className='btn-box btn-accent mt-3 mb-3' type="submit" onClick={handleSubmit(onSubmit)}>
                        Enviar
                    </button>

                    <div className='paragraph small text-secondary  mb-4'>En cumplimiento de lo establecido en la normativa sobre de Protección de Datos de Carácter Personal, Solimat Mutua Colaboradora con la Seguridad Social nº 72 le informa
                        que sus datos de carácter personal serán objeto de tratamiento, con la finalidad del mantenimiento y cumplimiento de la relación. La base
                        jurídica que legitima el tratamiento de sus datos es el cumplimiento de una obligación legal. Sus datos serán conservados de forma indefinida en nuestra
                        entidad para atender a las posibles responsabilidades derivadas del tratamiento. Usted podrá revocar el consentimiento prestado, así como ejercitar sus derechos de
                        acceso, rectificación, supresión, oposición, limitación del tratamiento, o portabilidad, en su caso, remitiendo un escrito a la siguiente dirección: Solimat,
                        Mutua Colaboradora con la Seguridad Social nº 72, C/ Berna, 1, 45003, Toledo, adjuntando su NIF. Si considera que sus derechos no han sido debidamente
                        atendidos, puede contactar con nuestro delegado de protección de datos lopd@solimat.com o interponer una reclamación frente a la Agencia Española de Protección
                        de Datos.
                    </div>

                </div>


            </FormProvider>

        </>
    )
}

export default CertificadoEmpleadorPrestacionEmpleadosHogar;
