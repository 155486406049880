import FieldsetContainer from 'components/blocks/Delta/Inputs/Containers/FieldsetContainer';
import React from 'react';
import { FieldValues, RefCallBack, UseFormRegister, useForm, useFormContext } from 'react-hook-form';
import InputPattern from '../Inputs/InputPattern';
import Province from 'components/blocks/Delta/Inputs/Province';
import TestCommunity from 'components/blocks/Delta/Inputs/TestCommunity';

interface IInputProps {
    errors: any,
    register: any,
    label: string
    setValue:any
    watch:any
    children?: any
    acronimo?: string
    razonSocial?: boolean
    telefono?: boolean
}

const EmpresaButtonGroup: React.FC<IInputProps> = ({errors, register, setValue, watch, label, children, acronimo, razonSocial, telefono }) => {

    const newAcronimo = acronimo ? acronimo : 'Empresa';
    const watchProvinceCode = watch(`${newAcronimo}Province`, 0);
    const watchProvinceDescription = watch(`${newAcronimo}Province_description`);
    const watchCommunityCode = watch(`${newAcronimo}CommunityCode`);
    const watchCommunityDescription = watch(`${newAcronimo}CommunityDescription`);

    return (
        <>
            <div className="row">
                <div className="col-xl-12">
                    <FieldsetContainer legend={label} disabled={false}>

                        {!razonSocial &&
                            <InputPattern
                                id={`${newAcronimo}NombreRazonSocial`}
                                name={`${newAcronimo}NombreRazonSocial`}
                                label="Nombre o Razón Social"
                                placeholder=""
                                req='Falta Nombre o Razón Social'
                                xl={6}
                                md={6}
                                errors={errors}
                                register={register}
                            />
                        }

                        {razonSocial &&
                            <InputPattern
                                id={`${newAcronimo}NombreApellido`}
                                name={`${newAcronimo}NombreApellido`}
                                label="Nombre y Apellidos"
                                placeholder=""
                                req='Falta Nombre y Apellidos'
                                xl={6}
                                md={6}
                                errors={errors}
                                register={register}
                            />
                        }
                        <InputPattern
                            id={`${newAcronimo}CCC`}
                            name={`${newAcronimo}CCC`}
                            label="Codigo Cuenta Cotización"
                            placeholder="CCC"
                            errors={errors}
                            register={register}
                            req='Falta Código Cuenta Cotización'
                            pattern={/^[0-9]+$/}
                            patternMessage='Solo se aceptan valores numericos'
                            xl={6}
                            md={6}

                        />
                        {telefono &&
                            <InputPattern
                                id={`${newAcronimo}Telefono`}
                                name={`${newAcronimo}Telefono`}
                                label="Telefono de la Empresa"
                                placeholder=""
                                req='Falta Telefono de la Empresa'
                                xl={6}
                                md={6}
                                errors={errors}
                                register={register}
                                maxLength={9}
                                pattern={/^[0-9]+$/}
                                patternMessage='Solo se aceptan valores numericos'
                            />
                        }
                        <InputPattern
                            id={`${newAcronimo}Domicilio`}
                            name={`${newAcronimo}Domicilio`}
                            label="Domicilio (calle/plaza/...)"
                            placeholder=""
                            req='Falta Domicilio de la Empresa'
                            xl={6}
                            md={6}
                            errors={errors}
                            register={register}

                        />
                        <InputPattern
                            id={`${newAcronimo}CodigoPostal`}
                            name={`${newAcronimo}CodigoPostal`}
                            label="Codigo Postal"
                            req='Falta Codigo Postal de la Empresa'
                            placeholder=""
                            pattern={/^[0-9]+$/}
                            patternMessage='Solo se aceptan valores numericos'
                            xl={6}
                            md={6}
                            errors={errors}
                            register={register}

                        />
                        <div className="col-xl-6 col-md-6">
                            <Province
                                setValue={setValue}
                                errors={errors}
                                register={register}
                                watchProvinceCode={watchProvinceCode}
                                watchProvinceDescription={watchProvinceDescription}
                                idCode={`${newAcronimo}Province`}
                                idDescription={`${newAcronimo}Province_description`}
                                required='Falta Provincia de la Empresa'
                            />
                        </div>
                        <div className="col-xl-6 col-md-6">
                            <TestCommunity
                                errors={errors}
                                register={register}
                                setValue={setValue}
                                idCode={`${newAcronimo}CommunityCode`}
                                idDescription={`${newAcronimo}CommunityDescription`}
                                filterByProvince={true}
                                watchProvinceCode={watchProvinceCode}
                                watchCommunityCode={watchCommunityCode}
                                watchCommunityDescription={watchCommunityDescription}
                                required='Falta el Municipio de la Empresa'
                            />
                        </div>

                        

                        {children}
                    </FieldsetContainer>
                </div>
            </div>
        </>
    )
}

export default EmpresaButtonGroup;

// USE EXAMPLE
{/* <InputText 
  id="personalDataName"
  name="personalDataName"
  label="Nombre"
  placeholder="Nombre"
  errors={errors}
  register={register}
  req = 'MENSAJE DE ERROR CUANDO NO HAYA VALOR'
/> */}