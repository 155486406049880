import React, { useEffect } from "react";
import "../GeneralManagement/GeneralManagement.scss";
import useGetMyManagement from "services/management/useGetMyManagement";
import LoaderMin from "components/blocks/Loader/LoaderMin";
import { useGeneralManagementState } from "../GeneralManagement/generalManagementState";
import DocumentsAuthorizationsGrid from "components/blocks/Grids/DocumentsAuthorizationsGrid";
import useGetAllAuthorizations from "services/management/useGetAllAuthorizations";

const ManagementAuthorizations = ({ props, handleOnClick }) => {

  const { state, dispatch } = useGeneralManagementState();
  const {
    getMyManagement,
    successGetMyManagement,
    dataMyManagement,
    documentosAutorizaciones,
    formulariosAutorizaciones,
  } = useGetMyManagement();
  const {
    allGetAllAuthorizations,
    getAllAuthorizations,
    loadingGetAllAuthorizations,
    successGetAllAuthorizations,
    errorGetAllAuthorizations } = useGetAllAuthorizations()
  const {
    prestacionActiva,
  } = state;
  useEffect(() => {
    if (formulariosAutorizaciones.length <= 0) {
      getMyManagement();
    } else {
      return;
    }
    getAllAuthorizations()

  }, []);

  useEffect(() => {
    if (state.formulariosAutorizaciones <= 0) {
      dispatch({
        type: "SET_FORMS_AUTORIZACIONES",
        payload: formulariosAutorizaciones
        ,
      });
      dispatch({
        type: "SET_DOCUMENTOS_AUTORIZACIONES",
        payload: allGetAllAuthorizations

      });
    }
  }, [dataMyManagement]);
  return (
    <div className="container pl-5 pr-5 mt-0 mb-4 ">
      <div className="row">
        <div className="col">
          {/*  */}
          <div className="accordion accordion-flush" id="accordionFlushExample">
            <div className="accordion-item mt-0">
              <h2 className="accordion-header" id="flush-headingFour">
                <button
                  className="accordion-button collapsed header-acordeon-title"
                  type="button"
                  data-bs-toggle={
                    state.formulariosAutorizaciones &&
                      state.formulariosAutorizaciones.length <= 0
                      ? ""
                      : "collapse"
                  }
                  data-bs-target="#flush-collapseFour"
                  aria-expanded="false"
                  aria-controls="flush-collapseFour"
                >
                  <h1 className="ml-4 title-gestion">
                    Formularios Autorizaciones
                  </h1>
                  {prestacionActiva.length === 0 &&
                    state.formulariosAutorizaciones &&
                    state.formulariosAutorizaciones.length === 0 &&
                    !successGetMyManagement && (
                      <span className="without-active-benefit">
                        <LoaderMin />
                      </span>
                    )}
                  {successGetMyManagement &&
                    state.formulariosAutorizaciones?.length === 0 && (
                      <span className="without-active-benefit">
                        No hay formularios de autorizaciones
                      </span>
                    )}
                </button>
              </h2>
              <div
                id="flush-collapseFour"
                className="accordion-collapse collapse "
                aria-labelledby="flush-headingFour"
                data-bs-parent="#accordionFlushExample"
              >
                <div className="accordion-body m-1">
                  {state.formulariosAutorizaciones &&
                    state.formulariosAutorizaciones.map((autorizacion, i) =>
                      autorizacion.Tipo === 1 ? (
                        <div
                          className="link-document-management"
                          key={`autorizacion{i + 12}`}
                          onClick={() =>
                            handleOnClick(autorizacion.Titulo, autorizacion.Url)
                          }
                        >
                          <div className="col-sm-12 col-md-9 container-items">
                            <h1 className="name-management-item mb-2">
                              {autorizacion.Titulo}
                            </h1>
                            <p className="description-management-item">
                              {autorizacion.Descripcion}
                            </p>
                          </div>
                          <div className="col-md-3 descargar-pdf-container">
                            <span className="link-bold">Ver</span>
                          </div>
                        </div>
                      ) : (
                        <div
                          className="link-document-management"
                          key={`autorizacion{i + 11}`}
                        >
                          <div className=" col-sm-12 col-md-9 container-items">
                            <h1 className="name-management-item mb-2">
                              {autorizacion.Titulo}
                            </h1>

                            <p className="description-management-item">
                              {autorizacion.Descripcion}
                            </p>
                          </div>
                          <div className="col-md-3 descargar-pdf-container">
                            <a
                              href={autorizacion.Url}
                              rel="noopener noreferrer"
                              // target="_blank"
                              className="link-bold"
                            >
                              Descargar PDF
                            </a>
                          </div>
                        </div>
                      )
                    )}
                </div>
              </div>
            </div>

            {/* documentos de autorizaciones  */}
            <div className="accordion-item mt-4">
              <h2 className="accordion-header" id="flush-headingFive">
                <button
                  className="accordion-button collapsed header-acordeon-title"
                  type="button"
                  data-bs-toggle={
                    allGetAllAuthorizations &&
                      allGetAllAuthorizations?.length <= 0 || allGetAllAuthorizations === null
                      ? ""
                      : "collapse"
                  }
                  data-bs-target="#flush-collapseFive"
                  aria-expanded="false"
                  aria-controls="flush-collapseFive"
                >
                  <h1 className="ml-4 title-gestion">Documentos Autorizaciones</h1>
                  {
                    !successGetAllAuthorizations && (
                      <span className="without-active-benefit">
                        <LoaderMin />
                      </span>
                    )}
                  {successGetAllAuthorizations &&
                    allGetAllAuthorizations?.length === 0 && (
                      <span className="without-active-benefit">
                        No hay documentos de autorizaciones
                      </span>
                    )}
                </button>
              </h2>
              <div
                id="flush-collapseFive"
                className="accordion-collapse collapse "
                aria-labelledby="flush-headingFive"
                data-bs-parent="#accordionFlushExample"
              >
                <div className="accordion-body m-1">
                  <DocumentsAuthorizationsGrid documents={allGetAllAuthorizations && allGetAllAuthorizations}></DocumentsAuthorizationsGrid>

                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ManagementAuthorizations;