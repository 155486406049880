import React, { useEffect } from "react";
import "./GeneralManagement.scss";
import { Link, useLocation } from 'react-router-dom'
import LoaderMin from "components/blocks/Loader/LoaderMin";
import { useGeneralManagementState } from "./generalManagementState";
const GeneralManagement = ({ props, handleOnClick }) => {
  const { state } = useGeneralManagementState();

  const {
    prestacionActiva,
    prestacionGeneral,
    gruposPrestacionActiva,
    gruposPrestacionGeneral,
    successGetMyManagement,
    loadinGetMyManagement
  } = state;

  useEffect(() => {

  }, [successGetMyManagement, prestacionActiva, gruposPrestacionActiva, loadinGetMyManagement])

  return (
    <>
      <div className="container pl-5 pr-5 mt-4 mb-1 ">
        <div className="row">
          <div className="col">
            <div
              className="accordion accordion-flush"
              id="accordionFlushExample"
            >
              <div className="accordion-item ">
                <h2
                  className="accordion-header"
                  id="panelsStayOpen-headingThree"
                >
                  <button
                    className="accordion-button actives collapsed header-acordeon-title"
                    type="button"
                    data-bs-toggle={
                      prestacionActiva.length <= 0 &&
                        gruposPrestacionActiva.length <= 0
                        ? ""
                        : "collapse"
                    }
                    data-bs-target="#panelsStayOpen-collapseThree"
                    aria-expanded="false"
                    aria-controls="panelsStayOpen-collapseThree"
                  >
                    <h1 className="ml-4 title-gestion">
                      Gestiones de la prestación activa
                    </h1>
                    {!successGetMyManagement ? (
                      <span className="without-active-benefit">
                        <LoaderMin />
                      </span>
                    ) : prestacionActiva.length <= 0 &&
                      gruposPrestacionActiva.length <= 0 && successGetMyManagement ? (
                      <span className="without-active-benefit">
                        No hay prestación activa
                      </span>
                    ) : (
                      ""
                    )}
                  </button>
                </h2>
                <div
                  id="panelsStayOpen-collapseThree"
                  className="accordion-collapse collapse"
                  aria-labelledby="panelsStayOpen-headingThree"
                >
                  <div className="accordion-body m-1">
                    {prestacionActiva?.length === 0 &&
                      prestacionGeneral?.length === 0 &&
                      successGetMyManagement && <LoaderMin />}
                    {successGetMyManagement &&
                      prestacionGeneral.length <= 0 &&
                      gruposPrestacionGeneral.length <= 0 && (
                        <p className="loader-text c-center">
                          No hay gestiones generales
                        </p>
                      )}
                    {prestacionActiva?.map((prestacion, i) =>
                      prestacion.Tipo === 1 ? (
                        <div
                          className="link-document-management"
                          key={`prestacion_${i + 1}`}
                          onClick={() =>
                            handleOnClick(prestacion.Titulo, prestacion.Url)
                          }
                        >
                          <div className="col-sm-12 col-md-9 container-items">
                            <h1 className="name-management-item mb-2">
                              {prestacion.Titulo}
                            </h1>
                            <p className="description-management-item">
                              {prestacion.Descripcion}
                            </p>
                          </div>
                          <div className="col-md-3 descargar-pdf-container">
                            <span className="link-bold">Ver</span>
                          </div>
                        </div>
                      ) : prestacion.Tipo === 2 ? (
                        <div
                          className="link-document-management"
                          key={`prestacion_${i + 1}`}
                        >
                          <div className=" col-sm-12 col-md-9 container-items">
                            <h1 className="name-management-item mb-2">
                              {prestacion.Titulo}
                            </h1>

                            <p className="description-management-item">
                              {prestacion.Descripcion}
                            </p>
                          </div>
                          <div className="col-md-3 descargar-pdf-container">
                            <a
                              href={prestacion.Url}
                              rel="noopener noreferrer"
                              // target="_blank"
                              className="link-bold"
                            >
                              Descargar PDF
                            </a>
                          </div>
                        </div>
                      ) : (
                        <div
                          className="link-document-management"
                          key={`prestacion_${i + 1}`}
                        >
                          <div className=" col-sm-12 col-md-9 container-items">
                            <h1 className="name-management-item mb-2">
                              {prestacion.Titulo}
                            </h1>

                            <p className="description-management-item">
                              {prestacion.Descripcion}
                            </p>
                          </div>
                          <div className="col-md-3 descargar-pdf-container">
                            <Link
                              to={{
                                pathname: "/mis-gestiones/formularios",
                                state: { titulo: prestacion.Titulo, id: prestacion.Id }
                              }}
                              className="link-bold"
                            >
                              Ver
                            </Link>
                          </div>
                        </div>
                      )
                    )}
                  </div>
                  {/* grupos */}
                  {gruposPrestacionActiva
                    ? gruposPrestacionActiva.map((grupo, i) => (
                      <div className="container" key={i + 500}>
                        <div className="accordion-item">
                          <h2
                            className="accordion-header"
                            id={`flush-headingTwoA${i}`}
                          >
                            <button
                              className="accordion-button actives header-acordeon-title collapsed mb-2 mt-2"
                              type="button"
                              data-bs-toggle="collapse"
                              data-bs-target={`#flush-collapseTwoA${i}`}
                              aria-expanded="false"
                              aria-controls={`flush-collapseTwoA${i}`}
                            >
                              {grupo.Titulo}
                            </button>
                          </h2>
                          <div
                            id={`flush-collapseTwoA${i}`}
                            className="accordion-collapse collapse"
                            aria-labelledby="flush-headingTwoA"
                            data-bs-parent={`#accordionFlushExampleA${i}`}
                          >
                            <div className="accordion-body bg-groups-active">
                              <div>
                                <p className="description-group">
                                  {grupo.Descripcion}
                                </p>
                              </div>
                              {grupo.Gestiones.map((gestion, i) =>
                                gestion.Tipo === 1 ? (
                                  <div
                                    className="link-document-management"
                                    key={`prestacion_${i + 12}`}
                                    onClick={() =>
                                      handleOnClick(
                                        gestion.Titulo,
                                        gestion.Url
                                      )
                                    }
                                  >
                                    <div className="col-sm-12 col-md-9 container-items">
                                      <h1 className="name-management-item mb-2">
                                        {gestion.Titulo}
                                      </h1>
                                      <p className="description-management-item">
                                        {gestion.Descripcion}
                                      </p>
                                    </div>
                                    <div className="col-md-3 descargar-pdf-container">
                                      <span className="link-bold">Ver</span>
                                    </div>
                                  </div>
                                ) : gestion.Tipo === 2 ? (
                                  <div
                                    className="link-document-management"
                                    key={`prestacion_${i + 11}`}
                                  >
                                    <div className=" col-sm-12 col-md-9 container-items">
                                      <h1 className="name-management-item mb-2">
                                        {gestion.Titulo}
                                      </h1>

                                      <p className="description-management-item">
                                        {gestion.Descripcion}
                                      </p>
                                    </div>
                                    <div className="col-md-3 descargar-pdf-container">
                                      <a
                                        href={gestion.Url}
                                        rel="noopener noreferrer"
                                        // target="_blank"
                                        className="link-bold"
                                      >
                                        Descargar PDF
                                      </a>
                                    </div>
                                  </div>
                                ) :
                                  (<div
                                    className="link-document-management"
                                    key={`prestacion_${i + 11}`}
                                  >
                                    <div className=" col-sm-12 col-md-9 container-items">
                                      <h1 className="name-management-item mb-2">
                                        {gestion.Titulo}
                                      </h1>

                                      <p className="description-management-item">
                                        {gestion.Descripcion}
                                      </p>
                                    </div>
                                    <div className="col-md-3 descargar-pdf-container">
                                      <Link
                                        to={{
                                          pathname: "/mis-gestiones/formularios",
                                          state: { titulo: gestion.Titulo, id: gestion.Id }
                                        }}
                                        className="link-bold"
                                      >
                                        Ver
                                      </Link>
                                    </div>
                                  </div>)
                              )}
                            </div>
                          </div>
                        </div>
                      </div>
                    ))
                    : ""}
                </div>
              </div>
              <div className="accordion-item mt-4">
                <h2 className="accordion-header" id="flush-headingOne">
                  <button
                    className="accordion-button collapsed header-acordeon-title"
                    type="button"
                    data-bs-toggle={
                      prestacionGeneral.length <= 0 &&
                        gruposPrestacionGeneral.length <= 0 ? "" : "collapse"
                    }
                    data-bs-target="#flush-collapseOne"
                    aria-expanded="false"
                    aria-controls="flush-collapseOne"
                  >
                    <h1 className="ml-4 title-gestion">
                      Gestiones generales
                    </h1>
                    {prestacionActiva.length === 0 &&
                      prestacionGeneral.length === 0 &&
                      !successGetMyManagement && (
                        <span className="without-active-benefit">
                          <LoaderMin />
                        </span>
                      )}
                    {successGetMyManagement &&
                      prestacionGeneral?.length === 0 &&
                      gruposPrestacionGeneral.length <= 0 && (
                        <span className="without-active-benefit">
                          No hay gestiones generales
                        </span>
                      )}
                  </button>
                </h2>
                <div
                  id="flush-collapseOne"
                  className="accordion-collapse collapse"
                  aria-labelledby="flush-headingOne"
                  data-bs-parent="#accordionFlushExample"
                >
                  <div className="accordion-body m-1">
                    {prestacionGeneral &&
                      prestacionGeneral.map((prestacion, i) =>
                        prestacion.Tipo === 1 ? (
                          <div
                            className="link-document-management"
                            key={`prestacion_${i + 12}`}
                            onClick={() =>
                              handleOnClick(prestacion.Titulo, prestacion.Url)
                            }
                          >
                            <div className="col-sm-12 col-md-9 container-items">
                              <h1 className="name-management-item mb-2">
                                {prestacion.Titulo}
                              </h1>
                              <p className="description-management-item">
                                {prestacion.Descripcion}
                              </p>
                            </div>
                            <div className="col-md-3 descargar-pdf-container">
                              <span className="link-bold">Ver</span>
                            </div>
                          </div>
                        ) : prestacion.Tipo === 2 ? (
                          <div
                            className="link-document-management"
                            key={`prestacion_${i + 11}`}
                          >
                            <div className=" col-sm-12 col-md-9 container-items">
                              <h1 className="name-management-item mb-2">
                                {prestacion.Titulo}
                              </h1>

                              <p className="description-management-item">
                                {prestacion.Descripcion}
                              </p>
                            </div>
                            <div className="col-md-3 descargar-pdf-container">
                              <a
                                href={prestacion.Url}
                                rel="noopener noreferrer"
                                // target="_blank"
                                className="link-bold"
                              >
                                Descargar PDF
                              </a>
                            </div>
                          </div>
                        ) : (
                          <div
                            className="link-document-management"
                            key={`prestacion_${i + 11}`}
                          >
                            <div className=" col-sm-12 col-md-9 container-items">
                              <h1 className="name-management-item mb-2">
                                {prestacion.Titulo}
                              </h1>

                              <p className="description-management-item">
                                {prestacion.Descripcion}
                              </p>
                            </div>
                            <div className="col-md-3 descargar-pdf-container">
                              <Link
                                to={{
                                  pathname: "/mis-gestiones/formularios",
                                  state: { titulo: prestacion.Titulo, id: prestacion.Id }
                                }}
                                className="link-bold"
                              >
                                Ver
                              </Link>
                            </div>
                          </div>
                        )
                      )}
                  </div>
                  {/* grupos */}
                  {gruposPrestacionGeneral.length > 0
                    ? gruposPrestacionGeneral.map((grupo, i) => (
                      <div className="container" key={i + 600}>
                        <div className="accordion-item">
                          <h2
                            className="accordion-header"
                            id={`flush-headingTwo${i}`}
                          >
                            <button
                              className="accordion-button header-acordeon-title collapsed mb-2 mt-2"
                              type="button"
                              data-bs-toggle="collapse"
                              data-bs-target={`#flush-collapseTwo${i}`}
                              aria-expanded="false"
                              aria-controls={`flush-collapseTwo${i}`}
                            >
                              {grupo.Titulo}
                            </button>
                          </h2>
                          <div
                            id={`flush-collapseTwo${i}`}
                            className="accordion-collapse collapse"
                            aria-labelledby="flush-headingTwo"
                            data-bs-parent={`#accordionFlushExample${i}`}
                          >
                            <div className="accordion-body bg-groups-active">
                              <div>
                                <p className="description-group">
                                  {grupo.Descripcion}
                                </p>
                              </div>
                              {grupo.Gestiones.map((gestion, i) =>
                                gestion.Tipo === 1 ? (
                                  <div
                                    className="link-document-management"
                                    key={`prestacion_${i + 12}`}
                                    onClick={() =>
                                      handleOnClick(
                                        gestion.Titulo,
                                        gestion.Url
                                      )
                                    }
                                  >
                                    <div className="col-sm-12 col-md-9 container-items">
                                      <h1 className="name-management-item mb-2">
                                        {gestion.Titulo}
                                      </h1>
                                      <p className="description-management-item">
                                        {gestion.Descripcion}
                                      </p>
                                    </div>
                                    <div className="col-md-3 descargar-pdf-container">
                                      <span className="link-bold">Ver</span>
                                    </div>
                                  </div>
                                ) : gestion.Tipo === 2 ? (
                                  <div
                                    className="link-document-management"
                                    key={`prestacion_${i + 11}`}
                                  >
                                    <div className=" col-sm-12 col-md-9 container-items">
                                      <h1 className="name-management-item mb-2">
                                        {gestion.Titulo}
                                      </h1>

                                      <p className="description-management-item">
                                        {gestion.Descripcion}
                                      </p>
                                    </div>
                                    <div className="col-md-3 descargar-pdf-container">
                                      <a
                                        href={gestion.Url}
                                        rel="noopener noreferrer"
                                        target="_blank"
                                        className="link-bold"
                                      >
                                        Descargar PDF
                                      </a>
                                    </div>
                                  </div>
                                ) : (
                                  <div
                                    className="link-document-management"
                                    key={`prestacion_${i + 11}`}
                                  >
                                    <div className=" col-sm-12 col-md-9 container-items">
                                      <h1 className="name-management-item mb-2">
                                        {gestion.Titulo}
                                      </h1>

                                      <p className="description-management-item">
                                        {gestion.Descripcion}
                                      </p>
                                    </div>
                                    <div className="col-md-3 descargar-pdf-container">
                                      <Link
                                        to={{
                                          pathname: "/mis-gestiones/formularios",
                                          state: { titulo: gestion.Titulo, id: gestion.Id }
                                        }}
                                        className="link-bold"
                                      >
                                        Ver
                                      </Link>
                                    </div>
                                  </div>
                                )
                              )}
                            </div>
                          </div>
                        </div>
                      </div>
                    ))
                    : ""}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default GeneralManagement;
