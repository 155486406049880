import React, { useEffect, useState, createContext, useContext } from 'react'
import { useLocation, Link } from 'react-router-dom';
import PageMenu from 'components/blocks/PageMenu/PageMenu';
import IconArrowGray from "../../../img/icons/svg/botones/flecha-big-gris.svg";
import FieldsetContainer from 'components/blocks/Delta/Inputs/Containers/FieldsetContainer';
import { FormProvider, useForm } from 'react-hook-form';
import Loader from 'components/blocks/Loader/Loader';
import TrabajadorButtonGroup from 'components/blocks/ManagementNewForms/ButtonGroups/TrabajadorButtonGroup';
import InputPattern from 'components/blocks/ManagementNewForms/Inputs/InputPattern';
import InputMultiRadio from 'components/blocks/ManagementNewForms/Inputs/InputMultiRadio';
import InputDate from 'components/blocks/Delta/Inputs/InputDate';
import InputIBAN from 'components/blocks/ManagementNewForms/Inputs/InputIBAN';
import InputUpload from 'components/blocks/ManagementNewForms/Inputs/InputUpload';

export const SolicitudPrestacionEconomicaREL: React.FC = () => {

    const location = useLocation();
    const title = "Solicitud de prestación económicapor riesgo durante el embarazo o la lactancia natural "
    const breadcrumbs = ["Solicitud de prestación económicapor riesgo durante el embarazo o la lactancia natural "];
    const formMethods = useForm();
    const { register, handleSubmit, setError, formState: { errors }, setValue, watch } = formMethods;

    const onSubmit = async (data: any) => {

        console.log(data);
    };


    return (
        <>
            <PageMenu title={title} breadcrumbs={breadcrumbs}>
                <div className="btns-header-container">
                    <Link to={`/inicio`}>
                        <img src={IconArrowGray} alt="icon arrow right" />
                    </Link>
                </div>
            </PageMenu>
            <FormProvider {...formMethods}>
                <div className="container form--simple-styled" id="formDeltawithoutleave">


                    <TrabajadorButtonGroup errors={errors} register={register} label={'Datos de la Solicitante'} setValue={setValue} watch={watch} localizacion={true}
                    >
                        <InputPattern
                            id="SolicitanteIRPFVoluntario"
                            name="SolicitanteIRPFVoluntario"
                            label="TIPO DE IRPF VOLUNTARIO (Superior al obligatorio según Normativa fiscal)"
                            placeholder=""


                            xl={12}
                            md={12}
                            errors={errors}
                            register={register}


                        />
                    </TrabajadorButtonGroup>

                    <div className="row">
                        <div className="col-xl-12">
                            <FieldsetContainer legend="Datos de la Empresa" disabled={false}>
                                <InputPattern
                                    id="EmpresaRazonSocial"
                                    name="PrestacionRazonSocial"
                                    label="Nombre o razón social de la empresa "
                                    placeholder=""

                                    xl={6}
                                    md={6}
                                    errors={errors}
                                    register={register}


                                />
                                <InputPattern
                                    id="EmpresaCCC"
                                    name="EmpresaCCC"
                                    label="Código Cuenta Cotización de la empresa"
                                    placeholder=""
                                    pattern={/^[0-9]+$/}
                                    patternMessage='Solo se permiten valores numericos'

                                    xl={6}
                                    md={6}
                                    errors={errors}
                                    register={register}


                                />
                            </FieldsetContainer>
                        </div>
                    </div>

                    <div className="row">
                        <div className="col-xl-12">
                            <FieldsetContainer legend="Motivo de la Solicitud" disabled={false}>

                                <InputMultiRadio
                                    errors={errors}
                                    register={register}
                                    opciones={["Riesgo durante el Embarazo", "Riesgo durante la Lactancia"]}
                                    labels={["Riesgo durante el Embarazo", "Riesgo durante la Lactancia"]}
                                    name={'MotivoSolicitud'}
                                    id={'MotivoSolicitud'}
                                    required='Es necesario elegir una opción'
                                    label='Motivo de la Solicitud que va a presentar'
                                />

                                <div className="col-xl-12 col-md-12">
                                    <InputDate
                                        id="FechaSuspensionCeseActividad"
                                        name="FechaSuspensionCeseActividad"
                                        label="Fecha suspensión contrato o cese actividad"
                                        maxDate={new Date(Date.now())}
                                        placeholder=""
                                        errors={errors}
                                        register={register}
                                        setValue={setValue}
                                        req='La Fecha es necesaria'
                                    />
                                </div>
                            </FieldsetContainer>
                        </div>
                    </div>

                    <div className="row">
                        <div className="col-xl-12">
                            <FieldsetContainer legend="Datos Bancarios" disabled={false}>
                                <InputIBAN
                                    id='SolicitanteIBAN'
                                    name='SolicitanteIBAN'
                                    label='IBAN'
                                    placeholder=''
                                    xl={12}
                                    md={12}
                                    errors={errors}
                                    register={register}

                                    req='Falta IBAN'

                                />
                            </FieldsetContainer>
                        </div>
                    </div>
                    <div className='paragraph small text-secondary  mt-4'>DECLARO, bajo mi responsabilidad, que son ciertos los datos que consigno en la presente solicitud de pago directo que suscribo para que se me
                        reconozca la prestación por riesgo durante el embarazo o la lactancia natural, manifestando que quedo enterada de la obligación de comunicar a
                        la Mutua cualquier variación de los mismos que pudiera producirse mientras perciba la prestación.</div>
                    <div className="row">
                        <div className="col-xl-12">
                            <FieldsetContainer legend="Archivos a Adjuntar" disabled={false}>
                            <label className="input-block__label mb-2 mt-4">En todos los casos: </label>
                                <InputUpload
                                    name={'ArchivoDniDelante'}
                                    id={'ArchivoDniDelante'}
                                    label={'Foto DNI/NIE/NIF por Delante'}
                                    placeholder={''}
                                    errors={errors}
                                    register={register}
                                    xl={6}
                                    md={6}
                                    setError={setError}
                                    req='El Documento de Identidad es requerido'
                                />
                                <InputUpload
                                    name={'ArchivoDniDetras'}
                                    id={'ArchivoDniDetras'}
                                    label={'Foto DNI/NIE/NIF por Detras'}
                                    placeholder={''}
                                    errors={errors}
                                    register={register}
                                    xl={6}
                                    md={6}
                                    setError={setError} />
                                <InputUpload
                                    name={'ArchivoCertificacionMedicaREL'}
                                    id={'ArchivoCertificacionMedicaREL'}
                                    label={'Certificación médica sobre la existencia de riesgo durante el embarazo, o la lactancia natural en aquellos casos en los que no obre en poder de esta entidad. '}
                                    placeholder={''}
                                    errors={errors}
                                    register={register}
                                    xl={12}
                                    md={12}
                                    req='El Certificado Médico es requerido'
                                    setError={setError} />
                                    
                                <InputUpload
                                    name={'ArchivoImpresoIRPF'}
                                    id={'ArchivoImpresoIRPF'}
                                    label={' Impreso a efectos IRPF (modelo 145 de la Agencia Tributaria), excepto para residentes en País Vasco y Navarra. '}
                                    placeholder={''}
                                    errors={errors}
                                    register={register}
                                    xl={12}
                                    md={12}
                                   
                                    setError={setError} />
                                <InputUpload
                                    name={'ArchivoLibroDeFamilia'}
                                    id={'ArchivoLibroDeFamilia'}
                                    label={'Libro de familia con inscripción del hijo, en supuestos de prestación por riesgo durante la lactancia natural. '}
                                    placeholder={''}
                                    errors={errors}
                                    register={register}
                                    xl={12}
                                    md={12}
                                    
                                    setError={setError} />
                                <InputUpload
                                    name={'ArchivoDeclaracionEmpresarial'}
                                    id={'ArchivoDeclaracionEmpresarial'}
                                    label={'Declaración empresarial sobre descripción y exposición a riesgos durante el embarazo o la lactancia natural. '}
                                    placeholder={''}
                                    errors={errors}
                                    register={register}
                                    xl={12}
                                    md={12}
                                    req='La Declaración Empresarial es requerida'
                                    setError={setError} />
                                    <div className='mt-3'></div>
                                <InputUpload
                                    name={'ArchivoCertificadoEmpresarial'}
                                    id={'ArchivoCertificadoEmpresarial'}
                                    label={"Certificado empresarial para la solicitud de la prestación económica por riesgo durante el embarazo o la lactancia natural. Declaración de la empresa sobre la inexistencia de puestos de trabajo compatibles con el estado de la trabajadora o, cuando estos existan, sobre la imposibilidad, técnica u objetiva, de realizar el traslado correspondiente, o que no pueda razonablemente exigirse por motivos justificados. De igual modo, se deberá reflejar también la fecha en la que la trabajadora ha suspendido la relación laboral. La declaración irá acompañada de informe sobre estos particulares emitido por el servicio de prevención propio de la empresa, siempre que cuente con la especialidad preventiva de vigilancia de la salud, o por la entidad especializada que desarrolle para la empresa, en base al correspondiente concierto, las funciones de servicio de prevención ajeno"}
                                    placeholder={''}
                                    errors={errors}
                                    register={register}
                                    req='El Certificado Empresarial es requerido'   
                                    xl={12}
                                    md={12}
                                    setError={setError} />
                                <div className='mt-3'></div>
                                <InputUpload
                                    name={'ArchivoCertificadoEmpresaEconomia'}
                                    id={'ArchivoCertificadoEmpresaEconomia'}
                                    label={'Certificado de Empresa de datos económicos. Debe constar la cuantía de la base de cotización de la trabajadora por  contingencias profesionales, correspondiente al mes anterior al del inicio de la suspensión del contrato de trabajo y, en su caso, las  cantidades de percepción no periódica abonadas a la trabajadora durante el año anterior a la fecha de suspensión del contrato. Asimismo, deberá constar expresamente en la declaración la cotización por realización de horas extraordinarias en el año anterior  al inicio de la suspensión laboral. '}
                                    placeholder={''}
                                    errors={errors}
                                    register={register}
                                    req='El Certificado de Empresa de datos económicos es requerido'
                                    xl={12}
                                    md={12}
                                    setError={setError} />
                                <label className="input-block__label mb-2 mt-4">Trabajadora por cuenta propia: </label>
                                <InputUpload
                                    name={'ArchivoJustificantePagoCuotas'}
                                    id={'ArchivoJustificantePagoCuotas'}
                                    label={'Justificante pago de las cuotas tres últimos meses. '}
                                    placeholder={''}
                                    errors={errors}
                                    register={register}
                                    xl={12}
                                    md={12}
                                    setError={setError} />
                                <InputUpload
                                    name={'ArchivoDeclaracionSituacion'}
                                    id={'ArchivoDeclaracionSituacion'}
                                    label={'Declaración situación de actividad. '}
                                    placeholder={''}
                                    errors={errors}
                                    register={register}
                                    xl={12}
                                    md={12}
                                    setError={setError} />
                                <label className="input-block__label mb-2 mt-4">Artistas y profesionales taurinas: </label>
                                <InputUpload
                                    name={'ArchivoTC46'}
                                    id={'ArchivoTC46'}
                                    label={'Declaración de actividades a la Seguridad Social (Modelo TC 4/6). '}
                                    placeholder={''}
                                    errors={errors}
                                    register={register}
                                    xl={12}
                                    md={12}
                                    setError={setError} />
                                    <label className="input-block__label mb-2 mt-4">Representantes de comercio: </label>
                                <InputUpload
                                    name={'ArchivoJustificantePagoCuotasTrabajadorHogar'}
                                    id={'ArchivoJustificantePagoCuotasTrabajadorHogar'}
                                    label={'Justificante pago cuotas tres últimos meses. '}
                                    placeholder={''}
                                    errors={errors}
                                    register={register}
                                    xl={12}
                                    md={12}
                                    setError={setError} />
                                <label className="input-block__label mb-2 mt-4">Personas integradas en el Régimen Especial de la Seguridad Social de los Empleados de hogar: </label>
                                <InputUpload
                                    name={'ArchivoDeclaracionSituacionHogar'}
                                    id={'ArchivoDeclaracionSituacionHogar'}
                                    label={'Cuando se trate de personas integradas en el Régimen Especial de la Seguridad Social de los Empleados de hogar, se aportará declaración del responsable del hogar familiar sobre la inexistencia de puesto de trabajo compatible con el estado de la trabajadora.'}
                                    placeholder={''}
                                    errors={errors}
                                    register={register}
                                    xl={12}
                                    md={12}
                                    setError={setError} />
                                <label className="input-block__label mb-2 mt-4">Trabajadoras a tiempo parcial: </label>
                                <InputUpload
                                    name={'ArchivoTrabajadorasTiempoParcial'}
                                    id={'ArchivoTrabajadorasTiempoParcial'}
                                    label={'En los casos de trabajadoras a tiempo parcial, deberá reflejarse la cuantía de la base de cotización correspondiente a los tres meses anteriores a la suspensión del contrato.'}
                                    placeholder={''}
                                    errors={errors}
                                    register={register}
                                    xl={12}
                                    md={12}
                                    setError={setError} />
                            </FieldsetContainer>
                        </div>
                    </div>


                    {/* BOTON DE ENVIAR */}
                    <button className='btn-box btn-accent mt-3 mb-3' type="submit" onClick={handleSubmit(onSubmit)}>
                        Enviar
                    </button>

                    <div className='paragraph small text-secondary  mt-4 mb-4'>En cumplimiento de lo establecido en la normativa sobre de Protección de Datos de Carácter Personal, Solimat Mutua Colaboradora con la Seguridad Social nº 72 le informa que sus datos de
                        carácter personal serán objeto de tratamiento, con la finalidad del mantenimiento y cumplimiento de la relación. La base jurídica que legitima el tratamiento de sus datos es el
                        cumplimiento de una obligación legal. Sus datos serán conservados de forma indefinida en nuestra entidad para atender a las posibles responsabilidades derivadas del tratamiento. Usted
                        podrá revocar el consentimiento prestado, así como ejercitar sus derechos de acceso, rectificación, supresión, oposición, limitación del tratamiento, o portabilidad, en su caso,
                        remitiendo un escrito a la siguiente dirección: Solimat, Mutua Colaboradora con la Seguridad Social nº 72, C/ Berna, 1, 45003, Toledo, adjuntando su NIF. Si considera que sus
                        derechos no han sido debidamente atendidos, puede contactar con nuestro delegado de protección de datos lopd@solimat.com o interponer una reclamación frente a la Agencia
                        Española de Protección de Datos.</div>
                </div>


            </FormProvider>

        </>
    )
}

export default SolicitudPrestacionEconomicaREL;
