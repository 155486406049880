import FieldsetContainer from 'components/blocks/Delta/Inputs/Containers/FieldsetContainer';
import React, { useEffect, useState } from 'react'
import { useForm, useFieldArray, Controller } from "react-hook-form";
import InputPattern from '../Inputs/InputPattern';
import InputHora from '../Inputs/InputHora';
import InputChkBox from '../Inputs/InputChkBox';

interface IInputProps {
    control: any;
    register: any;
    name: string;
    errors: any;
    disabled?: boolean;
    cantidad?: number;
    legend: string
    contingenciasProfesionales: boolean
    horasExtra: boolean
    removable?: boolean
    appends?: boolean
}


//Este componente es el que se encarga de renderizar el array de Contingencias
//no es muy reutilable en principio pero sirve de base para otros arrayfields
export const ArrayFieldContingencias: React.FC<IInputProps> = ({ control, name, register, errors, disabled, cantidad, legend, contingenciasProfesionales, horasExtra, removable, appends }) => {




    const { fields, append, remove } = useFieldArray({
        control,
        name: name,

    });

    useEffect(() => {




        const timeout = setTimeout(() => {
            if (fields.length === 0) {

                for (let i = 0; i < cantidad; i++) {
                    append({})
                }
            }

        }, 300);



    }, [])


    return (
        <>
            <div className="container form--simple-styled" >
                <div className="row">
                    <div className="col-xl-12">
                        <FieldsetContainer legend={legend} disabled={disabled}>
                            {fields.map((item, index) => (



                                <>
                                    <InputPattern
                                        id={`${name}.${index}.CotizacionMesAnteriorAnos`}
                                        name={`${name}.${index}.CotizacionMesAnteriorAnos`}
                                        label="Año"
                                        placeholder=""
                                        xl={horasExtra ? 4 : 3}
                                        md={6}
                                        errors={errors}
                                        register={register}
                                        pattern={/^[0-9]*$/}
                                        patternMessage='Solo se aceptan números'

                                    />
                                    <InputPattern
                                        id={`${name}.${index}.CotizacionMesAnteriorMeses`}
                                        name={`${name}.${index}.CotizacionMesAnteriorMeses`}
                                        label="Mes"
                                        placeholder=""
                                        xl={horasExtra ? 4 : 3}
                                        md={6}
                                        errors={errors}
                                        register={register}
                                        pattern={/^[0-9]*$/}
                                        patternMessage='Solo se aceptan números'

                                    />
                                    <InputPattern
                                        id={`${name}.${index}.CotizacionMesAnteriorDias`}
                                        name={`${name}.${index}.CotizacionMesAnteriorDias`}
                                        label="Días Cotizados"
                                        placeholder=""
                                        xl={horasExtra ? 4 : 3}
                                        md={6}
                                        errors={errors}
                                        register={register}
                                        pattern={/^[0-9]*$/}
                                        patternMessage='Solo se aceptan números'

                                    />
                                    <InputPattern
                                        id={`${name}.${index}.CotizacionMesAnteriorContingenciasComunes`}
                                        name={`${name}.${index}.CotizacionMesAnteriorContingenciasComunes`}
                                        label="Base Contingencias Comunes"
                                        placeholder=""
                                        xl={horasExtra ? 4 : 3}
                                        md={6}
                                        errors={errors}
                                        register={register}
                                        pattern={/^[0-9.,'"]*$/}
                                        patternMessage='Solo se aceptan números y decimales'

                                    />
                                    {
                                        contingenciasProfesionales && <InputPattern
                                            id={`${name}.${index}.CotizacionMesAnteriorContingenciasProfesionales`}
                                            name={`${name}.${index}.CotizacionMesAnteriorContingenciasProfesionales`}
                                            label="Base Contingencias Profesionales"
                                            placeholder=""
                                            xl={4}
                                            md={6}
                                            errors={errors}
                                            register={register}
                                            pattern={/^[0-9.,'"]*$/}
                                            patternMessage='Solo se aceptan números y decimales'

                                        />
                                    }

                                    {
                                        horasExtra && <InputPattern
                                            id={`${name}.${index}.CotizacionMesAnteriorHorasExtras`}
                                            name={`${name}.${index}.CotizacionMesAnteriorHorasExtras`}
                                            label="Horas Extras y otros conceptos (año anterior)"
                                            placeholder=""
                                            xl={4}
                                            md={6}
                                            errors={errors}
                                            register={register}
                                            pattern={/^[0-9.,'"]*$/}
                                            patternMessage='Solo se aceptan números y decimales'

                                        />
                                    }
                                    {!removable && <div className='col-12 d-flex justify-content-center mb-3'>
                                        <button id={`btnDelete${index}`} type="button" onClick={() => remove(index)} className=' btn-box btn-secondary  '>
                                            Borrar Cotizacion
                                        </button>
                                    </div>
                                    }


                                    <div className=" border-bottom border-dark"></div>

                                </>


                            ))}
                            {
                                !appends && <button
                                    className="mt-2 btn-box btn-accent"
                                    type="button"
                                    disabled={disabled}
                                    onClick={() => append({})}
                                >Añadir Cotizacion</button>
                            }

                        </FieldsetContainer>



                    </div>
                </div>
            </div>
        </>
    )
}

export default ArrayFieldContingencias;
