import React, { useEffect, useState } from "react";
import { useHistory, useLocation } from "react-router-dom";
import useExportPat from "../../../services/delta/pat/useExportPat";
import { ExportPat } from "@solimat/solimat-web-endpoint/lib/Delta/dto";
import {
  ColumnDirective,
  ColumnsDirective,
  GridComponent,
  ToolbarItems,
  SortSettingsModel,
  PdfExportProperties,
} from "@syncfusion/ej2-react-grids";
import {
  PdfFontFamily,
  PdfFontStyle,
  PdfStandardFont,
} from "@syncfusion/ej2-pdf-export";
import {
  Grid,
  Inject,
  PdfExport,
  ExcelExport,
  Toolbar,
  Page,
  PageSettingsModel,
  Sort,
} from "@syncfusion/ej2-react-grids";
import Moment from "moment";
import downloadXml from "services/delta/pat/useDownloadXml";
import useGetRole from "hooks/useGetRole";
// DOCUMENTATION https://ej2.syncfusion.com/react/documentation/grid/pdf-export/

interface IProps {
  arrData: any;
}

const GridDeltaAccidentWithLeave: React.FC<IProps> = ({ arrData }) => {
  const history = useHistory();
  const location = useLocation();
  let grid: Grid | null = new Grid();
  const toolbar: ToolbarItems[] = ["PdfExport", "ExcelExport"];
  const [formatedArrData, setFormatedArrData] = useState<string[]>([]);
  const [arrayRemesa, setarrayRemesa] = useState([]);
  const [handleRowClick, setHandleRowClick] = useState(true);
  const { exportPat } = useExportPat();
  const section = location?.pathname.includes("remesados");
  const [isRemitted, setIsRemitted] = useState(false)

  const { isImpersonation } = useGetRole;

  useEffect(() => {
    setIsRemitted(location.pathname.includes('remesados'));
    if (arrData) {
      const formatedArrData = arrData.map((pat: any) => {
        return {
          ...pat,
          TipoDelta: checkTypePAT(pat.TipoDelta),
          Estado: checkStatusPAT(pat.Estado),
          formatted_accident_date:
            pat.FechaAccidente !== null
              ? Moment(new Date(pat.FechaAccidente)).format("L")
              : "",
          formatted_leaving_date:
            pat.FechaBaja !== null
              ? Moment(new Date(pat.FechaBaja)).format("L")
              : "",
        };
      });
      setFormatedArrData(formatedArrData);
    }
  }, [arrData, arrayRemesa]);

  const checkStatusPAT = (status: number) => {
    switch (status) {
      case 0:
        return "Nuevo";
      case 1:
        return "Actualizado";
      case 2:
        return "Verificado";
      case 3:
        return "Exportado";
      case 4:
        return "Success";
      case 5:
        return "Error";
      default:
        return "";
    }
  };

  const checkTypePAT = (type: number) => {
    switch (type) {
      case 1:
        return "Accidente";
      case 2:
        return "Recaida";
      default:
        return "";
    }
  };

  const toolbarClick = (args: any) => {
    if (grid && args.item.id === "grid_pdfexport") {
      const exportProperties: PdfExportProperties = {
        fileName: `Grid_PDF_${args.item.id}.pdf`,
        theme: {
          caption: { font: new PdfStandardFont(PdfFontFamily.TimesRoman, 9) },
          header: {
            font: new PdfStandardFont(
              PdfFontFamily.TimesRoman,
              11,
              PdfFontStyle.Bold
            ),
          },
          record: { font: new PdfStandardFont(PdfFontFamily.TimesRoman, 10) },
        },
        header: {
          contents: [
            {
              position: { x: 0, y: 24 },
              style: { textBrushColor: "#3A5FA1", fontSize: 13 },
              type: "Text",
              value: `${args.item.id}`,
            },
            {
              type: "Line",
              style: { penColor: "#172E56", penSize: 2, dashStyle: "Solid" },
              points: { x1: 0, y1: 4, x2: 685, y2: 4 },
            },
          ],
          fromTop: 0,
          height: 80,
        },
        footer: {
          contents: [
            {
              format: "Pág. {$current} de {$total}",
              pageNumberType: "Arabic",
              position: { x: -0, y: 0 },
              style: {
                fontSize: 12,
                textBrushColor: "#409CCE",
              },
              type: "PageNumber",
            },
          ],
          fromBottom: 24,
          height: 32,
        },
        pageOrientation: "Landscape",
      };
      grid.pdfExport(exportProperties);
    }
    if (grid && args.item.id === "grid_excelexport") {
      grid.excelExport();
    }
  };

  const pdfHeaderQueryCellInfo = (args: any) => {
    (args.cell as any).row.pdfGrid.repeatHeader = true;
  };

  const sortingOptions: SortSettingsModel = {
    columns: [{ field: "NumeroReferencia", direction: "Ascending" }],
  };

  const pageOptions: PageSettingsModel = {
    pageSize: 10,
    pageSizes: ["5", "10", "15", "20", "Todos"],
  };

  const handleEdit = (id: any) => {
    const section = location?.pathname.includes("remesados")
      ? "accidentes-con-baja-delta-remesados"
      : "accidentes-con-baja-pendientes-de-delta";

    history.push({
      pathname: `/unidad-delta/${section}/${id}`,
    });
  };

  const handleRemit = (id: string) => {
    if (arrayRemesa.includes(id)) {
      let miNewArr = arrayRemesa.filter((item) => item !== id);
      setarrayRemesa([...miNewArr]);
    } else {
      arrayRemesa.push(id);
      setarrayRemesa([...arrayRemesa]);
    }
  };
  
  const downloadXml = (data: any, filename = 'default.xml') => {
    const blob = new Blob([data], { type: 'application/xml' });
    const link = document.createElement('a');
    link.href = window.URL.createObjectURL(blob);
    link.download = filename;
    link.click();
  };
  
  const parseDate = (dateString:any) => {
    const day = dateString.slice(0, 2);
    const month = dateString.slice(2, 4);
    const year = dateString.slice(4, 8);
    return new Date(`${year}-${month}-${day}`);
  };
  
  const extractFilenameFromXml = (xmlString:any) => {
    const parser = new DOMParser();
    const xmlDoc = parser.parseFromString(xmlString, "application/xml");
    const ipf = xmlDoc.getElementsByTagName("ipf")[0].textContent;
    let fechaaccidente = xmlDoc.getElementsByTagName("fechaaccidente")[0].textContent;
      const date = parseDate(fechaaccidente);
    if (isNaN(date.getTime())) {
      throw new Error('Invalid date format');
    }
    fechaaccidente = date.toISOString().split('T')[0];
  
    return `pre_delta_con_baja_${ipf}_${fechaaccidente}.xml`;
  };
  
  const handleOnClickRemit = async () => {
    let exportIds = {
      ids: arrayRemesa,
    };
  
    let response = await exportPat(exportIds).then((response) => {
      try {
        const responseText = response && response;
        const filename = extractFilenameFromXml(responseText);
        downloadXml(responseText, filename);
      } catch (error) {
        console.log(error);
      }
    });
  };
  
  
  

  return (
    <> {
      !isRemitted ?
        <div className="container">
          <GridComponent
            id="grid"
            dataSource={formatedArrData}
            toolbar={toolbar}
            allowPdfExport={true}
            allowExcelExport={true}
            toolbarClick={toolbarClick}
            pdfHeaderQueryCellInfo={pdfHeaderQueryCellInfo}
            ref={(g) => (grid = g)}
            allowPaging={true}
            allowSorting={false}
            pageSettings={pageOptions}
            sortSettings={sortingOptions}
            width="100%"
            enablePersistence={true}
          >
            <ColumnsDirective>
              {!isImpersonation() &&
                <ColumnDirective
                  textAlign="Center"
                  headerText="remesar"
                  width="100"
                  template={(props: any) => (
                    <input
                      type="checkbox"
                      checked={arrayRemesa.includes(props.Id)}
                      onChange={() => handleRemit(props.Id)}
                      data-id={props.Id}
                      disabled={!props.Validacion ? true : false}
                    />
                  )}
                />
              }
              <ColumnDirective
                headerText="Editar"
                textAlign="Center"
                width="100"
                template={(props: any) => (
                  <span
                    className="edit-delta-icon"
                    onClick={() => handleEdit(props.Id)}
                  ></span>
                )}
              />
              <ColumnDirective
                field="formatted_accident_date"
                textAlign="Left"
                headerText="F. Accidente"
                width="150"
              />
              <ColumnDirective
                field="formatted_leaving_date"
                textAlign="Left"
                headerText="F. Baja"
                width="150"
              />
              <ColumnDirective
                field="IPF"
                textAlign="Left"
                headerText="Dni/Nie"
                width="150"
              />
              <ColumnDirective
                field="Nombre"
                textAlign="Left"
                headerText="Nombre"
                width="150"
              />
              <ColumnDirective
                field="Apellido1"
                textAlign="Left"
                headerText="Apellido"
                width="150"
              />
              <ColumnDirective
                field="Apellido2"
                textAlign="Left"
                headerText="Segundo Apellido"
                width="150"
              />
              <ColumnDirective
                field="CCC"
                textAlign="Left"
                headerText="CCC"
                width="150"
              />
              <ColumnDirective
                field="TipoDelta"
                textAlign="Left"
                headerText="Tipo"
                width="150"
              />

              {/* <ColumnDirective
        field="Estado"
        textAlign="Left"
        headerText="Aceptado/Rechazado"
        width="150"
      /> */}
              <ColumnDirective
                field="Validacion"
                textAlign="Center"
                headerText="Validación"
                width="150"
                template={(props: any) => (
                  <span
                    className={
                      props.Validacion
                        ? "check-in-delta-icon"
                        : "check-out-delta-icon"
                    }
                  ></span>
                )}
              />
              <ColumnDirective
                field="Remesado"
                textAlign="Center"
                headerText="Remesado"
                width="150"
                template={(props: any) => (
                  <span
                    className={
                      props.Remesado
                        ? "remesado-delta-icon"
                        : "no-remesado-delta-icon"
                    }
                  ></span>
                )}
              />
            </ColumnsDirective>

            <Inject services={[Toolbar, PdfExport, ExcelExport, Page, Sort]} />
          </GridComponent>
          {!section && !isImpersonation() ? (
            <div className=" container container-btn-remesar">
              <button
                className="btn-remesar btn-box btn-accent loading"
                disabled={
                  arrayRemesa.length <= 0 || arrayRemesa[0].length === 0
                    ? true
                    : false
                }
                onClick={() => handleOnClickRemit()}
              >
                Remesar
              </button>
            </div>
          ) : (
            ""
          )}
        </div>

        :
        <div className="container">
          <GridComponent
            id="grid"
            dataSource={formatedArrData}
            toolbar={toolbar}
            allowPdfExport={true}
            allowExcelExport={true}
            toolbarClick={toolbarClick}
            pdfHeaderQueryCellInfo={pdfHeaderQueryCellInfo}
            ref={(g) => (grid = g)}
            allowPaging={true}
            allowSorting={false}
            pageSettings={pageOptions}
            sortSettings={sortingOptions}
            width="100%"
          >
            <ColumnsDirective>

              <ColumnDirective
                headerText="Ver"
                textAlign="Center"
                width="100"
                template={(props: any) => (
                  <span
                    className="edit-delta-icon"
                    onClick={() => handleEdit(props.Id)}
                  ></span>
                )}
              />
              <ColumnDirective
                field="IPF"
                textAlign="Left"
                headerText="Dni/Nie"
                width="150"
              />
              <ColumnDirective
                field="Nombre"
                textAlign="Left"
                headerText="Nombre"
                width="150"
              />
              <ColumnDirective
                field="Apellido1"
                textAlign="Left"
                headerText="Apellido"
                width="150"
              />
              <ColumnDirective
                field="Apellido2"
                textAlign="Left"
                headerText="Segundo Apellido"
                width="150"
              />
              <ColumnDirective
                field="CCC"
                textAlign="Left"
                headerText="CCC"
                width="150"
              />
              <ColumnDirective
                field="TipoDelta"
                textAlign="Left"
                headerText="Tipo"
                width="150"
              />
              <ColumnDirective
                field="formatted_accident_date"
                textAlign="Left"
                headerText="F. Accidente"
                width="150"
              />
              <ColumnDirective
                field="formatted_leaving_date"
                textAlign="Left"
                headerText="F. Baja"
                width="150"
              />
              <ColumnDirective
                field="Estado"
                textAlign="Left"
                headerText="Aceptado/Rechazado"
                width="150"
              />

            </ColumnsDirective>

            <Inject services={[Toolbar, PdfExport, ExcelExport, Page, Sort]} />
          </GridComponent>
          {!section ? (
            <div className=" container container-btn-remesar">
              <button
                className="btn-remesar btn-box btn-accent loading"
                disabled={
                  arrayRemesa.length <= 0 || arrayRemesa[0].length === 0
                    ? true
                    : false
                }
                onClick={() => handleOnClickRemit()}
              >
                Remesar
              </button>
            </div>
          ) : (
            ""
          )}
        </div>

    }

    </>
  );
};

export default GridDeltaAccidentWithLeave;
