import React, { useState } from "react";
import IconDownload from '../../../img/icons/symbols/expediente.png'

import {
  GridComponent,
  ColumnsDirective,
  ColumnDirective,
  Inject,
  Page,
  Sort,
  PageSettingsModel,
} from "@syncfusion/ej2-react-grids";

import Loader from "../Loader/Loader";
export interface IImagesProps {

  images?:any[] |undefined;
  handleRequestImage: (Id: string, name:string,e:any) =>any;
  loadingImage:boolean
}

export interface IImage {
  nombre: string;
  extension: string;
  company: string;
  fecha: Date;
  requiereOtp: boolean;
  uri: string;
}

const GridEpisodeImages: React.FC<IImagesProps> = ({
  images,
  handleRequestImage,
  loadingImage
}) => {

  const [pageOptions] = useState<PageSettingsModel>({
    pageSize: 10,
    pageSizes: ["5", "10", "15", "20", "Todos"],
  });
  const [sortingOptions] = useState<Object>({
    columns: [{ field: "fecha", direction: "Descending" }],
  });

  const gridTemplate = (props: any): any => {
    const handleDownload = (e: any) => {
      e.preventDefault();
      handleRequestImage(props.Id, props.Descripcion, e);
    };

    return (
      <div className="link-document">
        <a
          className="link-bold"
          onClick={handleDownload}
          
        >
          <img style={{width:'20px', height:'20px'}} src={IconDownload} alt="descargar" />
          </a>
      </div>
    );
  };
  const template: any = gridTemplate;
  return (
    <div>
 {loadingImage ?
 
<Loader activeLoader label='Descargando Imagen'></Loader>
:  
<GridComponent
dataSource={images}
// allowPaging={images.length >= 5 ? true : false}
pageSettings={pageOptions}
sortSettings={sortingOptions}
enableHover={false}
allowSelection={false}
enablePersistence={true}
>
<ColumnsDirective>
<ColumnDirective
    field="uri"
    headerText=""
    template={template}
    width="30"
  />

  <ColumnDirective
    field="Descripcion"
    headerText="Descripcion"
    type="text"
    width="50"
  />
 
</ColumnsDirective>
<Inject services={[Page, Sort]} />
</GridComponent>

}
      
    </div>
  );
};

export default GridEpisodeImages;
