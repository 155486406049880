import React from 'react'


interface IInputProps {
  name: string,
  id: string,
  label: string,
  placeholder?: string,
  errors: any,
  register: any,
  readonly?: boolean,
  defaultValue?: string
  disabled?: boolean
  maxLength?: number,
  handleOnChange?: (e: React.ChangeEvent<HTMLInputElement>) => void;
  value?: any,
  isvalidateNumber?: boolean,
  isvalidateNumberPostalCode?: boolean,
  req?: string
  resizable?: boolean
  rows?: number
  colums?: number
}
export const TextAreaDefault: React.FC<IInputProps> = ({ name, id, label, placeholder, errors, register, readonly, defaultValue, disabled, maxLength, req, resizable, rows, colums }) => {
  return (
    <>
      <div className={`input-block ${errors && errors[name]?.message ? 'error' : ''}`}>
        <label htmlFor={id} className="input-block__label">{label}</label>
        <textarea
          name={name}
          id={id}
          placeholder={placeholder}
          {...register(name, { required: req })}
          readOnly={readonly || false}
          disabled={disabled || false}
          defaultValue={defaultValue || ''}
          maxLength={maxLength}
          rows={rows}
          colums={colums}
          style={{
            resize: resizable ? 'both' : 'none'
          }}
        >

        </textarea>

        {errors && errors ?
                <p className="error m-0 ">
                    {errors && errors[name]?.message}
                </p>
                : ''}
      </div>
    </>

  )
}

export default TextAreaDefault;