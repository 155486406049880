import React, { useState, useEffect, Dispatch } from "react";
import Moment from "moment";
import { guid } from "@solimat/solimat-web-endpoint/lib/user/dto";
import PageMenu from "../../blocks/PageMenu/PageMenu";
import Modal from "components/blocks/Modal/Modal";
import TableEpisode from "../../blocks/TableAppointment/TableEpisode";
import FormValidateOTP from "components/blocks/Forms/FormValidateOTP";
import IconArrowWhite from "../../../img/icons/svg/botones/flecha-big-blanco.svg";
import useGetEpisode from "../../../services/recordSheets/useGetEpisode";
import useCheckTypes from "../../../hooks/useCheckRecordStatusAndTypes";
import useModal from "hooks/useModal";
import IMGnotice from "../../../img/icons/symbols/notice.png";
import "./EpisodeDetail.scss";
import useDecode from "hooks/useDecode";
import useGetEpisodeImage from "services/recordSheets/useGetEpisodeImage";
// import FormAnswerCommunication from "components/blocks/Forms/FormAnswerCommunication";
// import  from "components/blocks/Forms/";
import MapGoogle2 from "components/blocks/MapGoogle/MapGoogle2";
import HealthcareDocumentsGrid from "components/blocks/Grids/HealthcareDocumentsGrid";
import useGetRequestDocumentOtp from "services/record/useGetRequestDocumentOtp";
import useGetDocumentstByEpisodeId from "services/record/useGetDocumentstByEpisodeId ";
import useGetAllHealthcareDocument from "services/record/useGetAllHealthcareDocument";
import LoaderMin from "components/blocks/Loader/LoaderMin";
import GridEpisodeImages from "components/blocks/Grids/GridEpisodeImages";
import useGetImage from "services/recordSheets/useGetImage";

const EpisodeDetail: React.FC = (props: any) => {
  const prevNavigation = props.location.origin;
  const paramsId = props.match.params.id;
  const arrParamsId = paramsId.split("_");
  const recordId = arrParamsId[1];
  const contingencyType = arrParamsId[2];
  const episodeType: string = props.match.params.episodeType;
  const episodeId: string = props.match.params.episodeId;
  const [episode, setEpisode] = useState<any | null>(null);
  const [documentID, setDocumentID] = useState<string | guid>("");
  const tokenResponse = useDecode("token");
  const userId = tokenResponse && tokenResponse.extension_id;
  const [breadcrumbs, setBreadcrumbs] = useState<string[]>([]);
  const [imagen, setimagen] = useState("");
  const sesionId = props.match.params.episodeId;
  const episodeIdReha = props.location.episodeId;
  const [loadingImage, setLoadingImage] = useState(false);
  const [errorImage, setErrorImage] = useState('')
  const [openModalError, setOpenModalError] = useState(true)
  const {
    collapsed,
    childrenProps,
    handleOpenModal,
    handleCloseModal,
    disabledCloseButton,
    handleDisableCloseButton,
  } = useModal();
  const { dataImage, getImagesbyId, successGetImage } = useGetImage();
  const {
    episodeImage,
    errorGetEpisodeImage,
    getEpisodeImage,
    loadingGetEpisodeImage,
    successGetEpisodeImage,
  } = useGetEpisodeImage();
  const { checkEpisodeType, checkRecordType, checkContingencyType } =
    useCheckTypes();
  const {
    getVisit,
    getRehabilitation,
    getHospitalization,
    getOperationRoom,
    loadingGetEpisode,
    getEmergencies,
    dataEpisode,
    getEpisodeImagesbyId,
  } = useGetEpisode();
  const { documentsByEpisodeId, getDocumentstByEpisodeId } =
    useGetDocumentstByEpisodeId();
  const { getRequestDocumentOtp, errorGetRequestDocumentOtp } =
    useGetRequestDocumentOtp();

  useEffect(() => {
    handleGetEpisode(episodeType, setEpisode); // getEpisode
  }, []);

  useEffect(() => {
    if (dataEpisode !== null) {
      setEpisode(dataEpisode);
    }
  }, [dataEpisode]);

  const handleGetEpisode = (episodeType: string, _cb: Dispatch<any>) => {
    switch (episodeType) {
      case "202": //Hospitalizacion
        return getHospitalization(userId, recordId, episodeId, _cb);
      case "204": // Quirófano
        return getOperationRoom(userId, recordId, episodeId, _cb);
      case "205": //Rehabilitación
        return getRehabilitation(userId, recordId, episodeIdReha, sesionId);
      case "200": //consulta
        return getVisit(userId, recordId, episodeId, _cb);
      case "206": //urgencias
        return getEmergencies(userId, recordId, episodeId, _cb);
      case "203": //IMAGEN
        return getEpisodeImagesbyId(userId, recordId, episodeId, _cb);
    }
  };

  const [breadcrumbsParams, setBreadcrumbsParams] = useState<null | any[]>(
    null
  );
  useEffect(() => {
    // AFTER SET EPISODE
    if (episode !== null) {
      getDocumentstByEpisodeId(userId, recordId, episode.episodioId);
      let episodeType2 = checkEpisodeType(parseInt(episodeType));
      let contingencyTypeBreadCrumbs = checkContingencyType(
        parseInt(contingencyType)
      );
      setBreadcrumbs([
        "Historial",
        contingencyTypeBreadCrumbs,
        episodeType2,
        "Detalle",
      ]);
      setBreadcrumbsParams([
        {},
        {},
        {
          record: {},
          filter: {
            type_id: episodeType,
            type: "Consultas",
          },
        },
      ]);
    }
  }, [episode, dataImage]);

  const handleGoBack = () => {
    props.history.push({
      pathname: `/historial/${props.match.params.id}/informacion-asistencial`,
      record: {},
      filter: {
        type_id: episodeType,
        type: "Consultas",
      },
    });
  };
  const handleRequestDocument = (documentID: string) => {
    setDocumentID(documentID);
    handleOpenModal();
    getRequestDocumentOtp(userId, recordId, documentID);
  };
  const handleRequestImage = async (id: string, name: string, e: any) => {
    e.preventDefault();
    setDocumentID(id);
    setLoadingImage(true);

    try {
      const miImagen2 = await getImagesbyId(id);
      if (miImagen2) {
        const blob = new Blob([miImagen2], { type: "image/jpeg" });
        const url = window.URL.createObjectURL(blob);
        const a = document.createElement("a");
        a.href = url;
        // a.target = '_blank';
        a.download = `${name}.jpg`;
        a.click();
        window.URL.revokeObjectURL(url);
        setLoadingImage(false);
      } else {
        console.error("La variable dataImage está vacía.");
      }
    } catch (error) {
      setLoadingImage(false);
      setErrorImage("Error al obtener la imagen:")
      setOpenModalError(false)
      // console.error("Error al obtener la imagen:", error);
    }
  };

  // REDIRECT TO GOOGLE MAPS
  const redirect = (latitud: number, longitud: number) => {
    window.open(`https://maps.google.com/?q=${latitud},${longitud}`);
  };

  const formatAddress = (address: any) => {
    const {
      tipoCalle,
      nombreCalle,
      numeroCalle,
      piso,
      puerta,
      letra,
      bloque,
      codigoPostal,
      provincia,
      localidad,
    } = address && address;
    let formatedAddress: string = "";
    let formatedStreet: string = "";
    if (nombreCalle) {
      if (tipoCalle) formatedStreet += tipoCalle;
      formatedStreet += ` ${nombreCalle}`;
    }
    if (formatedStreet) {
      formatedAddress += formatedStreet;
      numeroCalle
        ? (formatedAddress += `, número ${numeroCalle} `)
        : (formatedAddress += ", - ");
      if (piso) formatedAddress += `, piso ${piso}`;
      if (puerta) formatedAddress += `, puerta ${puerta}`;
      if (letra) formatedAddress += `, letra ${letra}`;
      if (bloque) formatedAddress += `, bloque ${bloque}`;
    }

    if (formatedStreet && codigoPostal) {
      formatedAddress += `, C.P. ${codigoPostal}`;
    } else if (!formatedStreet && codigoPostal) {
      formatedAddress += `C.P. ${codigoPostal}`;
    }

    if ((formatedStreet || codigoPostal) && localidad) {
      formatedAddress += ` - ${localidad}`;
    } else if (!formatedStreet && !codigoPostal && !provincia && localidad) {
      formatedAddress += `${localidad}`;
    }

    if ((formatedStreet || codigoPostal || localidad) && provincia) {
      formatedAddress += `, ${provincia}`;
    } else if ((!formatedStreet || !codigoPostal) && provincia) {
      formatedAddress += `${provincia}`;
    }

    return formatedAddress;
  };

  if (loadingGetEpisode) {
    return (
      <section className="container">
        {/* <p className="loader-text">Cargando...</p> */}
        <LoaderMin />
      </section>
    );
  }

  return (
    <>
      {episode !== null ? (
        <>
          <PageMenu
            title={episode !== null ? episode.especialidad : ""}
            type="photo"
            breadcrumbs={breadcrumbs}
            params={breadcrumbsParams}
          >
            <div className="btns-header-container">
              <button
                onClick={
                  typeof prevNavigation === "string"
                    ? handleGoBack
                    : () => props.history.goBack()
                }
              >
                <img src={IconArrowWhite} alt="icon arrow right" />
              </button>
            </div>
          </PageMenu>

          <section className="container">
            <div className="row">
              <div className="col-12">
                <h2 className="section-title">Información del episodio</h2>
              </div>
              <div className="col-lg-12">
                {episode !== null && (
                  <TableEpisode
                    episode={episode}
                    episodeType={parseInt(episodeType)}
                  />
                )}
              </div>
              <div className="col-lg-12 map-appointment-container">
                <MapGoogle2
                  latitudeCenter={parseFloat(episode?.centro?.latitud?.replace(",", "."))}
                  longitudeCenter={parseFloat(episode?.centro?.longitud?.replace(",", "."))}
                  redirect={redirect}
                  zoom={18}
                  title={episode?.centro?.descripcion}
                />
              </div>
            </div>
          </section>
          <section className="container p-0">
            <div className="row mt-4">
              <div className="col-sm-12 col-md-12 col-lg-12 ">
                <div className="col-12">
                  <h2 className="section-title">Detalles del centro</h2>
                  {episode !== null && (
                    <ul className="episode-info__list">
                      <li>
                        <span className="header">Dirección:</span>
                        <span className="data">
                          {formatAddress(episode && episode.centro)}
                        </span>
                      </li>
                      <li>
                        <span className="header">Teléfono de contacto:</span>
                        <span className="data">
                          <a href={`tel:${episode?.centro?.telefono}`}>
                            {episode?.centro?.telefono}
                          </a>
                        </span>
                      </li>
                    </ul>
                  )}
                </div>

                <div className="col-12 col-md-12 col-lg-12">
                  <h2 className="section-title mt-4 ">Datos asistenciales</h2>
                  <div className="col-lg-12 p-0">
                    {episode !== null ? (
                      <div>
                        <ul className="episode-info__list">
                          {parseInt(episodeType) !== 200 &&
                            parseInt(episodeType) !== 205 &&
                            parseInt(episodeType) !== 203 ? (
                            <>
                              <li>
                                <span className="header">
                                  Diagnóstico CIE9:
                                </span>
                                <span className="data">
                                  {episode?.diagCIE9Principal
                                    ? episode?.diagCIE9Principal
                                    : ""}
                                </span>
                              </li>
                              <li>
                                <span className="header">
                                  Diagnóstico CIE10:
                                </span>
                                <span className="data">
                                  {episode?.diagCIE10Principal
                                    ? episode?.diagCIE10Principal
                                    : ""}
                                </span>
                              </li>
                            </>
                          ) : (
                            ""
                          )}
                          {/* IMAGEN */}

                          {parseInt(episodeType) === 203 && (
                            <>
                              <li>
                                <span className="header">
                                  Nombre del doctor:
                                </span>
                                <span className="data">
                                  {episode?.nombreFacultativo}
                                </span>
                              </li>
                            </>
                          )}
                          {/* CONSULTA */}
                          {parseInt(episodeType) === 200 && (
                            <>
                              <li>
                                <span className="header">
                                  Nombre del doctor:
                                </span>
                                <span className="data">
                                  {episode?.nombreFacultativo}
                                </span>
                              </li>
                              <li>
                                <span className="header">Area lesionada:</span>
                                <span className="data">{episode?.motivo}</span>
                              </li>
                              <li>
                                <span className="header">Mecanismo de lesión:</span>
                                <span className="data">
                                  {episode?.problema}
                                </span>
                              </li>
                            </>
                          )}
                          {/* REHABILITACIÓN */}
                          {parseInt(episodeType) === 205 && (
                            <>
                              <li>
                                <span className="header">
                                  Rehabilitación Finalizada{" "}
                                </span>
                                <span className="data">
                                  {episode.finalizadaRehabilitacion
                                    ? "Si"
                                    : "No"}
                                </span>
                              </li>
                              <li>
                                <span className="header">Número de serie:</span>
                                <span className="data">
                                  {episode.numeroSerie}
                                </span>
                              </li>
                              <li>
                                <span className="header">Doctor:</span>
                                <span className="data">
                                  {episode.nombreAsistencial}
                                </span>
                              </li>
                              {/* <li>
                                <span className="header">Observaciones:</span>
                                <span className="data">
                                  {episode.observaciones}
                                </span>
                              </li> */}
                            </>
                          )}
                          {/* QUIRÓFANO */}
                          {parseInt(episodeType) === 204 && (
                            <>
                              <li>
                                <span className="header">Cirujano:</span>
                                <span className="data">{episode.cirujano}</span>
                              </li>
                              <li>
                                <span className="header">Anestesista:</span>
                                <span className="data">
                                  {episode.anestesista}
                                </span>
                              </li>
                            </>
                          )}
                          {/* HOSPITALIZACIÓN */}
                          {parseInt(episodeType) === 202 && (
                            <>
                              <li>
                                <span className="header">
                                  Fecha alta hospitalaria:
                                </span>
                                <span className="data">
                                  {episode?.FechaHoraFin != null
                                    ? Moment(
                                      new Date(
                                        episode?.FechaHoraFin?.toString()
                                      )
                                    ).format("L")
                                    : ""}
                                </span>
                              </li>
                              <li>
                                <span className="header">
                                  Días de estancia:
                                </span>
                                <span className="data">
                                  {episode.diasEstancia}
                                </span>
                              </li>
                              <li>
                                <span className="header">
                                  Descripcion Servicio:
                                </span>
                                <span className="data">
                                  {episode.servicioDescripcion}
                                </span>
                              </li>

                              <li>
                                <span className="header">Doctor:</span>
                                <span className="data">
                                  {episode.medicoResponsable}
                                </span>
                              </li>
                            </>
                          )}
                          {/* URGENCIAS */}
                          {parseInt(episodeType) === 206 && (
                            <>
                              <li>
                                <span className="header">Especialidad:</span>
                                <span className="data">
                                  {episode.especialidad}
                                </span>
                              </li>
                              <li>
                                <span className="header">
                                  Nombre del doctor:
                                </span>
                                <span className="data">
                                  {episode.nombreFacultativo}
                                </span>
                              </li>
                              <li>
                                <span className="header">
                                  Descripcion Servicio:
                                </span>
                                <span className="data">
                                  {episode.servicioDescripcion}
                                </span>
                              </li>
                            </>
                          )}
                          {episode.document && (
                            <li className="d-flex">
                              <span className="header">Hoja de visita:</span>
                              <a
                                className="d-flex btn-text-icon"
                                onClick={() =>
                                  handleRequestDocument(episode.document.id)
                                }
                              >
                                <span className="data btn-text mr-1">
                                  {episode.document.description}
                                </span>
                                <span className="download-icon"></span>
                              </a>
                            </li>
                          )}
                        </ul>
                        <Modal
                          disabledCloseButton={disabledCloseButton}
                          collapsed={collapsed}
                          onClose={handleCloseModal}
                          title={"Introduzca el código"}
                          subtitle={
                            "Para visualizar el documento, introduzca el código que hemos enviado a su teléfono"
                          }
                        >
                          {collapsed ? (
                            <div className="ghost-form"></div>
                          ) : (
                            <>
                              {documentsByEpisodeId ? (
                                <FormValidateOTP
                                  handleDisableCloseButton={
                                    handleDisableCloseButton
                                  }
                                  onClose={handleCloseModal}
                                  props={props}
                                  userId={userId}
                                  prestacionId={recordId}
                                  documentID={documentID}
                                />
                              ) : (
                                <>
                                  {errorGetRequestDocumentOtp ? (
                                    <div>
                                      <h3 className="modal_title">Error</h3>
                                      <p className="c-center">
                                        Lo sentimos, se ha producido un error
                                      </p>
                                    </div>
                                  ) : (
                                    <p className="loader-text c-center">
                                      Loading...
                                    </p>
                                  )}
                                </>
                              )}
                            </>
                          )}
                        </Modal>
                      </div>
                    ) : (
                      <p>- No hay observaciones para esta cita</p>
                    )}
                  </div>
                </div>
              </div>
              {parseInt(episodeType) === 203 ? (
                <GridEpisodeImages
                  handleRequestImage={handleRequestImage}
                  images={episode.referenciasImagenes}
                  loadingImage={loadingImage}
                ></GridEpisodeImages>
              ) : (
                <div className="col-sm-12 col-md-12 col-lg-12 record-container-documents">
                  <h2 className="section-title">Documentos de consulta</h2>
                  {!documentsByEpisodeId ? (
                    <div className="container-without-documents">
                      <p className="without-documents">
                        No hay disponible documentación
                      </p>
                    </div>
                  ) : (
                    <div>
                      <HealthcareDocumentsGrid
                        handleRequestDocument={handleRequestDocument}
                        documents={documentsByEpisodeId.map((doc: any) => {
                          return {
                            nombre: doc.nombre,
                            extension: doc.extension,
                            company: doc.company ?? "-",
                            fecha: doc.fecha,
                            id: doc.id,
                            uri: doc.uri,
                            requiereOtp: doc.requiereOtp,
                          };
                        })}
                      />
                    </div>
                  )}
                </div>
              )}
            </div>
          </section>
          {/* {(isPending ||(!isPending && userChatCommunication && userChatCommunication )) && (
        <section className="container">
          <div className="row">
            <div className="col-12">
              <h2 className="section-title">Comentarios</h2>
            </div>
            {errorGetUserChatCommunication && (
              <section>{TextMessagesTemplates.errorResponse}</section>
            )}
            {!userChatCommunication || loadingGetUserChatCommunication ? (
              <section>{TextMessagesTemplates.loading}</section>
            ) : (
              <>
                {userChatCommunication ? (
                  <div className="col-12">
                    {isPending && (
                      <div className="btns-upon-box-container">
                        <button
                          className="btn-text btn-gray"
                          onClick={handleOpenModal}
                        >
                          <img src={IconCommentGray} alt="icon reply" />
                          Responder
                        </button>
                      </div>
                    )}
                    <Message userChatCommunication={userChatCommunication && userChatCommunication} userId= {appointment.userId} />
                  </div>
                ) : (
                  <>
                    {isPending && (
                      <div className="col-md-6 offset-md-3">
                        <CommunicationsBlock
                          handleOpenModal={handleOpenModal}
                          handleCloseModal={handleCloseModal}
                          collapsed={collapsed}
                        />
                      </div>
                    )}
                  </>
                )}
              </>
            )}
            {errorCreateUserCommunication && (
              <Modal collapsed={messageCollpased} onClose={handleCloseMessage}>
                <div className="confirm-message">
                  <div className="img-container">
                    <img src={IMGnotice} alt="" />
                  </div>
                  <p>
                    Lo sentimos, se ha producido un error interno. Inténtelo de
                    nuevo más tarde, gracias.
                  </p>
                  <div className="btn-resolve-container">
                    <button
                      className="btn-box btn-accent"
                      onClick={handleCloseMessage}
                    >
                      Entendido
                    </button>
                  </div>
                </div>
              </Modal>
            )}
            <Modal collapsed={collapsed} onClose={handleCloseModal}>
              <>
                {!collapsed ? (
                  <>
                    {userChatCommunication ? (
                      <FormAnswerCommunication
                        title={"Responder"}
                        onSubmit={onSubmitReply}
                        onClose={handleCloseModal}
                      />
                    ) : (
                      <
                        title={"Crear comunicación"}
                        onSubmit={onSubmitCommunication}
                        onClose={handleCloseModal}
                      />
                    )}
                  </>
                ) : (
                  <div className="ghost-form"></div>
                )}
              </>
            </Modal>
          </div>
        </section>
      )} */}
        </>
      ) : (
        <></>
      )}
      {errorImage ? <Modal collapsed={openModalError} title='Error al descargar imagen'>
        <div className="error-download-img">
          <img src={IMGnotice} style={{ marginBottom: '40px' }} alt="" />
        </div>
        <div className=" container btns-container container-btn-close-error-img">
          <button
            type="button"
            className="btn-box btn-accent align-self-end"
            onClick={() => setOpenModalError(true)}
          >
            Cerrar
          </button>
        </div>
      </Modal> : ''}
    </>
  );
};

export default EpisodeDetail;
